export default function formatDate(data) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const year = data[0];
  const monthIndex = data[1] - 1;
  const day = data[2];
  const hours = data[3] + 1;
  const minutes = data[4];


  const formattedDate = `${day} ${months[monthIndex]}, ${year} at ${
    hours % 12 || 12
  }:${minutes.toString().padStart(2, "0")} ${hours >= 12 ? "pm" : "am"}`;

  return formattedDate;
}
