// import UserAvatar from "../../../assets/images/user.svg";
import { useState } from "react";
import "./ViewBvnDetails.scss";

const ViewBvnDetails = ({
  userBvnData,
  userBvn,
  setUserBvn,
  isBvnUpdateDetailsLoading,
  handleUpdateBvnDetails,
}) => {
  const [viewBvnTab, setViewBvnTab] = useState("view");

  if (!userBvnData) return;
  const { bvn, dateOfBirth, email, phoneNumber, base64Image, firstName, count } =
    userBvnData;
  const maskedNumber = "*".repeat(6) + bvn.slice(6);

  function bvnVerification() {
    if (userBvn.length > 11) {
      userBvn.slice(0, 11);
    }
  }

  return (
    <div className="viewBvnDetailsWrapper">
      <div className="btn_wrapper">
        <button
          onClick={() => setViewBvnTab("view")}
          className={viewBvnTab === "view" ? "activeBvnTab" : ""}
        >
          View Details
        </button>
        <button
          onClick={() => setViewBvnTab("update")}
          className={viewBvnTab === "update" ? "activeBvnTab" : ""}
        >
          Update Details
        </button>
      </div>

      {viewBvnTab === "view" && (
        <div className="viewBvnDetails_container">
          <div className="viewBvnDetails_topSection">
            <p className="bvnDetails_title">BVN Details</p>
            <div className="img_count_group">
              <div className="bvnDetails_image">
                <img
                  src={`data:image/png;base64,${base64Image}`}
                  alt={`${firstName} pics`}
                  loading="lazy"
                />
              </div>
              <div className="form_group_col">
                <label htmlFor="bvnCount">BVN REQUEST COUNT</label>
                <p id="bvnCount" className="m-0">
                  {count}
                </p>
              </div>
            </div>
          </div>

          <div className="viewBvnDetails_bottomSection">
            <div className="form_group_col">
              <label htmlFor="bvn">bvn</label>
              <input type="text" value={maskedNumber} readOnly />
            </div>
            <div className="form_group_col">
              <label htmlFor="bvn">Date of Birth</label>
              <input type="text" value={dateOfBirth} readOnly />
            </div>
            <div className="form_group_col">
              <label htmlFor="bvn">Phone Number</label>
              <input type="text" value={phoneNumber} readOnly />
            </div>
            <div className="form_group_col">
              <label htmlFor="bvn">Email Address</label>
              <input type="text" value={email} readOnly />
            </div>
          </div>
        </div>
      )}

      {viewBvnTab === "update" && (
        <div className="viewBvnDetails_container">
          <div className="viewBvnDetails_bottomSection">
            <div className="form_group_col">
              <label htmlFor="bvn">Enter BVN</label>
              <input
                type="number"
                value={userBvn}
                onChange={(e) => setUserBvn(e.target.value)}
                oninput={bvnVerification}
                maxLength={11}
                max={11}
              />
            </div>

            <button className="updateBvn" onClick={handleUpdateBvnDetails}>
              {isBvnUpdateDetailsLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewBvnDetails;
