/* eslint-disable react-hooks/exhaustive-deps */
import "./savings-index.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import { Tag, Table } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import CachedIcon from "@mui/icons-material/Cached";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import savingsService from "../../../../services/api/savings/savings";
import CustomLoader from "../../../../components/loader/loader";

const SavingsIndex = () => {
  // const [clickedRowRecord, setClickedRowRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { getAllSavings } = savingsService();

  useEffect(() => {
    (async () => {
      try {
        const data = await getAllSavings();
        const tabledata = data?.savers;
        setTableData(tabledata);
      } catch (err) {
        return err;
      }
      setLoading(false);
    })();
  }, []);

  const navigate = useNavigate();

  const handlePagination = (page) => {
    console.log(page);
  };

  const viewSaver = (res) => {
    navigate(`/main/savings/${res}`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "SAVER",
      dataIndex: "saver",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.saver.length - b.saver.length,
    },
    {
      title: "SAVINGS BALANCE (₦)",
      dataIndex: "balance",
      render: (text, record) => (
        <NumberFormat
          className="fw-600 color-secondary"
          value={record.balance}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: "MATURITY DATE",
      dataIndex: "maturityDate",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (tag) => (
        <span>
          <Tag color={tag === "ACTIVE" ? "#213f7d" : "#e3ac07"} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        </span>
      ),
    },
    {
      title: "",
      render: (props) => (
        <ArrowForwardIosIcon className="color-sec-fade pointer font-16" />
      ),
    },
  ];

  return (
    <NewDashboardLayout>
      {loading ? (
        <div className="load">
          <CustomLoader />
        </div>
      ) : (
        <div>
          <div className="color-secondary fw-600 font-25 mb-1">Savings</div>
          <section className="infor-cards row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card mt-4">
                <AdminPanelSettingsOutlinedIcon className="round-50 color-lpurple bg-lpurple font-30 p-1" />
                <div className="mt-3 color-sec-fade fw-500 font-16">
                  ACTIVE SAVINGS
                </div>
                <div className="color-secondary fw-600 font-25 mt-3">0</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card mt-4">
                <AccountCircleIcon className="round-50 color-green bg-green font-30 p-1" />
                <div className="mt-3 color-sec-fade fw-500 font-16">
                  ACTIVE SAVERS
                </div>
                <div className="color-secondary fw-600 font-25 mt-3">0</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card mt-4">
                <AccountBalanceIcon className="round-50 color-yellow bg-yellow font-30 p-1" />
                <div className="mt-3 color-sec-fade fw-500 font-16">
                  SAVINGS AMOUNT
                </div>
                <div className="color-secondary fw-600 font-25 mt-3">₦0</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card mt-4">
                <SavingsOutlinedIcon className="round-50 color-secondary bg-blue font-30 p-1" />
                <div className="mt-3 color-sec-fade fw-500 font-16">
                  ALL SAVINGS
                </div>
                <div className="color-secondary fw-600 font-25 mt-3">0</div>
              </div>
            </div>
          </section>

          {/* search filter section */}
          <section className="card p-3 mt-40">
            <Formik
              initialValues={{
                target: "",
                saver: "",
                intrest: "",
                status: "",
              }}
              validationSchema={yup.object({
                target: yup.number(""),
                intrest: yup.number(""),
                saver: yup.string(""),
                status: yup.string(),
              })}
              onSubmit={(values) => {
                console.log(JSON.stringify(values));
              }}
            >
              {(props) => (
                <section className="">
                  <Form>
                    <div className="">
                      <div className="font-14 fw-600 mb-2 color-sec-fade fw-500">
                        FILTER BY
                      </div>
                      <div className="adjust-grid-save justify-content-between align-items-center">
                        <div className="mb-3">
                          <label
                            htmlFor="saver"
                            className="form-label font-12 fw-500 color-text"
                          >
                            Saver
                          </label>
                          <Field
                            type="text"
                            className="form-control-new form-control"
                            name="saver"
                            placeholder=""
                          ></Field>
                          <div className="form-error mt-1">
                            <ErrorMessage
                              className="font-12"
                              name="saver"
                            ></ErrorMessage>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="target"
                            className="form-label font-12 fw-500 color-text"
                          >
                            Target
                          </label>
                          <Field
                            type="text"
                            className="form-control-new form-control"
                            name="target"
                            placeholder=""
                          ></Field>
                          <div className="form-error mt-1">
                            <ErrorMessage
                              className="font-12"
                              name="target"
                            ></ErrorMessage>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="intrest"
                            className="form-label font-12 fw-500 color-text"
                          >
                            Intrest
                          </label>
                          <Field
                            type="text"
                            className="form-control-new form-control"
                            name="intrest"
                            placeholder=""
                          ></Field>
                          <div className="form-error mt-1">
                            <ErrorMessage
                              className="font-12"
                              name="intrest"
                            ></ErrorMessage>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="status"
                            className="form-label font-12 fw-500 color-text"
                          >
                            Status
                          </label>
                          <Field
                            type="text"
                            className="form-control-new form-control"
                            name="status"
                            placeholder=""
                          ></Field>
                          <div className="form-error mt-1">
                            <ErrorMessage
                              className="font-12"
                              name="status"
                            ></ErrorMessage>
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            disabled={props.isValid === false}
                            className="border-0 m-auto w-100 font-12 round-4 fw-500 color-white p-3 btn-login"
                          >
                            Filter
                          </button>
                        </div>

                        <div>
                          <button className="border-0 m-auto bg-white">
                            <CachedIcon className="color-sec-fade pointer font-40" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </section>
              )}
            </Formik>
          </section>
          {/* search and filter section end */}

          <section className="table-section round-6 bg-white p-4 mt-40">
            <Table
              className="pointer"
              columns={columns}
              dataSource={tableData}
              onChange={handlePagination}
              pagination={{ pageSize: 20 }}
              rowKey="id"
              onRow={(record, index) => {
                return {
                  onClick: (event) => {
                    viewSaver(record.id);
                  },
                };
              }}
            />
            {/* <Table columns={columns} dataSource={data} onChange={onChange} pagination={{ pageSize: 4 }} /> */}
          </section>
        </div>
      )}
    </NewDashboardLayout>
  );
};

export default SavingsIndex;
