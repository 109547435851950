import React from "react";
import NewDashboardLayout from "../../../layouts/dashboard-layout/NewDashboardLayout";
import { Button, Table } from "@mantine/core";
import { IoFunnel } from "react-icons/io5";
import "./TransactionHistory.css";
import DebitIconCard from "../../../components/transaction/DebitIconCard/DebitIconCard";
import DebitBox from "../../../components/transaction/DebitBox/DebitBox";
import { useDisclosure } from "@mantine/hooks";
import GenerateTokenModal from "../../../components/transaction/GenerateTokenModal/GenerateTokenModal";
import ElectricityTokenModal from "../../../components/transaction/ElectricityTokenModal/ElectricityTokenModal";
// import { useMutation } from "@tanstack/react-query";
// import SavingsService from "../../../services/api/savings/savings";

const tableData = [
  {
    narration: "Airtime Purchase",
    type: "debit",
    amount: "200",
    date: "Dec. 02, 2022, 08:31:57 am",
  },
  {
    narration: "Airtime Purchase",
    type: "credit",
    amount: "200",
    date: "Dec. 02, 2022, 08:31:57 am",
  },
  {
    narration: "Airtime Purchase",
    type: "debit",
    amount: "200",
    date: "Dec. 02, 2022, 08:31:57 am",
  },
  {
    narration: "Airtime Purchase",
    type: "credit",
    amount: "200",
    date: "Dec. 02, 2022, 08:31:57 am",
  },
  {
    narration: "Airtime Purchase",
    type: "debit",
    amount: "200",
    date: "Dec. 02, 2022, 08:31:57 am",
  },
  {
    narration: "Airtime Purchase",
    type: "credit",
    amount: "200",
    date: "Dec. 02, 2022, 08:31:57 am",
  },
];

// const res = {
//   transaction_date: "2024-01-07T13:22:20.647Z",
//   amount: 30000.0,
//   country: "NG",
//   txRef: "CF-FLYAPI-20240107012220646364232",
//   extra: "43893183239914419938",
//   productDetails: "FLY-API-NG-EKEDC PREPAID TOPUP",
//   customerID: "54130144378",
//   product: "EKEDC PREPAID TOPUP",
//   productName: "EKEDC PREPAID TOPUP",
// };

export default function TransactionHistory() {
  const [opened, { open, close }] = useDisclosure(false);
  const [isRowOpened, { open: openRow, close: closeRow }] =
    useDisclosure(false);
  // const { getElectricityToken } = SavingsService();

  // const { data, isPending } = useMutation({
  //   mutationFn: getElectricityToken,
  //   mutationKey: "getElectricityToken",
  // });

  const rows = tableData.map((data, idx) => (
    <Table.Tr key={idx} onClick={openRow}>
      <Table.Td className="d-flex align-items-center gap-3">
        <DebitIconCard status={data.type} />
        <p className="m-0" style={{ color: "#101828" }}>
          {data.narration}
        </p>
      </Table.Td>

      <Table.Td>
        <DebitBox status={data.type} />
      </Table.Td>

      <Table.Td>
        {/*  w-50 d-flex justify-content-end */}
        <p className="m-0" style={{ color: "#000000", fontWeight: "500" }}>
          {data.type === "debit" ? "-₦" : "₦"} {data.amount}
        </p>
      </Table.Td>

      <Table.Td>
        <p className="m-0" style={{ color: "#000000" }}>
          {data.date}
        </p>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <NewDashboardLayout>
      <div className="w-100 d-flex align-items-center justify-content-between px-4 mt-2">
        <h2 className="transactionHistoryTitle">Transaction History</h2>
        <Button
          leftSection={<IoFunnel size={18} />}
          className="filter_btn"
          onClick={open}
        >
          Filter
        </Button>
      </div>

      <Table highlightOnHover className="my-4">
        <Table.Thead>
          <Table.Tr>
            <Table.Th className="transactionTableHeader">Narration</Table.Th>
            <Table.Th className="transactionTableHeader">Type</Table.Th>
            <Table.Th className="transactionTableHeader">Amount</Table.Th>
            <Table.Th className="transactionTableHeader">Timestamp</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>

      <GenerateTokenModal close={close} opened={opened} />
      <ElectricityTokenModal opened={isRowOpened} close={closeRow} />
    </NewDashboardLayout>
  );
}
