/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../users/view-user/view-user.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Rate, Table } from "antd";
import NumberFormat from "react-number-format";
import Moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SavingsService from "../../../../services/api/savings/savings";
import CustomLoader from "../../../../components/loader/loader";

const ViewSavings = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [userSavingsData, setUserSavingsData] = useState([]);
  const { getSavingsById } = SavingsService();

  let navigate = useNavigate();
  const handleClick = (e) => {
    navigate("/main/savings");
  };

  const formatedDate = (data) => {
    let formattedTime = Moment(data).format("DD/MM/YYYY HH:mm");
    return formattedTime;
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await getSavingsById(id);
        const savingsDetailData = data?.data?.savingsResponseDto;
        setUserSavingsData(savingsDetailData);
      } catch (err) {
        return err;
      }
      setLoading(false);
    })();
  }, []);

  const logsColumns = [
    {
      title: "",
      dataIndex: "transactionStatus",
      render: (res) => (
        <FiberManualRecordIcon
          fontSize="small"
          color={res === "APPROVED" ? "success" : "error"}
        />
      ),
    },
    {
      title: "TRANSACTION ID",
      dataIndex: "id",
    },
    {
      title: "DATE",
      dataIndex: "createdOn",
      render: (text, record) => <span>{formatedDate(record.createdOn)}</span>,
    },
    {
      title: "AMOUNT (₦)",
      dataIndex: "amount",
      render: (text, record) => (
        <NumberFormat
          className={
            record.debitCreditType === "CR"
              ? "color-green fw-700"
              : "color-error fw-700"
          }
          value={record.amount}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: "BALANCE (₦)",
      dataIndex: "balance",
      render: (text, record) => (
        <NumberFormat
          className="fw-600 color-secondary"
          value={record.balance}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: "PAYMENT METHOD",
      dataIndex: "paymentMethodName",
    },
    // {
    //   title: 'CHARGE ATTEMPTS',
    //   render: (text, record) => (
    //     <span> - </span>
    //   ),
    // },
    {
      title: "",
      render: (props) => (
        <ArrowForwardIosIcon className="color-sec-fade pointer font-16" />
      ),
    },
  ];
  return (
    <NewDashboardLayout>
      {loading ? (
        <div className="load">
          <CustomLoader />
        </div>
      ) : (
        <div>
          <button
            onClick={handleClick}
            className="mb-4 border-0 bg-grey pointer"
          >
            <KeyboardBackspaceIcon /> Back
          </button>

          <section className="big-card mb-5">
            <div className="row mt-4 mb-3">
              <div className="col-md-3 text-center">
                <Avatar
                  size={{ xs: 24, sm: 32, md: 80, lg: 100, xl: 130, xxl: 180 }}
                  icon={<UserOutlined />}
                />
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-20 fw-500 mb-2">
                    {userSavingsData?.personal?.name}
                  </div>
                  <div className="color-sec-fade fw-400 font-16">-</div>
                </div>

                <div>
                  <div className="color-secondary font-16 fw-500 mb-2">
                    User’s Rating
                  </div>
                  <Rate allowHalf disabled defaultValue={3} />
                </div>
              </div>

              <div className="col-md-3"></div>

              <div className="col-md-3">
                <div className=""></div>
              </div>
            </div>
          </section>

          <section className="big-card mb-5">
            <div className="row mt-4">
              <div className="mb-5 color-secondary font-18 fw-500">
                Saver Details
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">
                    FULL NAME
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {userSavingsData?.personal?.name}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">
                    PHONE NUMBER
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {userSavingsData?.personal?.mobile?.number}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">
                    EMAIL
                  </div>
                  <div className="color-sec-fade fw-500 font-16">-</div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">BVN</div>
                  <div className="color-sec-fade fw-500 font-16">
                    {userSavingsData?.personal?.externalID}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="big-card mb-5">
            <div className="row mt-4">
              <div className="mb-5 color-secondary font-18 fw-500">
                Savings Details
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">
                    SAVINGS PLAN
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {userSavingsData?.account?.product?.name}
                  </div>
                </div>

                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">
                    END DATE
                  </div>
                  <div className="color-sec-fade fw-500 font-16">-</div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">
                    SAVINGS NAME
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {userSavingsData?.account?.product?.depositProductType}
                  </div>
                </div>

                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">
                    START DATE
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {userSavingsData?.account?.account?.approvedOn}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">
                    SAVING BALANCE
                  </div>
                  <NumberFormat
                    className="color-sec-fade fw-400 font-16"
                    value={userSavingsData?.account?.account?.balance}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </div>

                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2"></div>
                  <div className="color-sec-fade fw-500 font-16"></div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2">
                    INTEREST
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {userSavingsData?.account?.product?.interest?.defaultRate}%
                  </div>
                </div>

                <div className="mb-4">
                  <div className="color-secondary font-14 fw-400 mb-2"></div>
                  <div className="color-sec-fade fw-500 font-16"></div>
                </div>
              </div>
            </div>
          </section>

          <section className="big-card mt-4">
            <div className="mt-3 mb-4 fw-500 color-secondary font-16">
              Savings Transactions
            </div>
            <Table
              className="pointer"
              columns={logsColumns}
              dataSource={userSavingsData?.account?.transactions}
              pagination={{ pageSize: 20 }}
              rowKey="id"
            />
          </section>
        </div>
      )}
    </NewDashboardLayout>
  );
};

export default ViewSavings;
