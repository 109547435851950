import { Table } from "@mantine/core";
import SettlementStatus from "./SettlementStatus";
import formatDate from "../../utils/formatDate";

export default function SuccessfulTable({
  successfulReports,
  filterData,
  status,
}) {
  const returnData = () => {
    if (filterData) {
      return filterData;
    } else {
      return successfulReports;
    }
  };

  const headStyle = {
    fontWeight: "700",
    fontSize: "12px",
    color: "#667085",
  };

  const rows =
    returnData()?.length === 0 ? (
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        No Data
      </div>
    ) : (
      returnData()?.map((element) => (
        <Table.Tr key={element.id}>
          <Table.Td>{element.serviceProviderName}</Table.Td>
          <Table.Td>{element.settlementReportType}</Table.Td>
          <Table.Td>{element.total}</Table.Td>
          <Table.Td>{element.successful}</Table.Td>
          <Table.Td>{element.uploadedby}</Table.Td>
          <Table.Td>{formatDate(element.uploadedDate)}</Table.Td>
          <Table.Td>
            {<SettlementStatus status={status(element.status)} />}
          </Table.Td>
        </Table.Tr>
      ))
    );

  return (
    <Table style={{ position: "relative" }}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th style={headStyle}>Document Name</Table.Th>
          <Table.Th style={headStyle}>Settlement Type</Table.Th>
          <Table.Th style={headStyle}>Transaction Record</Table.Th>
          <Table.Th style={headStyle}>Successful Record</Table.Th>
          <Table.Th style={headStyle}>Uploaded By</Table.Th>
          <Table.Th style={headStyle}>Date Uploaded</Table.Th>
          <Table.Th style={headStyle}>Status</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
}
