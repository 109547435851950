import { Button } from "@mantine/core";
import "../kyc/KycTab/KycTab.css";
import { tabsStyles1, tabsStyles2, tabsStyles3 } from "../../utils/tabsStyles";

// .active_kyc_btn {
//   background-color: #072c50;
//   color: white;
//   border: 0;
//   outline: 0;
// }

// .kyc_btn {
//   background-color: #fff;
//   color: black;
//   border: 1px solid #d0d5dd;
//   font-size: 15px;
// }

// .border_right_radius {
//   border-top-right-radius: 0px;
//   border-bottom-right-radius: 0px;
// }

// .border_left_radius {
//   border-top-left-radius: 0px;
//   border-bottom-left-radius: 0px;
// }

export default function SettlementReportTabs({
  activeSettlementTab,
  setActiveSettlementTab,
}) {
  return (
    <div className="w-100 d-flex align-items-center justify-content-between mt-4">
      <div className="d-flex align-items-center">
        <Button
          radius="md"
          variant="filled"
          style={tabsStyles1(activeSettlementTab, "pending")}
          onClick={() => setActiveSettlementTab("pending")}
        >
          Pending
        </Button>

        <Button
          radius="md"
          variant="filled"
          style={tabsStyles2(activeSettlementTab, "successful")}
          onClick={() => setActiveSettlementTab("successful")}
        >
          Successful
        </Button>

        <Button
          radius="md"
          variant="filled"
          style={tabsStyles3(activeSettlementTab, "unsuccessful")}
          onClick={() => setActiveSettlementTab("unsuccessful")}
        >
          Unsuccessful
        </Button>
      </div>
    </div>
  );
}
