/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../users/view-user/view-user.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Rate } from "antd";
import { UserOutlined } from "@ant-design/icons";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TransactionService from "../../../../services/api/transaction/transaction";

const ViewTransaction = () => {
  const { id } = useParams();

  const [, setUserTransactionData] = useState([]);
  const { generateReciept } = TransactionService();

  // let navigate = useNavigate();

  const handleClick = (e) => {
    return e;
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await generateReciept(id);
        if (data?.data) {
          setUserTransactionData(data?.data);
        }
      } catch (err) {
        return err;
      }
    })();
  }, []);

  // const logData = [
  //   {
  //     id: '1',
  //     scheduleId: '341234',
  //     date: 'Apr 22, 2022',
  //     amount: '12,000',
  //     status: 'F'
  //   },
  //   {
  //     id: '2',
  //     scheduleId: '341234',
  //     date: 'Apr 22, 2022',
  //     amount: '12,000',
  //     status: 'S'
  //   },
  //   {
  //     id: '3',
  //     scheduleId: '341234',
  //     date: 'Apr 22, 2022',
  //     amount: '12,000',
  //     status: 'F'
  //   },
  //   {
  //     id: '4',
  //     scheduleId: '341234',
  //     date: 'Apr 22, 2022',
  //     amount: '12,000',
  //     status: 'S'
  //   },
  //   {
  //     id: '5',
  //     scheduleId: '341234',
  //     date: 'Apr 22, 2022',
  //     amount: '12,000',
  //     status: 'S'
  //   },
  // ];

  // const logsColumns = [
  //   {
  //     title: '',
  //     dataIndex: 'status',
  //     render: (res) => (
  //       <FiberManualRecordIcon fontSize="small" color={
  //         res === 'F' ? 'error' : 'success'
  //       } />
  //     ),
  //   },
  //   {
  //     title: 'SCHEDULE ID',
  //     dataIndex: 'scheduleId'
  //   },
  //   {
  //     title: 'DATE',
  //     dataIndex: 'date'
  //   },
  //   {
  //     title: 'AMOUNT (₦)',
  //     dataIndex: 'amount'
  //   },
  //   {
  //     title: 'CHARGE ATTEMPTS',
  //     render: (text, record) => (
  //       <span> - </span>
  //     ),
  //   },
  //   {
  //     title: '',
  //     render: (props) => (
  //       <ArrowForwardIosIcon className="color-sec-fade pointer font-16" />
  //     )
  //   },
  // ];
  return (
    <NewDashboardLayout>
      <div>
        <button onClick={handleClick} className="mb-4 border-0 bg-grey pointer">
          <KeyboardBackspaceIcon /> Back
        </button>
        <div className="color-secondary fw-500 font-22 mb-3">
          Transaction:88880009
        </div>

        <section className="big-card mb-5">
          <div className="row mt-4 mb-3">
            <div className="col-md-3 text-center">
              <Avatar
                size={{ xs: 24, sm: 32, md: 80, lg: 100, xl: 130, xxl: 180 }}
                icon={<UserOutlined />}
              />
            </div>

            <div className="col-md-3">
              <div className="mb-4">
                <div className="color-secondary font-20 fw-500 mb-2">
                  Ade Oluwole
                </div>
                <div className="color-sec-fade fw-400 font-16">LSQ 508268</div>
              </div>

              <div>
                <div className="color-secondary font-16 fw-500 mb-2 ">
                  User’s Rating
                </div>
                <Rate allowHalf disabled defaultValue={3} />
              </div>
            </div>
            <div className="vr d-flex p-0"></div>

            <div className="col-md-3">
              <div className="color-grey font-14 mt-5 mb-2 ml-2 fw-500">
                Phone Number: 080123456789
              </div>
              <div className="color-grey font-14 mb-2 ml-2 fw-500">
                Email: adeoluwole@gmail.com
              </div>
            </div>

            <div className="col-md-3">
              <div className=""></div>
            </div>
          </div>
        </section>

        <section className="big-card mb-5">
          <div className="row mt-4">
            <div className="mb-5 color-secondary font-18 fw-500">
              Transaction Information
            </div>

            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  TRANSACTION ID
                </div>
                <div className="color-sec-fade fw-500 font-16">88880009</div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  EXTERNAL REF
                </div>
                <div className="color-sec-fade fw-500 font-16">-</div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">DATE</div>
                <div className="color-sec-fade fw-500 font-16">
                  April, 25 2022
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">TIME</div>
                <div className="color-sec-fade fw-500 font-16">6:40 PM</div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  AMOUNT
                </div>
                <div className="color-sec-fade fw-500 font-16">₦50.00</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">TYPE</div>
                <div className="color-sec-fade fw-500 font-16">
                  Card Activation
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  CHANNEL
                </div>
                <div className="color-sec-fade fw-500 font-16">CARD</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">BANK</div>
                <div className="color-sec-fade fw-500 font-16">-</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  ACCOUNT NUMBER
                </div>
                <div className="color-sec-fade fw-500 font-16">-</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  PHONE NUMBER{" "}
                </div>
                <div className="color-sec-fade fw-500 font-16">22142253742</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  PROCESSOR
                </div>
                <div className="color-sec-fade fw-500 font-16">Paystack</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  STATUS
                </div>
                <div className="round-10 color-red bg-red w-1 text-center font-12 p-2">
                  FAILED
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">ENTRY</div>
                <div className="round-10 color-green bg-green w-1 text-center font-12 p-2">
                  CREDIT
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  NARRATION
                </div>
                <div className="color-sec-fade fw-500 font-16">
                  Debit card activation
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="big-card mb-5">
          <div className="row mt-4">
            <div className="mb-5 color-secondary font-18 fw-500">
              Transactions Insight
            </div>

            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  DATE/TIME
                </div>
                <div className="color-sec-fade fw-500 font-16">
                  April 25,2022 6:40:38 PM
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  CHANNEL
                </div>
                <div className="color-sec-fade fw-500 font-16">CARD</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  PROCESSOR
                </div>
                <div className="color-sec-fade fw-500 font-16">Paystack</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  STATUS
                </div>
                <div className="round-10 color-red bg-red w-1 text-center font-12 p-2 ">
                  {" "}
                  FAILED{" "}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  CARD NUMBER
                </div>
                <div className="color-sec-fade fw-500 font-16">-</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <div className="color-secondary font-12 fw-400 mb-2">
                  CARD TYPE
                </div>
                <div className="color-sec-fade fw-500 font-16">-</div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="big-card mt-4">
          <div className="mt-3 mb-4 fw-500 color-secondary font-16">Savings Transactions</div>
          <Table columns={logsColumns} dataSource={logData} pagination={{ pageSize: 10 }} rowKey="id" />
        </section> */}
      </div>
    </NewDashboardLayout>
  );
};

export default ViewTransaction;
