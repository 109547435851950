import React from "react";
import TabLabel from "../TabLabel";
import sidebarTabStyle from "../../../utils/SidebarTabStyle";
import SideMenuList from "../SideMenuList";
import { useAppContext } from "../../../context/app";

export default function CreditvilleCore() {
  const { activeAccordion, setActiveAccordion } = useAppContext();

  const handleClick = () => {
    if (activeAccordion === "creditvilleCore") {
      setActiveAccordion("");
    } else {
      setActiveAccordion("creditvilleCore");
    }
  };

  return (
    <>
      <hr className="horizontal_line" />
      <TabLabel
        title="Creditville Core"
        handleClick={handleClick}
        isActive={activeAccordion === "creditvilleCore"}
      />
      <div style={sidebarTabStyle("20rem", activeAccordion, "creditvilleCore")}>
        <SideMenuList
          title="Overview"
          name=""
          iconLink="/core.svg"
          setActiveAccordion={setActiveAccordion}
          active="creditvilleCore"
        />
        <SideMenuList
          title="Paystack"
          name=""
          iconLink="/core.svg"
          setActiveAccordion={setActiveAccordion}
          active="creditvilleCore"
        />
        <SideMenuList
          title="Remita"
          name=""
          iconLink="/core.svg"
          setActiveAccordion={setActiveAccordion}
          active="creditvilleCore"
        />
        <SideMenuList
          title="Notifications"
          name=""
          iconLink="/core.svg"
          setActiveAccordion={setActiveAccordion}
          active="creditvilleCore"
        />
        <SideMenuList
          title="Investment"
          name=""
          iconLink="/core.svg"
          setActiveAccordion={setActiveAccordion}
          active="creditvilleCore"
        />
        <SideMenuList
          title="Disbursement"
          name=""
          iconLink="/core.svg"
          setActiveAccordion={setActiveAccordion}
          active="creditvilleCore"
        />
        <SideMenuList
          title="Account Management"
          name=""
          iconLink="/core.svg"
          setActiveAccordion={setActiveAccordion}
          active="creditvilleCore"
        />
        <SideMenuList
          title="Salary Management"
          name=""
          iconLink="/core.svg"
          setActiveAccordion={setActiveAccordion}
          active="creditvilleCore"
        />
      </div>
    </>
  );
}
