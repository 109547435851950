import { Modal } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";

export default function ActiveUser({
  activateModal,
  setActivateModal,
  setStatusChange,
}) {
  const { errors, handleSubmit, handleChange, values, isSubmitting, isValid } =
    useFormik({
      initialValues: {
        message: "",
      },
      validationSchema: yup.object().shape({
        message: yup.string().required("message field is required"),
      }),
      onSubmit: async (values, { resetForm, setSubmitting }) => {
        const status = "A";
        const message = values.message;
        await setStatusChange(status, message);
        resetForm();
        setSubmitting(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
    });

  return (
    <Modal
      centered
      footer={null}
      destroyOnClose={true}
      visible={activateModal}
      onOk={() => setActivateModal(false)}
      onCancel={() => setActivateModal(false)}
      width={500}
    >
      <div className="w-100 h-100">
        <div className="font-18 text-center mb-3 fw-500 color-secondary ">
          Activate User
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="message"
              className="form-label font-14 fw-500 color-text"
            >
              Why do you want to perform this action ? *
            </label>
            <textarea
              className="form-control font-14"
              name="message"
              rows={6}
              placeholder="Enter your Description"
              onChange={handleChange}
              value={values.message}
              style={{ resize: "none" }}
            ></textarea>
            {errors.message && (
              <div className="form-error">{errors.message}</div>
            )}
          </div>

          <button
            type="submit"
            disabled={!isValid || isSubmitting}
            className="border-0 w-100 font-16 mt-3 round-6 fw-500 color-white p-3 btn-red"
          >
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
}
