import "./Paystack_chargeback.css";

import NewDashboardLayout from "../../../layouts/dashboard-layout/NewDashboardLayout";
import { Button } from "@mantine/core";
import { Table } from "@mantine/core";
import { useState } from "react";
import SavingsService from "../../../services/api/savings/savings";
import { Spin } from "antd";

export default function PaystackChargeback() {
  const [status, setStatus] = useState("pending");
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [reference, setReference] = useState(null);
  const { getChargeBack } = SavingsService();

  const handleGetChargeBack = async (e) => {
    e.preventDefault();
    setStatus("loading");
    try {
      const payload = { email, reference };
      const res = await getChargeBack(payload);
      if (res) {
        setData(res);
        setStatus("success");
        setEmail("");
        setReference("");
      }
      setStatus("success");
    } catch (error) {
      setData(null);
      setStatus("failed");
      setEmail("");
      setReference("");
    }
  };

  return (
    <NewDashboardLayout>
      <h3 className="text_primary w-100 fw-600 font-25 my-2">
        Paystack Chargeback Transaction
      </h3>

      <div className="chargeback_container">
        <div className="top">
          <form onSubmit={handleGetChargeBack}>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Search Email..."
              autoFocus
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />

            <input
              type="text"
              name="reference"
              id="reference"
              placeholder="Search Reference..."
              value={reference}
              required
              onChange={(e) => setReference(e.target.value)}
            />

            <Button color="#213f7d" type="submit" size="md">
              Search
            </Button>
          </form>
        </div>

        <Spin spinning={status === "loading"}>
          <div className="chargebackTable">
            {!data && <h3 className="text-center">No Data</h3>}
            {data && (
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Account ID</Table.Th>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Reference</Table.Th>
                    <Table.Th>Amount</Table.Th>
                    <Table.Th>Created At</Table.Th>
                    <Table.Th>Status</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>{data?.accountId}</Table.Td>
                    <Table.Td>{data?.email}</Table.Td>
                    <Table.Td>{data?.reference}</Table.Td>
                    <Table.Td>{data?.amount}</Table.Td>
                    <Table.Td>
                      {new Date(data?.createdAt).toLocaleDateString()}
                    </Table.Td>
                    <Table.Td>
                      <Status status={data?.status} />
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            )}
          </div>
        </Spin>
      </div>
    </NewDashboardLayout>
  );
}

function Status({ status }) {
  return (
    <div
      className={`${
        status.toLowerCase() === "pending"
          ? "pending_chargeback"
          : "success_chargeback"
      }`}
    >
      <span
        className={`${
          status.toLowerCase() === "pending" ? "pending_ball" : "success_ball"
        }`}
      />
      {status}
    </div>
  );
}
