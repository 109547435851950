import {
  Input,
  CloseButton,
  Button,
  Modal,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import SavingsService from "../../../services/api/savings/savings";
import Logo from "../../../assets/images/logo.png";

export default function SearchFilter({
  data,
  handleChangePeriod,
  period,
  refetch,
}) {
  const [value, setValue] = useState("");

  const navigate = useNavigate();
  const productIds = data?.map((product) => product.productID);

  const productPeriods = Array.from(
    new Set(data?.map((product) => product.period?.toLowerCase()))
  );

  const { createSavingsProductRange, registerProduct } = SavingsService();
  const [opened, { open, close }] = useDisclosure(false);

  const initialValues = {
    minimumAmount: "",
    maximumAmount: "",
    interestRate: "",
    tenorStart: "",
    productRangeCode: "",
    tenorEnd: "",
    tenorDescription: "",
    productId: "",
  };
  const validationSchema = object().shape({
    minimumAmount: string().required("This field is required"),
    maximumAmount: string().required("This field is required"),
    interestRate: string().required("This field is required"),
    tenorStart: string().required("This field is required"),
    productRangeCode: string().required("This field is required"),
    tenorEnd: string().required("This field is required"),
    tenorDescription: string().required("This field is required"),
    productId: string().required("This field is required"),
  });

  const { values, handleChange, handleSubmit, errors, isSubmitting, isValid } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (
        {
          minimumAmount,
          maximumAmount,
          interestRate,
          tenorStart,
          productRangeCode,
          tenorEnd,
          tenorDescription,
          productId,
        },
        { resetForm, setSubmitting }
      ) => {
        const savingsProductRangeIDs = JSON.parse(
          localStorage.getItem("savingsProduct")
        );
        const productRangeCodes = savingsProductRangeIDs
          ?.filter((product) => product.productID === productId)
          ?.map((product) => product.savingsProductRangeDtos)[0]
          ?.map((id) => id.productRangeCode);

        const payload = {
          minimumAmount,
          maximumAmount,
          interestRate,
          tenorStart,
          productRangeCode,
          tenorEnd,
          tenorDescription,
        };

        const payload2 = {
          productID: productId,
          productRangeCode: [...productRangeCodes, productRangeCode],
        };

        const data = await createSavingsProductRange(payload);
        if (data?.status === "SUCCESS") {
          await registerProduct(payload2);
          resetForm();
          setSubmitting(false);
          close();
          refetch();
        }
      },
    });
  return (
    <div className="mt-3 w-100 d-flex align-items-center justify-content-between">
      <Input
        placeholder="Search"
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        rightSectionPointerEvents="all"
        mt="md"
        style={{ width: "40%" }}
        leftSection={<i className="bx bx-search"></i>}
        rightSection={
          <CloseButton
            aria-label="Clear input"
            onClick={() => setValue("")}
            style={{ display: value ? undefined : "none" }}
          />
        }
      />

      <div className="d-flex align-items-baseline gap-3">
        <Button
          color="#072C50"
          leftSection={<i className="bx bx-plus"></i>}
          onClick={() =>
            navigate("/main/savings-product/create-savings-product")
          }
        >
          Create New Savings Product
        </Button>
        <Button
          color="#072C50"
          leftSection={<i className="bx bx-plus"></i>}
          onClick={open}
        >
          Add Offering
        </Button>
        <Input
          component="select"
          rightSection={<i className="bx bxs-chevron-down"></i>}
          pointer
          mt="md"
          value={period}
          onChange={handleChangePeriod}
        >
          <option value="all">All</option>
          <option value="from newest">From Newest</option>
          {productPeriods?.map((period) => (
            <option value={period} key={period} className="text-capitalize">
              {period}
            </option>
          ))}
        </Input>
      </div>

      <Modal
        opened={opened}
        onClose={close}
        title={<img src={Logo} alt="logo" width={130} height={40} />}
      >
        <div className="w-100 mt-1">
          <form onSubmit={handleSubmit} className="w-100 mt-1">
            <h5 className="text_primary mt-4">Product Offering</h5>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Minimum Amount"
                  placeholder="Enter Minimum Amount"
                  radius={8}
                  className="mt-2 text_primary"
                  name="minimumAmount"
                  value={values.minimumAmount}
                  onChange={handleChange}
                  error={errors.minimumAmount}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Maximum Amount"
                  placeholder="Enter Maximum Amount"
                  radius={8}
                  className="mt-2 text_primary"
                  name="maximumAmount"
                  value={values.maximumAmount}
                  onChange={handleChange}
                  error={errors.maximumAmount}
                />
              </div>
            </div>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Interest Rate"
                  placeholder="Enter Interest Rate"
                  radius={8}
                  className="mt-2 text_primary"
                  name="interestRate"
                  value={values.interestRate}
                  onChange={handleChange}
                  error={errors.interestRate}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Product Range Code"
                  placeholder="Enter Product Range Code"
                  radius={8}
                  className="mt-2 text_primary"
                  name="productRangeCode"
                  value={values.productRangeCode}
                  onChange={handleChange}
                  error={errors.productRangeCode}
                />
              </div>
            </div>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Tenor Start"
                  placeholder="Enter Tenor Start"
                  radius={8}
                  className="mt-2 text_primary"
                  name="tenorStart"
                  value={values.tenorStart}
                  onChange={handleChange}
                  error={errors.tenorStart}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Tenor End"
                  placeholder="Enter Tenor End"
                  radius={8}
                  className="mt-2 text_primary"
                  name="tenorEnd"
                  value={values.tenorEnd}
                  onChange={handleChange}
                  error={errors.tenorEnd}
                />
              </div>
            </div>

            <div className="w-100 d-flex flex-column mt-2">
              <label
                htmlFor="productId"
                className="text_primary"
                style={{ fontWeight: "500", fontSize: "14px" }}
              >
                Select product ID
              </label>
              <Input
                component="select"
                rightSection={<i className="bx bxs-chevron-down"></i>}
                pointer
                placeholder="Select product ID"
                name="productId"
                id="productId"
                value={values.productId}
                onChange={handleChange}
                rightSectionPointerEvents="all"
                error={errors.productId}
              >
                {productIds?.map((id, idx) => (
                  <option value={id} key={idx}>
                    {id}
                  </option>
                ))}
              </Input>
            </div>

            <Textarea
              label="Tenor Description"
              placeholder="Enter Tenor Description"
              radius={8}
              className="mt-2 text_primary"
              name="tenorDescription"
              value={values.tenorDescription}
              onChange={handleChange}
              error={errors.tenorDescription}
            />

            <div className="w-100 py-3 d-flex align-items-center gap-2">
              <Button
                type="button"
                className="saving_target_btn px-5 w-100"
                onClick={close}
                style={{ border: "1px solid #D0D5DD" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="primary_btn px-5 w-100"
                disabled={!isValid || isSubmitting}
              >
                Next
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
