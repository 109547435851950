import { FaAngleDown, FaAngleUp } from "react-icons/fa";

export default function TabLabel({ handleClick, isActive, title }) {
  return (
    <button className="dropdown_btn" onClick={handleClick}>
      <span style={{ textTransform: "capitalize", fontWeight: "600" }}>
        {title}
      </span>
      {isActive ? (
        <FaAngleUp className="drop_icon" />
      ) : (
        <FaAngleDown className="drop_icon" />
      )}
    </button>
  );
}
