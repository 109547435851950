import "./nibss-transaction-index.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import CachedIcon from "@mui/icons-material/Cached";
import TransactionService from "../../../../services/api/transaction/transaction";
import CustomLoader from "../../../../components/loader/loader";
// import { utils, writeFile } from "xlsx";
import { useQuery } from "@tanstack/react-query";
import { useDisclosure } from "@mantine/hooks";
import NibbsTable from "../../../../components/NibbsTable/NibbsTable";
import { Pagination, Modal } from "@mantine/core";
import NibbsTransactionModal from "../../../../components/modals/NibbsTransactionModal/NibbsTransactionModal";
import NibbsFilterModal from "../../../../components/modals/NibbsFilter/NibbsFilter";

const NIBBSTransactionsIndex = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataByDateFilter, setTableDataByDateFilter] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const {
    getAllNibbsTransaction,
    searchNibbsTransaction,
    pageSize,
    recieptLoader,
    filterNibbsTransaction,
    error,
    setError,
  } = TransactionService();

  const [activePage, setActivePage] = useState(1);
  const [transaction, setTransaction] = useState({});
  const [opened, { open, close }] = useDisclosure(false);
  const [isFilterOpened, { open: openFilterModal, close: closeFilterModal }] =
    useDisclosure(false);

  // const queryClient = useQueryClient();

  const {
    data: allNibbsTransactions,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () => getAllNibbsTransaction(activePage - 1, 20),
    queryKey: ["getAllNibbsTransaction", { activePage }],
  });

  // const {mutateAsync: filterNibbsTransactionAsync} = useMutation({
  //   mutationFn: filterNibbsTransaction,
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({
  //       queryKey: ["getAllNibbsTransaction", { activePage }],
  //     });
  //   },
  // });

  const handleShowNibbsTransaction = (id) => {
    if (tableData.length) {
      open();
    }
    const transaction = allNibbsTransactions?.find(
      (transaction) => transaction.id === id
    );
    if (!transaction) return;

    setTransaction(transaction);
    open();
  };

  const refreshAPI = async () => {
    refetch();
    setTableData([]);
    setTableDataByDateFilter([]);
  };

  const handleDisplayNibbsTransactions = () => {
    if (tableData.length > 0) return tableData;
    if (tableDataByDateFilter.length > 0) return tableDataByDateFilter;
    return allNibbsTransactions;
  };

  // const handleExportCSV = () => {
  //   const wb = utils.book_new();
  //   const ws = utils.json_to_sheet(tableData);
  //   utils.book_append_sheet(wb, ws, "Nibbs Sheet");
  //   writeFile(wb, "Nibbs-Transaction.xlsx");
  // };

  return (
    <NewDashboardLayout>
      {isLoading || tableLoading || recieptLoader ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="color-secondary fw-600 font-25 mb-1">
            NIBSS Transactions
          </div>

          {/* search filter section  */}
          <section className="card p-3 mt-40">
            <Formik
              initialValues={{
                referenceID: "",
                sessionID: "",
              }}
              validationSchema={yup.object({
                referenceID: yup.string(""),
                sessionID: yup.string(""),
              })}
              onSubmit={(values, { resetForm }) => {
                const payload = {
                  sessionId: values.sessionID,
                  referenceCode: values.referenceID,
                };
                const displaySearch = async () => {
                  setTableLoading(true);
                  const data = await searchNibbsTransaction(payload);
                  setTransaction(data[0]);
                  setTableData(data);
                  setTableLoading(false);
                };
                displaySearch();
                resetForm();
              }}
            >
              {(props) => (
                <section className="d-flex">
                  <Form>
                    <div className="">
                      <div className="font-14 fw-600 mb-2 color-sec-fade fw-500">
                        FILTER BY
                      </div>
                      <div className="d-flex gap-3 justify-content-between align-items-center">
                        <div className="mb-3">
                          <label
                            htmlFor="referenceID"
                            className="form-label font-12 fw-500 color-text"
                          >
                            Reference ID
                          </label>
                          <Field
                            type="text"
                            className="form-control-new form-control w-auto"
                            name="referenceID"
                            placeholder=""
                          ></Field>
                          <div className="form-error mt-1">
                            <ErrorMessage
                              className="font-12"
                              name="referenceID"
                            ></ErrorMessage>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="sessionID"
                            className="form-label font-12 fw-500 color-text"
                          >
                            Session ID
                          </label>
                          <Field
                            type="text"
                            className="form-control-new form-control w-auto"
                            name="sessionID"
                            placeholder=""
                          ></Field>
                          <div className="form-error mt-1">
                            <ErrorMessage
                              className="font-12"
                              name="sessionID"
                            ></ErrorMessage>
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            disabled={props.isValid === false}
                            className="border-0 m-auto w-auto font-12 round-4 fw-500 color-white px-4 py-3 btn-login"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="d-flex mt-3 ml-2">
                    <button
                      className="border-0 m-auto bg-white"
                      onClick={refreshAPI}
                    >
                      <CachedIcon className="color-sec-fade pointer font-40" />
                    </button>

                    {/* <button
                      className="border-0 m-auto w-auto font-12 round-4 fw-500 color-white px-4 py-3 btn-login"
                      onClick={handleExportCSV}
                    >
                      Export CSV
                    </button> */}

                    <button
                      className="border-0 m-auto w-auto font-12 round-4 fw-500 color-white px-4 py-3 btn-login"
                      onClick={openFilterModal}
                    >
                      Filter by date
                    </button>
                  </div>
                </section>
              )}
            </Formik>
          </section>

          <section className="table-section round-6 bg-white p-4 mt-40">
            <NibbsTable
              allNibbsTransactions={handleDisplayNibbsTransactions()}
              handleShowNibbsTransaction={handleShowNibbsTransaction}
            />

            <div className="mt-4 d-flex justify-content-center">
              {allNibbsTransactions.length > 0 ||
                (tableDataByDateFilter.length > 0 && (
                  <Pagination
                    total={pageSize}
                    siblings={2}
                    defaultValue={1}
                    withEdges
                    value={activePage}
                    onChange={setActivePage}
                  />
                ))}
            </div>

            <Modal
              opened={opened}
              onClose={close}
              centered
              size="lg"
              radius={14}
              withCloseButton={false}
            >
              <NibbsTransactionModal transaction={transaction} />
            </Modal>

            <NibbsFilterModal
              filterIsOpened={isFilterOpened}
              closeFilterModal={closeFilterModal}
              error={error}
              setError={setError}
              filterNibbsTransaction={filterNibbsTransaction}
              // filterNibbsTransactionAsync={filterNibbsTransactionAsync}
              setTableDataByDateFilter={setTableDataByDateFilter}
              activePage={activePage}
            />
          </section>
        </div>
      )}
    </NewDashboardLayout>
  );
};

export default NIBBSTransactionsIndex;
