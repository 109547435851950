import React from "react";
import TabLabel from "../TabLabel";
import sidebarTabStyle from "../../../utils/SidebarTabStyle";
import SideMenuList from "../SideMenuList";
import { useAppContext } from "../../../context/app";

export default function CoporateCommunication() {
  const { activeAccordion, setActiveAccordion } = useAppContext();

  const handleClick = () => {
    if (activeAccordion === "coporateCommunication") {
      setActiveAccordion("");
    } else {
      setActiveAccordion("coporateCommunication");
    }
  };

  return (
    <>
      <hr className="horizontal_line" />
      <TabLabel
        title="coporate Communication"
        handleClick={handleClick}
        isActive={activeAccordion === "coporateCommunication"}
      />
      <div
        style={sidebarTabStyle(
          "20rem",
          activeAccordion,
          "coporateCommunication"
        )}
      >
        <SideMenuList
          title="Banner Management"
          name="Banner Management"
          iconLink="/banner-management.svg"
          setActiveAccordion={setActiveAccordion}
          active="coporateCommunication"
        />
        <SideMenuList
          title="Push Notification"
          name="Push Notification"
          iconLink="/push_notification_vector.svg"
          setActiveAccordion={setActiveAccordion}
          active="coporateCommunication"
        />
      </div>
    </>
  );
}
