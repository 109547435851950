import { Button, Image, Modal, Switch } from "@mantine/core";
import Logo from "../../../assets/images/logo.png";
import "./ElectricityTokenModal.css";
import ElectricModalRow from "../ElectricModalRow/ElectricModalRow";
import { useDisclosure } from "@mantine/hooks";
import { useEffect } from "react";

export default function ElectricityTokenModal({
  opened,
  close,
  electricityData,
}) {
  const [isTokenOpened, { open: openTokenModal, close: closeTokenModal }] =
    useDisclosure(false);

  useEffect(() => {}, []);
  return (
    <>
      <Modal opened={opened} onClose={close} withCloseButton={false} centered>
        <Image src={Logo} alt="logo" style={{ width: "60%" }} />
        <div className="elek_container">
          <div className="elek_name_container">
            <div className="elek_name">
              <span className="elek_abbr">E</span>
              <ul className="elek_details_list">
                <li>Ebiwari Meshach</li>
                <li>89*****780</li>
                <li>{electricityData?.transaction_date}</li>
              </ul>
            </div>

            <div className="elek_status">
              <h4>Type</h4>
              <div className="elek_status_bar">
                <div className="elek_dot" />
                <p className="m-0">{electricityData?.status}</p>
              </div>
            </div>
          </div>

          <img src="/ellipse-1.png" alt="ellipse1" className="ellipse1" />
          <img src="/ellipse-2.png" alt="ellipse2" className="ellipse2" />

          <ElectricModalRow
            borderBottom
            borderTop={false}
            left="Amount"
            right={electricityData?.amount}
          />
          <ElectricModalRow
            borderBottom
            borderTop={false}
            left="Reference"
            right={electricityData?.txRef}
          />
          <ElectricModalRow
            borderBottom
            borderTop={false}
            left="Product"
            right={electricityData?.product}
          />
          <ElectricModalRow
            borderBottom
            borderTop={false}
            left="Product Name"
            right={electricityData?.productName}
          />
          <ElectricModalRow
            borderBottom
            borderTop={false}
            left="Product Details"
            right={electricityData?.productDetails}
          />
          <ElectricModalRow
            borderBottom
            borderTop={false}
            left="Customer ID"
            right={electricityData?.customerID}
          />
          <ElectricModalRow
            borderBottom={false}
            borderTop={false}
            left="Extra"
            right={electricityData?.extra}
          />
        </div>
        <button
          style={{
            width: "100%",
            padding: "10px 0",
            textAlign: "center",
            backgroundColor: "#072C50",
            color: "white",
            border: 0,
            outline: 0,
            borderRadius: "10px",
            marginTop: "20px",
          }}
          onClick={openTokenModal}
        >
          Send Token to Customer
        </button>
      </Modal>

      <Modal
        opened={isTokenOpened}
        onClose={closeTokenModal}
        title={
          <div className="d-flex flex-column gap-2">
            <h3 className="m-0 token_modal_title">Send Token to customers</h3>
            <p className="m-0 text-muted token_modal_subtitle">
              Select Platform to send token to
            </p>
          </div>
        }
        withCloseButton={false}
        centered
      >
        <div className="d-flex align-items-start gap-3">
          <Switch size="sm" color="#072C50" />
          <span>
            <p
              style={{ color: "#344054", fontSize: "14px", fontWeight: "600" }}
              className="m-0"
            >
              Send token via email
            </p>
            <p
              style={{ color: "#344054", fontSize: "13px", fontWeight: "400" }}
              className="m-0"
            >
              ebiwarimeshach@gmail.com
            </p>
          </span>
        </div>

        <div className="d-flex align-items-start gap-3">
          <Switch size="sm" color="#072C50" />
          <span>
            <p
              style={{ color: "#344054", fontSize: "14px", fontWeight: "600" }}
              className="m-0"
            >
              Send token via SMS{" "}
            </p>
            <p
              style={{ color: "#344054", fontSize: "13px", fontWeight: "400" }}
              className="m-0"
            >
              07012345678
            </p>
          </span>
        </div>

        <div className="d-flex align-items-start gap-3">
          <Switch size="sm" color="#072C50" />
          <p
            style={{ color: "#344054", fontSize: "13px", fontWeight: "500" }}
            className="m-0"
          >
            Send to both{" "}
          </p>
        </div>

        <div className="w-100 d-flex align-items-center gap-3 mt-3">
          <Button
            className="w-100 bg-white"
            style={{ color: "#475467", border: "1px solid #D0D5DD" }}
            onClick={closeTokenModal}
          >
            Cancel
          </Button>
          <Button
            className="w-100 text-white"
            style={{ backgroundColor: "#072C50" }}
          >
            Send
          </Button>
        </div>
      </Modal>
    </>
  );
}
