import { FaRegFileImage } from "react-icons/fa";

export default function FileLoading({ fileName, file, count }) {
  return (
    <div className="progress_container">
      <FaRegFileImage color="#072c50" />
      <div>
        <div className="w-100 d-flex align-items-start justify-content-between">
          <div className="w-100">
            {fileName && (
              <p className="progress_file_name m-0">{fileName?.slice(0, 30)}</p>
            )}
            <p className="text-muted m-0" style={{ fontSize: "11px" }}>
              {file && (file?.size / 1024).toFixed(2)} KB
            </p>
          </div>
          <div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="7.5"
                fill="#072C50"
              />
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="7.5"
                stroke="#072C50"
              />
              <path
                d="M11.3337 5.5L6.75033 10.0833L4.66699 8"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="w-100 d-flex align-items-start justify-content-between gap-2">
          <meter
            max={100}
            min={0}
            value={count}
            style={{ width: "70%" }}
          ></meter>
          <span style={{ fontSize: "11px" }}>{count} %</span>
        </div>
      </div>
    </div>
  );
}
