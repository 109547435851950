import "./dashboard.scss";
import NewDashboardLayout from "../../../layouts/dashboard-layout/NewDashboardLayout";
// import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import EmptyState from "../../../components/empty-state/empty-state";

const Dashboard = () => {
  return (
    <NewDashboardLayout>
      <div>
        <div className="color-secondary fw-600 font-25 mb-1">Dashboard</div>

        <div className="pt-5">
          <EmptyState />
        </div>
        {/* <section className="dashboard-cards row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">ALL LOANS
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">RUNNING LOANS
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-pinky bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">PAID LOANS
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-pinky bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">PAST DUE LOANS
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">ALL USERS
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">ACTIVE USERS
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">USERS WITH LOANS
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">USERS WITH SAVINGS
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">DISBURSEENT WALLET FACE VALUE
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">DISBURSMENT WALLET BOOK VALUE
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">TOTAL WALLET BALANCES
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="color-grey fw-500 font-8">TOTAL SAVINGS BALANCES
                                <div className="icon">
                                    <DocumentScannerOutlinedIcon className="round-70 color-lpurple bg-lpurple font-40 p-1" />
                                </div>
                                <div className="color-secondary fw-500 font-25 mt-2 mb-1">₦36,000</div>
                                <div className="text-hr">
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 ">
                                        <div className=" mb-3 fw-500 mt-2 p-1">COUNT
                                            <div className="color-secondary mb-3 font-14 fw-500">25</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1">DISBURSED
                                            <div className="color-secondary font-14 fw-500">₦32,655</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6" >
                                        <div className="  fw-500 mt-2 p-1 ">PAID
                                            <div className=" color-secondary font-14 fw-500">₦35,000</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      </div>
    </NewDashboardLayout>
  );
};

export default Dashboard;
