/* eslint-disable react-hooks/exhaustive-deps */
import NewDashboardLayout from "../../../layouts/dashboard-layout/NewDashboardLayout";
import { TextInput, Button, Textarea } from "@mantine/core";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import SavingsService from "../../../services/api/savings/savings";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

export default function EditNewSavingsProduct() {
  const navigate = useNavigate();
  const { editSavingsProduct, getSavingsProducts } = SavingsService();

  const payload = {
    pageNo: "0",
    pageSize: "50",
  };

  const { data: products, refetch } = useQuery({
    queryKey: ["getSavingsProducts"],
    queryFn: () => getSavingsProducts(payload),
  });

  const initialValues = {
    name: "",
    defaultRate: "",
    productID: "",
    period: "",
    description: "",
    type: "",
    productPaymentsMethod: "",
    taxRate: "",
  };
  const validationSchema = object().shape({
    name: string().required("This field is required"),
    defaultRate: string().required("This field is required"),
    productID: string().required("This field is required"),
    period: string().required("This field is required"),
    description: string().required("This field is required"),
    type: string().required("This field is required"),
    productPaymentsMethod: string().required("This field is required"),
    taxRate: string().required("This field is required"),
  });
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      {
        defaultRate,
        description,
        name,
        period,
        productID,
        productPaymentsMethod,
        taxRate,
        type,
      },
      { resetForm, setSubmitting }
    ) => {
      const payload = {
        defaultRate,
        description,
        name,
        period,
        productID,
        productPaymentsMethod,
        taxRate,
        type,
      };
      const data = await editSavingsProduct(payload);
      if (data?.status === "SUCCESS") {
        resetForm();
        setSubmitting(false);
        refetch();
        navigate("/main/savings-product");
        products &&
          localStorage.setItem("savingsProduct", JSON.stringify(products));
      }
    },
  });

  useEffect(() => {
    const singleProduct = JSON.parse(
      localStorage.getItem("editSavingsProduct")
    );
    setFieldValue("name", singleProduct.name, true);
    setFieldValue("productID", singleProduct.productID, true);
    setFieldValue("period", singleProduct.period, true);
    setFieldValue("defaultRate", singleProduct.defaultRate, true);
    setFieldValue("type", singleProduct.type, true);
    setFieldValue(
      "productPaymentsMethod",
      singleProduct.productPaymentsMethod,
      true
    );
    setFieldValue("taxRate", singleProduct.taxRate, true);
    setFieldValue("description", singleProduct.description, true);
  }, []);
  return (
    <NewDashboardLayout>
      <div
        role="button"
        className="d-flex align-items-center gap-2 text_primary"
        onClick={() => navigate(-1)}
      >
        <FaChevronLeft />
        <h6 className="m-0">Back</h6>
      </div>
      <h3 className="text_primary mt-2">Edit Savings Product</h3>
      <h6 className="text_primary mt-4">Savings Product</h6>

      <form onSubmit={handleSubmit}>
        <div className="w-100 mt-2 p-3">
          <div className="w-50 mt-4">
            <TextInput
              label="Product name"
              placeholder="Enter Name of product"
              radius={8}
              name="name"
              value={values.name}
              onChange={handleChange}
              error={errors.name}
            />

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Product ID"
                  placeholder="Enter Product ID"
                  radius={8}
                  className="mt-2 text_primary"
                  name="productID"
                  value={values.productID}
                  onChange={handleChange}
                  error={errors.productID}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Period"
                  placeholder="Enter Period"
                  radius={8}
                  className="mt-2 text_primary"
                  name="period"
                  value={values.period}
                  onChange={handleChange}
                  error={errors.period}
                />
              </div>
            </div>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Default Rate"
                  placeholder="Enter Default Rate"
                  radius={8}
                  className="mt-2 text_primary"
                  name="defaultRate"
                  value={values.defaultRate}
                  onChange={handleChange}
                  error={errors.defaultRate}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Type"
                  placeholder="Enter Type"
                  radius={8}
                  className="mt-2 text_primary"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  error={errors.type}
                />
              </div>
            </div>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Product Payment Method"
                  placeholder="Enter Product Payment Method"
                  radius={8}
                  className="mt-2 text_primary"
                  name="productPaymentsMethod"
                  value={values.productPaymentsMethod}
                  onChange={handleChange}
                  error={errors.productPaymentsMethod}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Tax Rate"
                  placeholder="Enter Tax Rate"
                  radius={8}
                  className="mt-2 text_primary"
                  name="taxRate"
                  value={values.taxRate}
                  onChange={handleChange}
                  error={errors.taxRate}
                />
              </div>
            </div>

            <Textarea
              label="Description"
              placeholder="Enter Description"
              radius={8}
              className="mt-2 text_primary w-100"
              name="description"
              value={values.description}
              onChange={handleChange}
              error={errors.description}
            />

            {/* <div className="w-100 d-flex align-items-center gap-3">
            <div className="w-50">
              <TextInput
                label="Product Range Code"
                placeholder="code129"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
            <div className="w-50">
              <TextInput
                label="Product Payment Method"
                placeholder="MOBILE_SMART_SAVINGS"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
          </div>

          <div className="w-100 d-flex align-items-center gap-3 mt-2">
            <div className="w-50 d-flex flex-column gap-1">
              <h6 className="text_primary">isFixed</h6>
              <div className="d-flex align-items-center gap-2">
                <div className="d-flex align-items-center gap-1">
                  <input type="checkbox" name="" id="" />
                  <p className="m-0 text_primary">Yes</p>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <input type="checkbox" name="" id="" />
                  <p className="m-0 text_primary">No</p>
                </div>
              </div>
            </div>

            <div className="w-50 d-flex flex-column gap-1">
              <h6 className="fs-10 text_primary">isLocked</h6>
              <div className="d-flex align-items-center gap-2">
                <div className="d-flex align-items-center gap-1">
                  <input type="checkbox" name="" id="" />
                  <p className="m-0 text_primary">Yes</p>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <input type="checkbox" name="" id="" />
                  <p className="m-0 text_primary">No</p>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>

        <div
          className="w-100 py-3 d-flex align-items-center justify-content-end"
          style={{
            borderBottom: "1px solid #c6c7c9",
            borderTop: "1px solid #c6c7c9",
          }}
        >
          <Button
            className="primary_btn px-5"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Save
          </Button>
        </div>
      </form>

      {/* <div className="w-100 mt-4 p-3">
        <div className="w-50 mt-4">
          <Textarea
            label="Description"
            placeholder="Save for the future of your kids"
            radius={8}
            className="mt-2 text_primary"
          />

          <h5 className="text_primary mt-4">Product Offering</h5>

          <div className="w-100 d-flex align-items-center gap-3">
            <div className="w-50">
              <TextInput
                label="Minimum Amount"
                placeholder="Enter Minimum Amount"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
            <div className="w-50">
              <TextInput
                label="Maximum Amount"
                placeholder="Enter Maximum Amount"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
          </div>

          <div className="w-100 d-flex align-items-center gap-3">
            <div className="w-50">
              <TextInput
                label="Interest Rate"
                placeholder="Enter Interest Rate"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
            <div className="w-50">
              <TextInput
                label="Product Range Code"
                placeholder="Enter Product Range Code"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
          </div>

          <div className="w-100 d-flex align-items-center gap-3">
            <div className="w-50">
              <TextInput
                label="Interest Period"
                placeholder="Enter Interest Period"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
            <div className="w-50">
              <TextInput
                label="Tenor Period"
                placeholder="Enter Tenor Period"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
          </div>

          <div className="w-100 d-flex align-items-center gap-3">
            <div className="w-50">
              <TextInput
                label="Tenor Start"
                placeholder="Enter Start"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
            <div className="w-50">
              <TextInput
                label="Tenor End"
                placeholder="Enter End"
                radius={8}
                className="mt-2 text_primary"
              />
            </div>
          </div>

          <div className="w-100 d-flex align-items-center gap-3 mt-2">
            <div className="w-50 d-flex flex-column gap-1">
              <h6 className="mt-2 text_primary">isFixed</h6>
              <div className="d-flex align-items-center gap-2">
                <div className="d-flex align-items-center gap-1">
                  <input type="checkbox" name="" id="" />
                  <p className="m-0 text_primary">Yes</p>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <input type="checkbox" name="" id="" />
                  <p className="m-0 text_primary">No</p>
                </div>
              </div>
            </div>

            <div className="w-50 d-flex flex-column gap-1">
              <h6 className="fs-10 text_primary">isLocked</h6>
              <div className="d-flex align-items-center gap-2">
                <div className="d-flex align-items-center gap-1">
                  <input type="checkbox" name="" id="" />
                  <p className="m-0 text_primary">Yes</p>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <input type="checkbox" name="" id="" />
                  <p className="m-0 text_primary">No</p>
                </div>
              </div>
            </div>
          </div>

          <Textarea
            label="Tenor Description:"
            placeholder="180 to 365 days"
            radius={8}
            className="mt-2 text_primary"
          />
        </div>
      </div> */}
    </NewDashboardLayout>
  );
}
