import { Button, Input, Modal, TextInput } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import SavingsService from "../../services/api/savings/savings";
import { Spin } from "antd";
import { openErrorNotification } from "../../utils/Notifications";
import FileLoading from "../FileLoading/FileLoading";

export default function UploadSettlementReport({
  isModalOpen,
  hideModal,
  refetch,
}) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [uploadName, setUploadName] = useState(null);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState("PAYSTACK");

  const { uploadSettlementReport: uploadReport } = SavingsService();

  const fileRef = useRef(null);

  const handleFileChange = (e) => {
    const target = e.target.files[0];
    setFile(target);
    setUploadName(target?.name);
  };

  const handleClose = () => {
    hideModal();
    setFile(null);
    setFileName(null);
    setStartDate(null);
    setEndDate(null);
    setUploadName(null);
    setCount(0);
    setType("PAYSTACK");
  };

  const title = (
    <div>
      <h3
        className="m-0"
        style={{ fontSize: "16px", fontWeight: "700", color: "#101828" }}
      >
        Upload document
      </h3>
      <p
        className="m-0"
        style={{ fontSize: "12px", color: "#475467", marginTop: "2px" }}
      >
        Upload and attach file to this project.
      </p>
    </div>
  );

  const handleUploadFile = async () => {
    if (!startDate || !endDate || !file || !type || !fileName) {
      openErrorNotification("Please fill your credentials properly", "ERROR");
      return;
    } else {
      setLoading(true);
      await uploadReport(file, fileName, startDate, endDate, type);
      setLoading(false);
      handleClose();
      refetch();
    }
  };

  useEffect(() => {
    if (uploadName) {
      const interval = setInterval(
        () => {
          if (count < 100) {
            setCount(count + 1);
          }
        },
        file && file?.size <= 350000
          ? 1
          : file && file?.size > 350000 && file?.size < 900000
          ? 4
          : 8
      );
      return () => clearInterval(interval);
    }
  }, [count, file, file?.size, uploadName]);

  return (
    <Modal
      title={title}
      opened={isModalOpen}
      onClose={handleClose}
      centered
      radius={8}
    >
      <Spin spinning={loading}>
        <TextInput
          radius={8}
          label="File Name"
          placeholder="Enter file name"
          value={fileName}
          onChange={(e) => setFileName(e.currentTarget.value)}
        />

        <div className="mt-2">
          <label
            htmlFor="type"
            style={{ fontSize: "14px", color: "#344054", fontWeight: "500" }}
          >
            Settlement Type
          </label>

          <Input
            component="select"
            id="type"
            radius={8}
            placeholder="Select Settlement Type"
            value={type}
            onChange={(e) => setType(e.currentTarget.value)}
            defaultValue={"Paystack"}
          >
            <option value="PAYSTACK">Paystack</option>
            <option value="NIBBS">Nibbs</option>
            <option value="FLUTTERWAVE">Flutterwave</option>
          </Input>
        </div>

        <div
          style={{
            width: "100%",
            height: "126px",
            color: "#475467",
            border: "1px solid #E4E7EC",
            borderRadius: "12px",
            marginTop: "1.2rem",
            display: "grid",
            placeContent: "center",
          }}
          onClick={() => {
            fileRef.current?.click();
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            <img src="/upload.svg" alt="Upload" />
            <p className="m-0">
              <span style={{ fontWeight: "700", color: "#072C50" }}>
                Click to upload
              </span>{" "}
              or drag and drop
            </p>
            <p className="m-0">PNG or JPEG (max. 800x400px)</p>
          </div>
        </div>

        {file && (
          <FileLoading count={count} file={file} fileName={uploadName} />
        )}

        <div className="w-100 d-flex align-items-center gap-3 mt-2">
          <div className="w-100">
            <p className="m-0" style={{ fontSize: "14px", fontWeight: "600" }}>
              Start Date:
            </p>

            <input
              type="date"
              name=""
              id=""
              className="w-100 rounded-1"
              style={{
                border: "1px solid #ddd",
                outline: "0",
                padding: "0 3px",
              }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          <div className="w-100">
            <p className="m-0" style={{ fontSize: "14px", fontWeight: "600" }}>
              End Date
            </p>

            <input
              type="date"
              name=""
              id=""
              className="w-100 rounded-1"
              style={{
                border: "1px solid #ddd",
                outline: "0",
                padding: "0 3px",
              }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>

        <div className="w-100 d-flex align-items-center justify-content-center gap-2 mt-3">
          <Button
            color="#fff"
            fullWidth
            style={{ color: "#344054", border: "1px solid #D0D5DD" }}
            onClick={hideModal}
            radius={8}
          >
            Cancel
          </Button>

          <Button
            color="#072C50"
            fullWidth
            radius={8}
            onClick={handleUploadFile}
          >
            Upload
          </Button>
        </div>

        <input
          type="file"
          name="file"
          id="file"
          hidden
          ref={fileRef}
          accept=".xlsx, .xls, .csv"
          onChange={handleFileChange}
        />
      </Spin>
    </Modal>
  );
}
