import "../login/login.scss";
import "./forgot-password.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import resetPasswordImage from "../../../../src/assets/images/forget-password.jpg";
import logo from "../../../../src/assets/images/logo.png";
import AuthService from "../../../services/api/auth/auth";
import CustomLoader from "../../../components/loader/loader";

const ForgotPassword = () => {
  const { forgetPasswordEmailAPI, forgetPassLoader } = AuthService();

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={yup.object({
        email: yup
          .string()
          .required("Email field is requied")
          .email("Invalid email address")
          .matches(
            /.+@(creditville)\.ng$/,
            "Please email must end with creditville.ng"
          ),
      })}
      onSubmit={(values, { resetForm }) => {
        const payload = {
          emailAddress: values.email,
          userType: "STAFF",
        };

        forgetPasswordEmailAPI(payload);
        resetForm();
      }}
    >
      {(props) => (
        <section className="pt-5 overflow-x">
          {forgetPassLoader ? (
            <CustomLoader />
          ) : (
            <Form>
              <div className="pdl-5">
                <img className="w-200" src={logo} alt="creditville-logo" />
              </div>
              <div className="row mt-5">
                <div className="col-md-6 pdl-5">
                  <img className="w-100" src={resetPasswordImage} alt="login" />
                </div>
                <div className="col-md-6 px-10">
                  <div>
                    <div className="font-30 fw-700 color-secondary ">
                      Forgot Password
                    </div>
                    <div className="color-text font-20 fw-400 mt-3 mb-5">
                      Enter your email address so we can send you a password
                      reset link.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="Email"
                      className="form-label font-16 fw-500 color-text"
                    >
                      Email
                    </label>
                    <Field
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="test@creditville.ng"
                      value={props.values.email}
                      onChange={props.handleChange}
                    ></Field>
                    <div className="form-error mt-1">
                      <ErrorMessage
                        className="form-error"
                        name="Email"
                      ></ErrorMessage>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={props.isValid === false}
                      className="border-0 w-100 font-16 mt-4 round-4 fw-500 color-white p-3 btn-login"
                    >
                      Get Link
                    </button>
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <div className="fw-500 font-12 color-secondary pointer link">
                      <Link
                        className="color-secondary text-decoration-none"
                        to="/"
                      >
                        OH! I REMEMBER MY PASSWORD
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </section>
      )}
    </Formik>
  );
};

export default ForgotPassword;
