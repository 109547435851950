import logo from "../../../src/assets/images/logo.png";
import CustomerExperience from "./SideFolder/CustomerExperience";
import WealthManagement from "./SideFolder/WealthManagement";
import Finance from "./SideFolder/Finance";
import CoporateCommunication from "./SideFolder/CoporateCommunication";
import CreditvilleCore from "./SideFolder/CreditvilleCore";
import Settings from "./SideFolder/Settings";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

export default function NewSideBar() {
  const [logOutModal, setLogOutModal] = useState(false);
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="sidebar_container">
      <div className="my-3 d-flex justify-content-center">
        <img className="w-140" src={logo} alt="creditville-logo" />
      </div>

      <CustomerExperience />
      <WealthManagement />
      <Finance />
      <CoporateCommunication />
      <CreditvilleCore />
      <Settings setLogOutModal={setLogOutModal} />

      <Modal
        centered
        destroyOnClose={true}
        visible={logOutModal}
        footer={null}
        onCancel={() => setLogOutModal(false)}
        width={450}
      >
        <div className="p-3 text-center">
          <div className="font-16 fw-500 my-4">
            Are you sure, you want to LogOut ?
          </div>
          <div className="d-flex justify-content-between mt-3">
            <button
              onClick={() => setLogOutModal(false)}
              className="border-0 font-16 p-3 w-140 round-8"
            >
              No
            </button>
            <button
              onClick={logOut}
              className="border-0 font-16 p-3 w-140 bg-dblue color-white round-8"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
