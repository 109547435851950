import { Button } from "@mantine/core";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import { openErrorNotification } from "../../utils/Notifications";

export default function TopFilter({
  showModal,
  handleFilterByDate,
  activeSettlementTab,
  refetch,
  setFilterData,
}) {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const handleRefresh = () => {
    setFrom("");
    setTo("");
    setFilterData(null);
    refetch();
  };

  const handleFilter = async () => {
    if (!from) {
      openErrorNotification("Kindly fill the start date", "ERROR");
      return;
    } else if (!to) {
      openErrorNotification("Kindly fill the end date", "ERROR");
      return;
    } else {
      await handleFilterByDate(from, to);
    }
  };

  return (
    <div className="w-100 d-flex align-items-end justify-content-between">
      <div className="d-flex align-items-end gap-2">
        <input
          type="date"
          value={from}
          onChange={(e) => setFrom(e.target.value)}
          id=""
          name=""
          max={new Date().toISOString().split("T")[0]}
          title="Enter start date"
          style={{
            outline: "0",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "3px 6px",
          }}
        />
        <input
          type="date"
          value={to}
          onChange={(e) => setTo(e.target.value)}
          id=""
          name=""
          title="Enter end date"
          max={new Date().toISOString().split("T")[0]}
          style={{
            outline: "0",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "3px 6px",
          }}
        />
        <Button radius={8} color="#072c50" onClick={handleFilter}>
          Search
        </Button>
        <Button
          radius={8}
          color="#072c50"
          onClick={handleRefresh}
          leftSection={<FiRefreshCw />}
        >
          Refresh
        </Button>
      </div>

      {activeSettlementTab === "pending" && (
        <Button
          radius={8}
          color="#072c50"
          leftSection={<FaPlus />}
          onClick={showModal}
        >
          Upload
        </Button>
      )}
    </div>
  );
}
