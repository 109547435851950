import { Modal } from "@mantine/core";
import { useFormik } from "formik";
import { object, string } from "yup";

export default function NibbsFilterModal({
  filterIsOpened,
  closeFilterModal,
  error,
  setError,
  filterNibbsTransaction,
  setTableDataByDateFilter,
  activePage,
}) {

  const validationSchema = object().shape({
    from: string().required("this field is required"),
    to: string().required("this field is required"),
  });

  const { handleSubmit, values, handleChange, isSubmitting } = useFormik({
    initialValues: {
      to: "",
      from: "",
    },
    validationSchema,
    onSubmit: async ({ from, to }, { resetForm, setSubmitting }) => {
      const data = await filterNibbsTransaction(from, activePage - 1, to);
      if (data.length > 0) closeFilterModal();
      setTableDataByDateFilter(data);
      resetForm();
      setSubmitting(false);
    },
  });
  
  return (
    <Modal
      centered
      radius={15}
      opened={filterIsOpened}
      onClose={() => {
        closeFilterModal();
        setError("");
      }}
      withCloseButton={false}
      padding="md"
    >
      <form
        onSubmit={handleSubmit}
        className="w-100 d-flex flex-column align-items-center justify-content-between gap-2 mb-2"
      >
        <div className="w-100 d-flex align-items-center justify-content-between gap-2 mb-2">
          <div className="form_group_col mt-2">
            <label htmlFor="from">From</label>
            <input
              type="date"
              id="from"
              name="from"
              value={values.from}
              onChange={handleChange}
            />
          </div>

          <div className="form_group_col mt-2">
            <label htmlFor="to">To</label>
            <input
              type="date"
              id="to"
              name="to"
              value={values.to}
              onChange={handleChange}
            />
          </div>
        </div>

        {error && (
          <span className="text-danger" style={{ fontSize: "10px" }}>
            {error}
          </span>
        )}

        <button
          type="submit"
          className="primary_btn mt-1 w-100 py-2 text-white fs-6 rounded-2"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Searching..." : "Search"}
        </button>
      </form>
    </Modal>
  );
}
