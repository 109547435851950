import React, { useState } from "react";
import "./PasswordShowHide.scss";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const PasswordShowHide = ({ field, form }) => {
    const [showHidePassword, changeShowHidePassword] = useState(false);
    // const hasError = form.touched[field.name] && form.errors[field.name];

    const hidePassword = () => {
        changeShowHidePassword(true)
    }

    const showPassword = () => {
        changeShowHidePassword(false)
    }

    return (
        <div className="form-control d-flex justify-content-between align-items-center">
            <input
                type={showHidePassword ? "text" : "password"}
                {...field}
                className="border-0 no-outline w-full"
                placeholder="**********"
            />
            {showHidePassword ?
                <button type="button" className="border-0 no-outline bg-white color-secondary" onClick={() => showPassword()}> <VisibilityIcon /> </button> :
                <button type="button" className="border-0 no-outline bg-white color-secondary"  onClick={() => hidePassword()}> <VisibilityOffIcon /> </button>
            }
        </div>
    );
};

export default PasswordShowHide;
