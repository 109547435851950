import { createContext, useContext, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

const AppContext = createContext({});

export function useAppContext() {
  return useContext(AppContext);
}

export function AppContextProvider({ children }) {
  const [activeKycTab, setActiveKycTab] = useLocalStorage(
    "activeKycTab",
    "processing"
  );
  const [activeSettlementTab, setActiveSettlementTab] = useLocalStorage(
    "activeSettlementTab",
    "pending"
  );
  const [userType, setUserType] = useLocalStorage("userType", "");
  const [kycPageNo, setKycPageNo] = useLocalStorage("kycPageNo", 1);
  const [kycPageSize, setKycPageSize] = useLocalStorage("kycPageSize", 10);
  const [bannerActive, setBannerActive] = useLocalStorage(
    "bannerActive",
    "active"
  );
  const [activeAccordion, setActiveAccordion] = useState("");

  const context = {
    activeKycTab,
    setActiveKycTab,
    userType,
    setUserType,
    kycPageNo,
    setKycPageNo,
    kycPageSize,
    setKycPageSize,
    bannerActive,
    setBannerActive,
    activeAccordion,
    setActiveAccordion,
    activeSettlementTab,
    setActiveSettlementTab,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}
