import { Modal } from "antd";
import React from "react";
import ViewBvnDetails from "../viewBvnDetails/ViewBvnDetails";

export default function ViewBvnModal({
  bvnDetailsModal,
  setBvnDetailsModal,
  userBvnData,
  isBvnUpdateDetailsLoading,
  userBvn,
  setUserBvn,
  handleUpdateBvnDetails,
}) {
  return (
    <Modal
      centered
      footer={null}
      destroyOnClose={true}
      visible={bvnDetailsModal}
      onOk={() => setBvnDetailsModal(false)}
      onCancel={() => setBvnDetailsModal(false)}
      width={500}
    >
      <ViewBvnDetails
        userBvnData={userBvnData}
        isBvnUpdateDetailsLoading={isBvnUpdateDetailsLoading}
        userBvn={userBvn}
        setUserBvn={setUserBvn}
        handleUpdateBvnDetails={handleUpdateBvnDetails}
      />
    </Modal>
  );
}
