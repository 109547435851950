import axios from "axios";
import { notification } from "antd";
import { FrownOutlined } from "@ant-design/icons";

const headers = {
  "Content-Type": "application/json",
  "access-control-allow-origin": "*",
};

const openErrorNotification = (res, status) => {
  notification.open({
    message: `${res}`,
    description: `${status}`,
    duration: 11,
    icon: <FrownOutlined style={{ color: "yellow" }} />,
  });
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers,
});

const axiosSavingInstance = axios.create({
  baseURL: process.env.REACT_APP_SAVINGS_BASE_URL,
  headers,
});

axiosSavingInstance.interceptors.request.use(
  (request) => {
    const loggedInUser = localStorage.getItem("userDetails");
    const newUser = JSON.parse(loggedInUser);
    // console.log(newUser);
    // if (newUser === null) {
    //   return Promise.reject("System under maintenance, try again later");
    // }
    request.headers.apiKey = newUser.token;
    return request;
  },

  (error) => {
    if (error.response.status === null) {
      const errMessage = error?.response?.data?.error;
      const resStatus = "System under maintenance, try again later";
      openErrorNotification(errMessage, resStatus);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (request) => {
    const loggedInUser = localStorage.getItem("userDetails");
    const newUser = JSON.parse(loggedInUser);
    // console.log(newUser);
    // if (newUser === null) {
    //   return Promise.reject("System under maintenance, try again later");
    // }
    request.headers.apiKey = newUser.token;
    return request;
  },
  (error) => {
    if (error.response.status === null) {
      const errMessage = error?.response?.data?.error;
      const resStatus = "System under maintenance, try again later";
      console.log("response1", error.response);
      openErrorNotification(errMessage, resStatus);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

axiosSavingInstance.interceptors.response.use(
  (response) => {
    if (response?.status >= 200 && response?.status <= 226) {
      // console.log('check something', response?.data?.status)
      // const errMessage = response?.response?.data?.data?.resp?.message;
      // const resStatus = response?.response?.data?.status;
      // let error =
      //     response?.data?.errors.toString() || response?.data?.description;
      // return Promise.reject(error);
    }
    return response;
  },
  (error) => {
    // if (error.response.status === null) {
    //   const errMessage = "Error";
    //   const resStatus = "System under maintenance, try again later";
    //   openErrorNotification(errMessage, resStatus);
    //   return Promise.reject(error);
    // }
    if (error.response.status === 500) {
      const errMessage = error?.response?.data?.error;
      const resStatus =
        "Our Enginners are currently working on it, please try again in few minutes";
      openErrorNotification(errMessage, resStatus);
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      // return to login and clear token;
      // const errMessage = error?.response?.data?.error;
      // const resStatus = 'Our Enginners are currently working on it, please try again in few minutes';
      // openErrorNotification(errMessage, resStatus);
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    }
    if (error.response.status === 403) {
      // const errMessage = error?.response?.data?.error;
      // const resStatus = 'Our Enginners are currently working on it, please try again in few minutes';
      // openErrorNotification(errMessage, resStatus)
      return Promise.reject(
        "Your session has expired you need to login again."
      );
    }
    if (error.response.status === 405) {
      // return to login and clear token;
      const errMessage = error?.response?.data?.error;
      const resStatus =
        "Our Enginners are currently working on it, please try again in few minutes";
      openErrorNotification(errMessage, resStatus);
      return Promise.reject(error);
    }
    if (typeof error.response === "undefined") {
      const errMessage = "Oops Sorry";
      const resStatus =
        "Our Engineers are inform, This could be a CORS issue or a dropped internet connection";
      openErrorNotification(errMessage, resStatus);
      return Promise.reject("Please try again in few mins.");
    }
    if (error?.response?.data?.resp?.code === "cv401") {
      const errMessage = error?.response?.data?.resp?.code?.message;
      const resStatus = "Oops!!";
      openErrorNotification(resStatus, errMessage);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 500) {
      const errMessage = error?.response?.data?.error;
      const resStatus =
        "Our Enginners are currently working on it, please try again in few minutes";
      openErrorNotification(errMessage, resStatus);
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    }
    if (error.response.status === 403) {
      return Promise.reject(
        "Your session has expired you need to login again."
      );
    }
    if (error.response.status === 405) {
      // return to login and clear token;
      const errMessage = error?.response?.data?.error;
      const resStatus =
        "Our Enginners are currently working on it, please try again in few minutes";
      openErrorNotification(errMessage, resStatus);
      return Promise.reject(error);
    }
    
    if (error.response.data?.responseCode === "cv96") {
      openErrorNotification(error.response.data?.responseMessage, "ERROR");
      return Promise.reject(error.response.data?.responseMessage);
    }
    if (error.response.data?.staffResponse?.responseCode === "cv96") {
      openErrorNotification(
        error.response.data?.staffResponse?.responseMessage,
        "ERROR"
      );
      return Promise.reject(
        error.response.data?.staffResponse?.responseMessage
      );
    }
    if (
      error?.response?.data?.resp?.code === "cv401" ||
      error?.response?.data?.data?.resp?.code === "cv401" ||
      error?.response?.data?.code === "cv401" ||
      error?.response?.code === "cv401" ||
      error.response.data?.staffResponse?.responseCode === "cv401" ||
      error.response.data?.responseCode === "cv401"
    ) {
      window.location.href = "/main/unauthorized";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export { axiosSavingInstance };

export default axiosInstance;
