import "./loader.scss";
import logo from '../../../src/assets/images/logo.png';


const CustomLoader = () => {
    return (
        <div className="d-flex  flex-column fade-out justify-content-center loader-container">
            <div className="logo  d-flex justify-content-center">
                <img className="w-240" src={logo} alt="creditville-logo" />
            </div>
            <div className="text-center color-secondary mt-3 fw-500 font-16">Please wait....</div>
        </div>
    );
}

export default CustomLoader