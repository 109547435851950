// import { BsArrowUpRight, BsArrowDownLeft } from "react-icons/bs";
import { RiArrowLeftDownLine, RiArrowRightUpLine } from "react-icons/ri";

export default function DebitIconCard({ status }) {
  return (
    <div
      style={{
        width: "35px",
        height: "35px",
        backgroundColor: status === "debit" ? "#FDE3EA" : "#DFF1C8",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {status === "debit" ? (
        <RiArrowRightUpLine size={17} style={{ color: "#80011F" }} />
      ) : (
        <RiArrowLeftDownLine size={17} style={{ color: "#73B424" }} />
      )}
    </div>
  );
}
