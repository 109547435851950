import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Modal, Dropdown } from "antd";
import { FaSortDown } from "react-icons/fa";
import { Avatar } from "@mantine/core";
import { BiLogOutCircle } from "react-icons/bi";

export default function NewNavbar() {
  const [logOutModal, setLogOutModal] = useState(false);
  const [user, setUser] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const loggedInUser = localStorage.getItem("userDetails");
    if (loggedInUser) {
      const newUser = JSON.parse(loggedInUser);
      setUser(newUser);
    }
  }, []);

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <button
          onClick={() => setLogOutModal(true)}
          className="font-14 border-0 bg-transparent color-secondary pointer d-flex align-items-center gap-2"
        >
          <BiLogOutCircle className="avatar-sec" />
          Logout
        </button>
        <Modal
          centered
          destroyOnClose={true}
          visible={logOutModal}
          footer={null}
          onCancel={() => setLogOutModal(false)}
          width={450}
        >
          <div className="p-3 text-center">
            <div className="font-16 fw-500 my-4">
              Are you sure, you want to LogOut ?
            </div>
            <div className="d-flex justify-content-between mt-3">
              <button
                onClick={() => setLogOutModal(false)}
                className="border-0 font-16 p-3 w-140 round-8"
              >
                No
              </button>
              <button
                onClick={logOut}
                className="border-0 font-16 p-3 w-140 bg-dblue color-white round-8"
              >
                Yes
              </button>
            </div>
          </div>
        </Modal>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="bg-white shadow-sm new_navbar">
      <div className="topbar py-3">
        <div className="d-flex align-items-center gap-3">
          <Avatar src="/broken-image.jpg" />
          <label
            htmlFor="fullName"
            className="font-16 color-secondary fw-500 avatar-sec"
            role="button"
          >
            {user?.firstName} {user?.lastName}
          </label>
          
          <Dropdown overlay={menu} trigger={["click"]}>
            <button
              className="ant-dropdown-link d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "transparent",
                border: "0",
                outline: "0",
                width: "20px",
                height: "20px",
              }}
              id="fullName"
            >
              <FaSortDown className="color-secondary pointer" />
            </button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
