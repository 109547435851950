import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import EmptyState from "../../../../components/empty-state/empty-state";

const LoanRequest = () => {
  return (
    <NewDashboardLayout>
      <div>
        <div className="color-secondary fw-600 font-25 mb-1">Loan Request</div>

        <div className="pt-5">
          <EmptyState />
        </div>
      </div>
    </NewDashboardLayout>
  );
};

export default LoanRequest;
