import React from "react";
import { useLocation } from "react-router-dom";
import { Table } from "@mantine/core";
import NewDashboardLayout from "../../../layouts/dashboard-layout/NewDashboardLayout";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function SingleSavingsProduct() {
  const location = useLocation();
  const pathname = location.pathname;
  const ID = pathname.split("/")[4];

  const navigate = useNavigate();

  const data = JSON.parse(localStorage.getItem("savingsProduct"));
  const savingsProduct = data?.find((product) => product.id === Number(ID));
  const savingsProductRange = savingsProduct?.savingsProductRangeDtos;

  return (
    <NewDashboardLayout>
      <div
        role="button"
        className="d-flex align-items-center gap-2 text_primary"
        onClick={() => navigate(-1)}
      >
        <FaChevronLeft />
        <h6 className="m-0 savings_title">Back</h6>
      </div>
      <h3 className="mt-4" style={{ color: "#174375" }}>
        Saving Product
      </h3>
      <div
        className="mx-auto shadow-lg mt-5 p-4 bg-white"
        style={{ width: "92%", borderRadius: "20px" }}
      >
        <Table
          style={{ tableLayout: "fixed" }}
          withTableBorder={false}
          withColumnBorders={false}
          withRowBorders={false}
        >
          <Table.Thead style={{ zIndex: "50" }}>
            <Table.Tr>
              <Table.Th>Id</Table.Th>
              <Table.Th>Created On</Table.Th>
              <Table.Th>Default Rate</Table.Th>
              <Table.Th>Description</Table.Th>
              <Table.Th>Name</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>{savingsProduct?.id || "-"}</Table.Td>
              <Table.Td>
                {new Date(savingsProduct?.createdOn).toLocaleString()}
              </Table.Td>
              <Table.Td>{savingsProduct?.defaultRate || "-"}</Table.Td>
              <Table.Td>{savingsProduct?.description || "-"}</Table.Td>
              <Table.Td>{savingsProduct?.name || "-"}</Table.Td>
            </Table.Tr>
          </Table.Tbody>

          <Table.Thead style={{ zIndex: "50", margin: "20px 0" }}>
            <Table.Tr>
              <Table.Th>Period</Table.Th>
              <Table.Th>Product ID</Table.Th>
              <Table.Th>Product Payments Method</Table.Th>
              <Table.Th>Tax Rate</Table.Th>
              <Table.Th>Type</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>{savingsProduct?.period || "-"}</Table.Td>
              <Table.Td>{savingsProduct?.productID || "-"}</Table.Td>
              <Table.Td>
                {savingsProduct?.productPaymentsMeTdod || "-"}
              </Table.Td>
              <Table.Td>{savingsProduct?.taxRate || "-"}</Table.Td>
              <Table.Td>{savingsProduct?.type || "-"}</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </div>

      {savingsProductRange?.map(
        ({
          id,
          interestPeriod,
          interestRate,
          isFixed,
          isLocked,
          maximumAmount,
          minimumAmount,
          period,
          productId,
          productRangeCode,
          savingProductsDtos,
          tenorDescription,
          tenorEnd,
          tenorPeriod,
          tenorStart,
        }) => (
          <div
            className="mx-auto shadow-lg mt-5 p-4 bg-white"
            style={{ width: "92%", borderRadius: "20px" }}
          >
            <h6 className="fs-10 mb-3" style={{ color: "#174375" }}>
              Savings Product Range {id}
            </h6>
            <Table>
              <Table.Thead style={{ zIndex: "50" }}>
                <Table.Tr>
                  <Table.Th>Id</Table.Th>
                  <Table.Th>Interest Period</Table.Th>
                  <Table.Th>Interest Rate</Table.Th>
                  <Table.Th>Is Fixed</Table.Th>
                  <Table.Th>Is Locked</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>{id || "-"}</Table.Td>
                  <Table.Td>{interestPeriod || "-"}</Table.Td>
                  <Table.Td>{interestRate || "-"}</Table.Td>
                  <Table.Td>{String(isFixed) || "-"}</Table.Td>
                  <Table.Td>{String(isLocked) || "-"}</Table.Td>
                </Table.Tr>
              </Table.Tbody>

              <Table.Thead style={{ zIndex: "50", marginTop: "20px" }}>
                <Table.Tr>
                  <Table.Th>Maximum Amount</Table.Th>
                  <Table.Th>Minimum Amount</Table.Th>
                  <Table.Th>Tenor Start</Table.Th>
                  <Table.Th>Period</Table.Th>
                  <Table.Th>Product Id</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>{maximumAmount || "-"}</Table.Td>
                  <Table.Td>{minimumAmount || "-"}</Table.Td>
                  <Table.Td>{tenorStart || "-"}</Table.Td>
                  <Table.Td>{period || "-"}</Table.Td>
                  <Table.Td>{productId || "-"}</Table.Td>
                </Table.Tr>
              </Table.Tbody>

              <Table.Thead style={{ zIndex: "50", margin: "20px 0" }}>
                <Table.Tr>
                  <Table.Th>Product Range Code</Table.Th>
                  <Table.Th>Saving Products</Table.Th>
                  <Table.Th>Tenor Description</Table.Th>
                  <Table.Th>Tenor End</Table.Th>
                  <Table.Th>Tenor Period</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>{productRangeCode || "-"}</Table.Td>
                  <Table.Td>{savingProductsDtos || "-"}</Table.Td>
                  <Table.Td>{tenorDescription || "-"}</Table.Td>
                  <Table.Td>{tenorEnd || "-"}</Table.Td>
                  <Table.Td>{tenorPeriod || "-"}</Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </div>
        )
      )}
    </NewDashboardLayout>
  );
}
