import axiosInstance from "../../interceptors/axiosInterceptors";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import axios from "axios";

const openErrorNotification = (res, status) => {
  notification.open({
    message: `${status}`,
    description: `${res}`,
    duration: 11,
    icon: <FrownOutlined style={{ color: "red" }} />,
  });
};

const openSuccessNotification = (res, status) => {
  notification.open({
    message: `${status}`,
    description: `${res}`,
    duration: 11,
    icon: <SmileOutlined style={{ color: "green" }} />,
  });
};

const headers = {
  "Content-Type": "application/json",
  "access-control-allow-origin": "*",
};

const axiosLoginInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers,
});

const USER_URL = process.env.REACT_APP_USER_SERVICE_PATH;

const AuthService = () => {
  const navigate = useNavigate();
  const [forgetPassLoader, setForgetPassLoader] = useState(false);
  const [resetPassLoader, setResetPassLoader] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);

  const loginAPI = async (payload) => {
    setLoginLoader(true);
    try {
      const { data } = await axiosLoginInstance.post(
        `${USER_URL}/login/staff`,
        payload
      );
      if (data?.status?.toLowerCase() === "success") {
        localStorage.setItem("userDetails", JSON.stringify(data?.data));
        setLoginLoader(false);
        openSuccessNotification(data?.data?.resp?.message, "Success");
        navigate("/main/customer");
        return data;
      }
    } catch (error) {
      const errMessage = error?.response?.data?.data?.resp?.message;
      const resStatus = error?.response?.data?.status;
      openErrorNotification(errMessage, resStatus);
      setLoginLoader(false);
      return error;
    }
  };

  const searchAPI = async (phone, status, name, bvn) => {
    try {
      const { data } = await axiosInstance.get(`${USER_URL}/customer/all`, {
        params: {
          phone,
          status,
          name,
          bvn,
        },
      });
      return data;
    } catch (error) {
      return error;
    }
  };

  const forgetPasswordEmailAPI = async (payload) => {
    // System under maintenance, try again later
    setForgetPassLoader(true);
    try {
      const { data } = await axiosLoginInstance.post(
        `${USER_URL}/password/init-forget-password`,
        payload
      );
      setForgetPassLoader(false);
      openSuccessNotification(data?.data?.resp?.message, data?.status);
      return data;
    } catch (error) {
      const errMessage =
        error?.response?.data?.data?.resp?.message ||
        error?.response?.data?.message ||
        error.message;
      const resStatus = "Error";
      openErrorNotification(errMessage, resStatus);
      setForgetPassLoader(false);
      return error;
    }
  };

  const forgetPasswordResetAPI = async (payload) => {
    setResetPassLoader(true);
    try {
      const { data } = await axiosLoginInstance.post(
        `${USER_URL}/password/process-forget-password`,
        payload
      );
      setResetPassLoader(false);
      navigate("/reset-success");
      return data;
    } catch (error) {
      const errMessage = error?.response?.data?.data?.resp?.message;
      const resStatus = error?.response?.data?.status;
      openErrorNotification(errMessage, resStatus);
      setResetPassLoader(false);
      return error;
    }
  };

  const resetPasswordStaffAPI = async (payload) => {
    setResetPassLoader(true);
    try {
      const url = `${USER_URL}/password/staff/set-staff-password`;
      console.log("hello");
      const { data } = await axiosLoginInstance.post(url, payload);
      setResetPassLoader(false);
      if (data?.status === "SUCCESS") {
        openSuccessNotification(data?.data?.resp?.message, "SUCCESS");
        navigate("/reset-success");
      } else {
        openErrorNotification(data?.data?.resp?.message, "Error");
      }
      navigate("/reset-success");
      return data;
    } catch (error) {
      const errMessage =
        error?.response?.data?.data?.resp?.message || error.message;
      const resStatus = error?.response?.data?.status || "Error";
      openErrorNotification(errMessage, resStatus);
      setResetPassLoader(false);
      return error;
    }
  };

  return {
    loginAPI,
    forgetPasswordEmailAPI,
    setLoginLoader,
    setForgetPassLoader,
    setResetPassLoader,
    loginLoader,
    forgetPassLoader,
    resetPassLoader,
    forgetPasswordResetAPI,
    resetPasswordStaffAPI,
    searchAPI,
  };
};

export default AuthService;
