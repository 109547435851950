import NewDashboardLayout from "../../../layouts/dashboard-layout/NewDashboardLayout";
import { TextInput, Button, Textarea } from "@mantine/core";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import SavingsService from "../../../services/api/savings/savings";
import { useQuery } from "@tanstack/react-query";

export default function CreateNewSavingsProduct() {
  const navigate = useNavigate();
  const {
    addSavingsProduct,
    createSavingsProductRange,
    registerProduct,
    getSavingsProducts,
  } = SavingsService();

  const payload = {
    pageNo: "0",
    pageSize: "50",
  };

  const { data: products, refetch } = useQuery({
    queryKey: ["getSavingsProducts"],
    queryFn: () => getSavingsProducts(payload),
  });

  const initialValues = {
    name: "",
    defaultRate: "",
    productID: "",
    period: "",
    description: "",
    type: "",
    productPaymentsMethod: "",
    taxRate: "",

    minimumAmount: "",
    maximumAmount: "",
    interestRate: "",
    tenorStart: "",
    productRangeCode: "",
    tenorEnd: "",
    tenorDescription: "",
  };
  const validationSchema = object().shape({
    name: string().required("This field is required"),
    defaultRate: string().required("This field is required"),
    productID: string().required("This field is required"),
    period: string().required("This field is required"),
    description: string().required("This field is required"),
    type: string().required("This field is required"),
    productPaymentsMethod: string().required("This field is required"),
    taxRate: string().required("This field is required"),

    minimumAmount: string().required("This field is required"),
    maximumAmount: string().required("This field is required"),
    interestRate: string().required("This field is required"),
    tenorStart: string().required("This field is required"),
    productRangeCode: string().required("This field is required"),
    tenorEnd: string().required("This field is required"),
    tenorDescription: string().required("This field is required"),
  });

  const { values, handleChange, handleSubmit, errors, isSubmitting, isValid } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (
        {
          defaultRate,
          description,
          name,
          period,
          productID,
          productPaymentsMethod,
          taxRate,
          type,

          minimumAmount,
          maximumAmount,
          interestRate,
          tenorStart,
          productRangeCode,
          tenorEnd,
          tenorDescription,
        },
        { resetForm, setSubmitting }
      ) => {
        const payload = {
          defaultRate,
          description,
          name,
          period,
          productID,
          productPaymentsMethod,
          taxRate,
          type,
        };
        const payload2 = {
          minimumAmount,
          maximumAmount,
          interestRate,
          tenorStart,
          productRangeCode,
          tenorEnd,
          tenorDescription,
        };
        const payload3 = {
          productID,
          productRangeCode: [productRangeCode],
        };
        await addSavingsProduct(payload);
        await createSavingsProductRange(payload2);
        const data = await registerProduct(payload3);

        if (data?.status === "SUCCESS") {
          resetForm();
          setSubmitting(false);
          refetch();
          navigate("/main/savings-product");
          products &&
            localStorage.setItem("savingsProduct", JSON.stringify(products));
        }
      },
    });
  return (
    <NewDashboardLayout>
      <div
        role="button"
        className="d-flex align-items-center gap-2 text_primary"
        onClick={() => navigate(-1)}
      >
        <FaChevronLeft />
        <h6 className="m-0">Back</h6>
      </div>
      <h3 className="text_primary mt-3">Create Savings Product</h3>
      <h6 className="text_primary mt-4">Savings Product</h6>

      <form onSubmit={handleSubmit} style={{ position: "relative" }}>
        <div className="w-100 mt-2 p-3 mb-5">
          <div className="w-50 mt-4">
            <TextInput
              label="Product name"
              placeholder="Enter Name of product"
              radius={8}
              name="name"
              value={values.name}
              onChange={handleChange}
              error={errors.name}
            />

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Product ID"
                  placeholder="Enter Product ID"
                  radius={8}
                  className="mt-2 text_primary"
                  name="productID"
                  value={values.productID}
                  onChange={handleChange}
                  error={errors.productID}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Period"
                  placeholder="Enter Period"
                  radius={8}
                  className="mt-2 text_primary"
                  name="period"
                  value={values.period}
                  onChange={handleChange}
                  error={errors.period}
                />
              </div>
            </div>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Default Rate"
                  placeholder="Enter Default Rate"
                  radius={8}
                  className="mt-2 text_primary"
                  name="defaultRate"
                  value={values.defaultRate}
                  onChange={handleChange}
                  error={errors.defaultRate}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Type"
                  placeholder="Enter Type"
                  radius={8}
                  className="mt-2 text_primary"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  error={errors.type}
                />
              </div>
            </div>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Product Payment Method"
                  placeholder="Enter Product Payment Method"
                  radius={8}
                  className="mt-2 text_primary"
                  name="productPaymentsMethod"
                  value={values.productPaymentsMethod}
                  onChange={handleChange}
                  error={errors.productPaymentsMethod}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Tax Rate"
                  placeholder="Enter Tax Rate"
                  radius={8}
                  className="mt-2 text_primary"
                  name="taxRate"
                  value={values.taxRate}
                  onChange={handleChange}
                  error={errors.taxRate}
                />
              </div>
            </div>

            <Textarea
              label="Description"
              placeholder="Enter Description"
              radius={8}
              className="mt-2 text_primary w-100"
              name="description"
              value={values.description}
              onChange={handleChange}
              error={errors.description}
            />

            <h5 className="text_primary mt-4">Product Offering</h5>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Minimum Amount"
                  placeholder="Enter Minimum Amount"
                  radius={8}
                  className="mt-2 text_primary"
                  name="minimumAmount"
                  value={values.minimumAmount}
                  onChange={handleChange}
                  error={errors.minimumAmount}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Maximum Amount"
                  placeholder="Enter Maximum Amount"
                  radius={8}
                  className="mt-2 text_primary"
                  name="maximumAmount"
                  value={values.maximumAmount}
                  onChange={handleChange}
                  error={errors.maximumAmount}
                />
              </div>
            </div>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Interest Rate"
                  placeholder="Enter Interest Rate"
                  radius={8}
                  className="mt-2 text_primary"
                  name="interestRate"
                  value={values.interestRate}
                  onChange={handleChange}
                  error={errors.interestRate}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Product Range Code"
                  placeholder="Enter Product Range Code"
                  radius={8}
                  className="mt-2 text_primary"
                  name="productRangeCode"
                  value={values.productRangeCode}
                  onChange={handleChange}
                  error={errors.productRangeCode}
                />
              </div>
            </div>

            <div className="w-100 d-flex align-items-center gap-3">
              <div className="w-50">
                <TextInput
                  label="Tenor Start"
                  placeholder="Enter Tenor Start"
                  radius={8}
                  className="mt-2 text_primary"
                  name="tenorStart"
                  value={values.tenorStart}
                  onChange={handleChange}
                  error={errors.tenorStart}
                />
              </div>
              <div className="w-50">
                <TextInput
                  label="Tenor End"
                  placeholder="Enter Tenor End"
                  radius={8}
                  className="mt-2 text_primary"
                  name="tenorEnd"
                  value={values.tenorEnd}
                  onChange={handleChange}
                  error={errors.tenorEnd}
                />
              </div>
            </div>

            <Textarea
              label="Tenor Description"
              placeholder="Enter Tenor Description"
              radius={8}
              className="mt-2 text_primary "
              name="tenorDescription"
              value={values.tenorDescription}
              onChange={handleChange}
              error={errors.tenorDescription}
            />
          </div>
        </div>

        <div
          className="py-3 d-flex align-items-center justify-content-end"
          style={{
            width: "82.8%",
            borderBottom: "1px solid #c6c7c9",
            borderTop: "1px solid #c6c7c9",
            position: "fixed",
            bottom: "0",
            right: "0",
            background: "#fff",
          }}
        >
          <Button
            className="primary_btn px-5"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Save
          </Button>
        </div>
      </form>
    </NewDashboardLayout>
  );
}
