import "./empty-state.scss";
import EngineeringIcon from '@mui/icons-material/Engineering';

const EmptyState = () => {
    return (
        <div className='big-card'>
            <EngineeringIcon className='font-150 color-secondary font-40 mb-4 text-center m-auto d-flex'/>
            <div className='text-center font-16 fw-600 color-blue mb-3'>Sorry, This page is currently under construction.</div>
        </div>
    );
}

export default EmptyState