/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./view-nibss-transaction.scss";
import "../../users/view-user/view-user.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import TransactionService from "../../../../services/api/transaction/transaction";
import Moment from "moment";
import NumberFormat from "react-number-format";
import CustomLoader from "../../../../components/loader/loader";

const ViewNIBBSTransaction = () => {
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();
  const { id } = useParams();
  const { searchNibbsTransaction } = TransactionService();

  const handleClick = (e) => {
    navigate("/main/nibss-transactions");
  };

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          id: parseInt(id),
        };
        const data = await searchNibbsTransaction(payload);
        const userTransaction =
          data?.data?.nibssTransactionResponseData?.nibssTransaction;
        if (userTransaction) {
          setTransactionDetails(userTransaction[0]);
        }
      } catch (err) {
        return err;
      }
      setLoading(false);
    })();
  }, []);

  return (
    <NewDashboardLayout>
      {loading ? (
        <div className="load">
          <CustomLoader />
        </div>
      ) : (
        <div>
          <button
            onClick={handleClick}
            className="mb-4 border-0 bg-grey pointer"
          >
            <KeyboardBackspaceIcon /> Back
          </button>
          <div className="color-secondary fw-500 font-22 mb-3">
            NIBBS Transaction Details
          </div>

          <section className="big-card mb-5">
            <div className="row mt-4">
              <div className="mb-5 color-secondary font-18 fw-500">
                Transaction Information
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Transaction ID
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.transactionId ? (
                      <span>{transactionDetails?.transactionId}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Reference Code
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.referenceCode ? (
                      <span>{transactionDetails?.referenceCode}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Session ID
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.sessionId ? (
                      <span>{transactionDetails?.sessionId}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Action Type
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.actionType ? (
                      <span>{transactionDetails?.actionType}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Amount Before
                  </div>
                  {transactionDetails?.amountBefore ? (
                    <NumberFormat
                      className="color-sec-fade fw-500 font-16"
                      value={transactionDetails?.amountBefore}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Amount After
                  </div>
                  {transactionDetails?.amountAfter ? (
                    <NumberFormat
                      className="color-sec-fade fw-500 font-16"
                      value={transactionDetails?.amountAfter}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Channel
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.channel ? (
                      <span>{transactionDetails?.channel}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Unit Transacted
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.unitTransacted ? (
                      <span>{transactionDetails?.unitTransacted}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Date
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {Moment(transactionDetails?.createdAt).format("LLLL")}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    STATUS
                  </div>
                  {transactionDetails?.status === "Success" ? (
                    <div className="round-10 color-green bg-green w-1 text-center font-12 p-2">
                      SUCCESS
                    </div>
                  ) : transactionDetails?.status === "DISBURSED" ? (
                    <div className="round-10 color-green bg-green w-1 text-center font-12 p-2">
                      DISBURSED
                    </div>
                  ) : transactionDetails?.status === "PAID" ? (
                    <div className="round-10 color-green bg-green w-1 text-center font-12 p-2">
                      PAID
                    </div>
                  ) : transactionDetails?.status === "Pending" ? (
                    <div className="round-10 color-yellow bg-yellow w-1 text-center font-12 p-2">
                      PENDING
                    </div>
                  ) : transactionDetails?.status === null ? (
                    <div className="round-10 color-red bg-red w-1 text-center font-12 p-2">
                      null
                    </div>
                  ) : (
                    <div className="round-10 color-red bg-red w-1 text-center font-12 p-2">
                      {transactionDetails?.status}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="big-card mb-5">
            <div className="row mt-4">
              <div className="mb-5 color-secondary font-18 fw-500">
                Transactions Source Details
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Source Account Name
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.sourceAccountName ? (
                      <span>{transactionDetails?.sourceAccountName}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Source Account Number
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.sourceAccountNumber ? (
                      <span>{transactionDetails?.sourceAccountNumber}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Source BVN
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.sourceBankVerificationNumber ? (
                      <span>
                        {transactionDetails?.sourceBankVerificationNumber}
                      </span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Source Institution Code
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.sourceInstitutionCode ? (
                      <span>{transactionDetails?.sourceInstitutionCode}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Source Institution Name
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.sourceInstitutionName ? (
                      <span>{transactionDetails?.sourceInstitutionName}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Source KYC Level
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.sourceKycLevel ? (
                      <span>{transactionDetails?.sourceKycLevel}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="big-card mb-5">
            <div className="row mt-4">
              <div className="mb-5 color-secondary font-18 fw-500">
                Transactions Destination Details
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Destination Account Name
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.destinationAccountName ? (
                      <span>{transactionDetails?.destinationAccountName}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Destination Account Number
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.destinationAccountNumber ? (
                      <span>
                        {transactionDetails?.destinationAccountNumber}
                      </span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Destination BVN
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.transactionDetails
                      ?.destinationBankVerificationNumber ? (
                      <span>
                        {
                          transactionDetails?.transactionDetails
                            ?.destinationBankVerificationNumber
                        }
                      </span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Destination Institution Code
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.destinationInstitutionCode ? (
                      <span>
                        {transactionDetails?.destinationInstitutionCode}
                      </span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Destination Institution Name
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.destinationInstitutionName ? (
                      <span>
                        {transactionDetails?.destinationInstitutionName}
                      </span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-5">
                  <div className="color-secondary font-12 fw-400 mb-2">
                    Destination KYC Level
                  </div>
                  <div className="color-sec-fade fw-500 font-16">
                    {transactionDetails?.destinationKycLevel ? (
                      <span>{transactionDetails?.destinationKycLevel}</span>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </NewDashboardLayout>
  );
};

export default ViewNIBBSTransaction;
