import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useFormik } from "formik";
import { object, string } from "yup";

export default function DocumentModal({ opened, close, data, verifyDocs }) {
  const { type, fileDownLoadUrl, comment } = data;
  const [
    isRejectionModalOpen,
    { open: openRejectionModal, close: closeRejectionModal },
  ] = useDisclosure(false);

  const validationSchema = object().shape({
    reasonForRejection: string()
      .required("You must attach a reason before rejecting!!!")
      .min(3, "Reason must have above 3 characters")
      .max(255, "Reason must not exceed 255 characters"),
  });

  const { values, errors, touched, isSubmitting, handleSubmit, handleChange } =
    useFormik({
      initialValues: {
        reasonForRejection: "",
      },
      validationSchema,
      onSubmit: async (
        { reasonForRejection },
        { resetForm, setSubmitting }
      ) => {
        await verifyDocs("rejected", reasonForRejection);
        resetForm();
        setSubmitting(false);
      },
    });
  return (
    <>
      <Modal opened={opened} onClose={close} centered size="lg" radius="md">
        <div className="w-100 d-flex align-items-center gap-3">
          <Button className="w-100" onClick={() => verifyDocs("Approved", "")}>
            Approve
          </Button>
          <Button className="w-100" onClick={openRejectionModal}>
            Reject
          </Button>
        </div>

        <div className="w-100 d-flex flex-column gap-1 mt-2">
          <h4 className="text-capitalize">{type}</h4>
          <img
            src={fileDownLoadUrl}
            height="400"
            alt={fileDownLoadUrl}
            style={{ objectFit: "contain" }}
          />
        </div>

        {comment && (
          <div className="w-100 d-flex flex-column mt-2">
            <h5>Reason For Rejection</h5>
            <p className="m-0 text-danger">{comment}</p>
          </div>
        )}
      </Modal>

      <Modal
        opened={isRejectionModalOpen}
        onClose={closeRejectionModal}
        withCloseButton={false}
        centered
        size="lg"
        radius="md"
      >
        <div>
          <img
            src="/closeBtn.png"
            alt="close button"
            role="button"
            onClick={closeRejectionModal}
          />
        </div>

        <div className="mt-3">
          <h3 style={{ fontWeight: "700", fontSize: "20px" }}>
            Reject Document
          </h3>

          <p className="text-muted" style={{ fontSize: "14px" }}>
            Move this document to rejected by clicking on reject
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <textarea
            name="reasonForRejection"
            cols="30"
            rows="10"
            placeholder="Enter reason for rejection"
            className="w-100 p-2 rounded-3"
            style={{
              resize: "none",
              border: "1px solid #D0D5DD",
              outline: "0px",
            }}
            value={values.reasonForRejection}
            onChange={handleChange}
          ></textarea>
          {errors.reasonForRejection && touched.reasonForRejection && (
            <div className="color-error font-12">
              {errors.reasonForRejection}
            </div>
          )}

          <div className="d-flex align-items-center justify-content-between mt-2">
            <Button
              onClick={closeRejectionModal}
              type="button"
              style={{
                background: "white",
                border: "1px solid #D4D4D8",
                color: "#52525B",
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              style={{ background: "#F32013", border: "0", color: "white" }}
              disabled={isSubmitting}
            >
              Yes, Reject
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
