import { Formik, Form, useFormik } from "formik";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { string, object, ref } from "yup";
import resetImage from "../../../../src/assets/images/reset-password.jpg";
import logo from "../../../../src/assets/images/logo.png";
import AuthService from "../../../services/api/auth/auth";
import "../login/login.scss";
import CustomLoader from "../../../components/loader/loader";
import { PasswordInput } from "@mantine/core";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const { resetPassLoader, resetPasswordStaffAPI } = AuthService();
  const emailAddress = searchParams.get("emailAddress");

  const { handleChange, handleSubmit, values, errors, isSubmitting, isValid } =
    useFormik({
      initialValues: {
        Password: "",
        ConfirmPassword: "",
      },
      validationSchema: object().shape({
        Password: string()
          .required("Password is required")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
          ),
        ConfirmPassword: string()
          .required("Password is required")
          .oneOf([ref("Password"), null], "Passwords must match"),
      }),
      onSubmit: async (
        { ConfirmPassword, Password },
        { resetForm, setSubmitting }
      ) => {
        const payload = {
          emailAddress,
          newPassword: Password,
          confirmPassword: ConfirmPassword,
        };

        await resetPasswordStaffAPI(payload);
        resetForm();
        setSubmitting(false);
      },
    });

  return (
    <section className="pt-5 overflow-x">
      {resetPassLoader ? (
        <CustomLoader />
      ) : (
        <>
          <div className="pdl-5">
            <img className="w-200" src={logo} alt="creditville-logo" />
          </div>
          <div className="row mt-5">
            <div className="col-md-6 pdl-5">
              <img className="w-100" src={resetImage} alt="login" />
            </div>
            <div className="col-md-6 px-10">
              <div>
                <div className="font-30 fw-700 color-secondary ">
                  Reset Password
                </div>
                <div className="color-text font-20 fw-400 mt-3 mb-5">
                  Enter your new password to reset it.
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <PasswordInput
                    label={
                      <h6 className="form-label font-16 fw-500 color-text">
                        New Password
                      </h6>
                    }
                    name="Password"
                    placeholder="********"
                    value={values.Password}
                    onChange={handleChange}
                    error={errors.Password}
                    size="lg"
                  />
                </div>
                <div className="mb-3">
                  <PasswordInput
                    label={
                      <h6 className="form-label font-16 fw-500 color-text">
                        Confirm New Password
                      </h6>
                    }
                    name="ConfirmPassword"
                    placeholder="********"
                    value={values.ConfirmPassword}
                    onChange={handleChange}
                    error={errors.ConfirmPassword}
                    size="lg"
                  />
                </div>
                <button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="border-0 w-100 font-16 mt-4 round-4 fw-500 color-white p-3 btn-login"
                >
                  Reset
                </button>
              </form>

              <div className="d-flex justify-content-center mt-4">
                <div className="fw-500 font-12 color-secondary pointer link">
                  <Link className="color-secondary text-decoration-none" to="/">
                    BACK TO LOGIN
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default ResetPassword;
