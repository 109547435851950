import React from "react";
import TabLabel from "../TabLabel";
import sidebarTabStyle from "../../../utils/SidebarTabStyle";
import SideMenuList from "../SideMenuList";
import { useAppContext } from "../../../context/app";

export default function Finance() {
  const { activeAccordion, setActiveAccordion } = useAppContext();
  const handleClick = () => {
    if (activeAccordion === "finance") {
      setActiveAccordion("");
    } else {
      setActiveAccordion("finance");
    }
  };
  return (
    <>
      <hr className="horizontal_line" />
      <TabLabel
        title="Finance"
        handleClick={handleClick}
        isActive={activeAccordion === "finance"}
      />
      <div style={sidebarTabStyle("20rem", activeAccordion, "finance")}>
        <SideMenuList
          title="Bulk Transaction"
          name="Bulk Upload"
          iconLink="/finance.svg"
          setActiveAccordion={setActiveAccordion}
          active="finance"
        />
        <SideMenuList
          title="Transaction"
          name="Transaction"
          iconLink="/finance.svg"
          setActiveAccordion={setActiveAccordion}
          active="finance"
        />
        <SideMenuList
          title="Nibss Transactions"
          name="Nibss Transactions"
          iconLink="/finance.svg"
          setActiveAccordion={setActiveAccordion}
          active="finance"
        />
        <SideMenuList
          title="Settlement Report"
          name="Settlement Report"
          iconLink="/finance.svg"
          setActiveAccordion={setActiveAccordion}
          active="finance"
        />
      </div>
    </>
  );
}
