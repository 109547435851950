import "./transaction-index.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { Table, Radio } from "antd";
import { useState } from "react";

const TransactionsIndex = () => {
  const [active, setActive] = useState("All");

  const referralStatusColor = (status) => {
    if (
      status.toLowerCase() === "withdrawn" ||
      status.toLowerCase() === "matured"
    ) {
      return ["#dcfce7", "#16a34a"];
    } else if (status.toLowerCase() === "running") {
      return ["#fef3c7", "#ca8a04"];
    } else {
      return [];
    }
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "FullName",
      key: "FullName",
      render: (data) => (
        <div className="d-flex align-items-center gap-2">
          <img src={data[0]} alt="" />
          <div className="d-flex flex-column ">
            <p className="m-0 referralName">{data[1]}</p>
            <p className="m-0">{data[2]}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Used Referral Code",
      dataIndex: "UsedReferralCode",
      key: "UsedReferralCode",
      render: (text) => <p className="m-0 text-black">{text}</p>,
    },
    {
      title: "Referral Status",
      dataIndex: "referralStatus",
      key: "referralStatus",
      render: (text) => (
        <p
          className="m-0 py-1 px-3"
          style={{
            backgroundColor: `${referralStatusColor(text)[0]}`,
            color: `${referralStatusColor(text)[1]}`,
            borderRadius: "16px",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Amount Funded",
      key: "AmountFunded",
      dataIndex: "AmountFunded",
      render: (text) => <p className="m-0 text-black">{text}</p>,
    },
    {
      title: "Sign Up Date",
      key: "SignUpDate",
      dataIndex: "SignUpDate",
      render: (text) => <p className="m-0 text-black">{text}</p>,
    },
    {
      title: "Date Funded",
      key: "DateFunded",
      dataIndex: "DateFunded",
      render: (text) => <p className="m-0 text-black">{text}</p>,
    },
  ];

  const data = [
    {
      key: "1",
      FullName: ["/AvatarFrame.jpg", "Meshach Ebiwari", "WhatsApp"],
      UsedReferralCode: "MEY389WGUGDBJZVN",
      referralStatus: "running",
      AmountFunded: "N1000",
      SignUpDate: "Dec. 02, 2022, 08:31:57 am",
      DateFunded: "Dec. 02, 2022, 08:31:57 am",
    },
    {
      key: "2",
      FullName: ["/AvatarFrame.jpg", "Meshach Ebiwari", "WhatsApp"],
      UsedReferralCode: "MEY389WGUGDBJZVN",
      referralStatus: "running",
      AmountFunded: "N1000",
      SignUpDate: "Dec. 02, 2022, 08:31:57 am",
      DateFunded: "Dec. 02, 2022, 08:31:57 am",
    },
    {
      key: "3",
      FullName: ["/AvatarFrame.jpg", "Meshach Ebiwari", "WhatsApp"],
      UsedReferralCode: "MEY389WGUGDBJZVN",
      referralStatus: "withdrawn",
      AmountFunded: "N1000",
      SignUpDate: "Dec. 02, 2022, 08:31:57 am",
      DateFunded: "Dec. 02, 2022, 08:31:57 am",
    },
    {
      key: "4",
      FullName: ["/AvatarFrame.jpg", "Meshach Ebiwari", "WhatsApp"],
      UsedReferralCode: "MEY389WGUGDBJZVN",
      referralStatus: "matured",
      AmountFunded: "N1000",
      SignUpDate: "Dec. 02, 2022, 08:31:57 am",
      DateFunded: "Dec. 02, 2022, 08:31:57 am",
    },
  ];

  return (
    <NewDashboardLayout>
      <div className="transaction_container">
        <h2>Referral</h2>

        <div className="transaction_details_wrapper">
          <div className="transaction_details_card">
            <p>total revenue generated</p>
            <div className="transaction_bottom">
              <h4>N2,000,426,000</h4>
              <div className="transaction_growth">
                <p style={{ color: "#22C55E" }}>+ 36%</p>
                <AiOutlineArrowUp
                  style={{ color: "#22C55E" }}
                  className="growth_icon"
                />
              </div>
            </div>
          </div>

          <div className="transaction_details_card">
            <p>new customers</p>
            <div className="transaction_bottom">
              <h4>12,426</h4>
              <div className="transaction_growth">
                <p style={{ color: "red" }}>- 14%</p>
                <AiOutlineArrowDown
                  style={{ color: "red" }}
                  className="growth_icon"
                />
              </div>
            </div>
          </div>

          <div className="transaction_details_card">
            <p>total number of referrals made</p>
            <div className="transaction_bottom">
              <h4>12,426</h4>
              <div className="transaction_growth">
                <p style={{ color: "#22C55E" }}>+ 36%</p>
                <AiOutlineArrowUp
                  style={{ color: "#22C55E" }}
                  className="growth_icon"
                />
              </div>
            </div>
          </div>

          <div className="transaction_details_card">
            <p>redeemed referrals</p>
            <div className="transaction_bottom">
              <h4>300</h4>
              <div className="transaction_growth">
                <p style={{ color: "#22C55E" }}>+ 36%</p>
                <AiOutlineArrowUp
                  style={{ color: "#22C55E" }}
                  className="growth_icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="transaction_table">
          <div className="w-100 d-flex align-items-center justify-content-between mt-3">
            <h2>User</h2>
            <Radio.Group
              value={active}
              onChange={(e) => setActive(e.target.value)}
            >
              <Radio.Button
                className={
                  active.toLowerCase() === "all"
                    ? "active_btn btn_left"
                    : "btn_left"
                }
                defaultColor="red"
                value="All"
              >
                All
              </Radio.Button>

              <Radio.Button
                className={
                  active.toLowerCase() === "running" ? "active_btn" : ""
                }
                value="Running"
              >
                Running
              </Radio.Button>

              <Radio.Button
                className={
                  active.toLowerCase() === "redeemed"
                    ? "active_btn btn_right"
                    : "btn_right"
                }
                value="Redeemed"
              >
                Redeemed
              </Radio.Button>
            </Radio.Group>
          </div>
          <Table
            onRow={() => console.log(`clicked row 1`)}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
    </NewDashboardLayout>
  );
};

export default TransactionsIndex;
