import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "./App";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MantineProvider } from "@mantine/core";
import { AppContextProvider } from "./context/app";
// import * as Sentry from "@sentry/react";
const queryClient = new QueryClient();

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   tracesSampleRate: 1.0,
//   replaysOnErrorSampleRate: 1.0
// });

const app = document.getElementById("root");
const root = createRoot(app);
// createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
