import React from "react";

export default function ElectricModalRow({
  left,
  right,
  borderTop,
  borderBottom,
}) {
  return (
    <div
      style={{
        borderTop: borderTop ? "1px solid #072C50" : "",
        borderBottom: borderBottom ? "1px solid #072C50" : "",
      }}
      className="w-100 d-flex align-items-center justify-content-between py-2 my-2"
    >
      <h4
        className="text-bold m-0"
        style={{ fontSize: "15px", fontWeight: "bolder", color: "#072C50" }}
      >
        {left}
      </h4>
      <p className="m-0" style={{ fontSize: "13px", color: "#28374C" }}>
        {right}
      </p>
    </div>
  );
}
