import "./login.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import loginImage from "../../../../src/assets/images/loginVector2.svg";
import logo from "../../../../src/assets/images/logo.png";
import AuthService from "../../../services/api/auth/auth";
import PasswordShowHide from "../../../components/PasswordShowHide/PasswordShowHide";
import CustomLoader from "../../../components/loader/loader";

const Login = () => {
  const { loginAPI, loginLoader } = AuthService();

  return (
    <Formik
      initialValues={{
        emailAddress: "",
        password: "",
      }}
      validationSchema={yup.object({
        emailAddress: yup
          .string()
          .required("Email field is requied")
          .email("Invalid email address")
          .matches(
            /.+@(creditville)\.ng$/,
            "Please email must end with creditville.ng"
          ),
        password: yup
          .string()
          .required("Password is required")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
          ),
      })}
      onSubmit={async (values) => {
        await loginAPI(JSON.stringify(values));
      }}
    >
      {(props) => (
        <section className="pt-5 overflow-x">
          {loginLoader ? (
            <CustomLoader />
          ) : (
            <Form>
              <div className="pdl-5">
                <img className="w-200" src={logo} alt="creditville-logo" />
              </div>
              <div className="row mt-5">
                <div className="col-md-6 pdl-5">
                  <img className="w-100" src={loginImage} alt="login" />
                </div>
                <div className="col-md-6 px-10">
                  <div>
                    <div className="font-30 fw-700 color-secondary mb-5">
                      Sign in
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="emailAddress"
                      className="form-label font-16 fw-500 color-text"
                    >
                      Email
                    </label>
                    <Field
                      type="email"
                      className="form-control"
                      name="emailAddress"
                      placeholder="test@creditville.ng"
                    ></Field>
                    <div className="form-error mt-1">
                      <ErrorMessage
                        className="form-error"
                        name="emailAddress"
                      ></ErrorMessage>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="password"
                      className="form-label font-16 fw-500 color-text"
                    >
                      Password
                    </label>
                    <Field
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="********"
                      component={PasswordShowHide}
                    />
                    <div className="form-error mt-1">
                      <ErrorMessage name="password"></ErrorMessage>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={props.isValid === false}
                      className="border-0 w-100 font-16 mt-4 round-4 fw-500 color-white p-3 btn-login"
                    >
                      Login
                    </button>
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <div className="fw-500 font-12 color-secondary pointer link">
                      <Link
                        className="color-secondary text-decoration-none"
                        to="/forgot-password"
                      >
                        FORGOT PASSWORD ?
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </section>
      )}
    </Formik>
  );
};

export default Login;
