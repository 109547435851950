import "./loans-index.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import EmptyState from "../../../../components/empty-state/empty-state";
// import { Menu, Tag } from 'antd';
// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as yup from 'yup';

// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import CachedIcon from '@mui/icons-material/Cached';
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const LoansIndex = () => {
  // const [clickedRowRecord,] = useState({});
  // const navigate = useNavigate();

  // const viewUser = () => {
  //     navigate(`/main/users/${clickedRowRecord?.id}`);
  // }

  // const menu = (
  //     <Menu>
  //         <Menu.Item key="0">
  //             <button onClick={viewUser} className="font-14 border-0 color-sec-fade back-btn  pointer">
  //                 <VisibilityOutlinedIcon className="avatar-sec" />
  //                 View Loan
  //             </button>
  //         </Menu.Item>
  //     </Menu>
  // );

  // const columns = [
  //     {
  //         title: 'APPROVED DATE',
  //         dataIndex: 'approvedDate',
  //     },
  //     {
  //         title: 'BORROWER',
  //         dataIndex: 'borrower',
  //         defaultSortOrder: 'descend',
  //         sorter: (a, b) => a.borrower.length - b.borrower.length,
  //     },
  //     {
  //         title: 'PRINCIPAL (₦)',
  //         dataIndex: 'principal'
  //     },
  //     {
  //         title: 'TOTAL DUE (₦)',
  //         dataIndex: 'totalDue'
  //     },
  //     {
  //         title: 'NEXT PAYMENT',
  //         dataIndex: 'nextPayment',
  //     },
  //     {
  //         title: 'STATUS',
  //         dataIndex: 'status',
  //         render: (tag) => (
  //             <span>
  //                 <Tag color={tag === 'settled' ? '#213f7d' : '#7f0020'} key={tag}>
  //                     {tag.toUpperCase()}
  //                 </Tag>
  //             </span>
  //         ),
  //     },
  //     {
  //         title: '',
  //         render: (props) =>
  //             <ArrowForwardIosIcon className="color-sec-fade pointer font-16" />

  //     },
  // ];

  // const data = [
  //     {
  //         id: '1',
  //         approvedDate: 'Apr 22, 2022',
  //         borrower: 'John Brown',
  //         principal: '10,000.00',
  //         totalDue: '23,000.00',
  //         nextPayment: 'Apr 22, 2022',
  //         status: 'settled'
  //     },
  //     {
  //         id: '2',
  //         approvedDate: 'Apr 22, 2022',
  //         borrower: 'John Brown',
  //         principal: '10,000.00',
  //         totalDue: '23,000.00',
  //         nextPayment: 'Apr 22, 2022',
  //         status: 'unsettled'
  //     },
  //     {
  //         id: '3',
  //         approvedDate: 'Apr 22, 2022',
  //         borrower: 'John Brown',
  //         principal: '10,000.00',
  //         totalDue: '23,000.00',
  //         nextPayment: 'Apr 22, 2022',
  //         status: 'settled'
  //     },
  //     {
  //         id: '4',
  //         approvedDate: 'Apr 22, 2022',
  //         borrower: 'John Brown',
  //         principal: '10,000.00',
  //         totalDue: '23,000.00',
  //         nextPayment: 'Apr 22, 2022',
  //         status: 'settled'
  //     },
  //     {
  //         id: '5',
  //         approvedDate: 'Apr 22, 2022',
  //         borrower: 'John Brown',
  //         principal: '10,000.00',
  //         totalDue: '23,000.00',
  //         nextPayment: 'Apr 22, 2022',
  //         status: 'unsettled'
  //     },
  //     {
  //         id: '6',
  //         approvedDate: 'Apr 22, 2022',
  //         borrower: 'John Brown',
  //         principal: '10,000.00',
  //         totalDue: '23,000.00',
  //         nextPayment: 'Apr 22, 2022',
  //         status: 'unsettled'
  //     },
  //     {
  //         id: '7',
  //         approvedDate: 'Apr 22, 2022',
  //         borrower: 'John Brown',
  //         principal: '10,000.00',
  //         totalDue: '23,000.00',
  //         nextPayment: 'Apr 22, 2022',
  //         status: 'unsettled'
  //     },
  // ];

  return (
    <NewDashboardLayout>
      <div className="color-secondary fw-600 font-25 mb-1">Loans</div>

      <div className="pt-5">
        <EmptyState />
      </div>
      {/* <div>
                <div className="color-secondary fw-600 font-25 mb-1">Loans</div>
                <section className="infor-cards row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="font-12 fw-400 mb-2 color-sec-fade fw-500">ALL LOANS</div>
                            <div className="color-secondary mb-3 fw-600 font-25">₦36,000</div>
                            <div className="row mb-2 justify-content-between">
                                <div className="round-8 col-md-6 col-sm-12 p-2 bg-lpurple w-1">
                                    <div className="font-12 fw-400 color-lpurple mb-1">COUNT</div>
                                    <div className="font-12 fw-500 color-lpurple">25</div>
                                </div>
                                <div className="round-8 bg-green col-sm-12 p-2 col-md-6 w-1">
                                    <div className="font-12 fw-400 color-green mb-1">DISBURSED</div>
                                    <div className="font-12 fw-500 color-green">₦35,000</div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="round-8 col-md-6 col-sm-12 p-2 bg-yellow w-1">
                                    <div className="font-12 fw-400 color-yellow mb-1">PAID</div>
                                    <div className="font-12 fw-500 color-yellow">₦35,000</div>
                                </div>
                                <div className="round-8 bg-red p-2 col-sm-12 col-md-6 w-1">
                                    <div className="font-12 fw-400 color-red mb-1">FEE</div>
                                    <div className="font-12 fw-500 color-red">₦3,500</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="font-12 fw-400 mb-2 color-sec-fade fw-500">RUNNING LOANS</div>
                            <div className="color-secondary mb-3 fw-600 font-25">₦236,000</div>
                            <div className="row mb-2 justify-content-between">
                                <div className="round-8 col-md-6 col-sm-12 p-2 bg-lpurple w-1">
                                    <div className="font-12 fw-400 color-lpurple mb-1">COUNT</div>
                                    <div className="font-12 fw-500 color-lpurple">25</div>
                                </div>
                                <div className="round-8 bg-green col-sm-12 p-2 col-md-6 w-1">
                                    <div className="font-12 fw-400 color-green mb-1">INTEREST</div>
                                    <div className="font-12 fw-500 color-green">₦10,000</div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="round-8 col-md-6 col-sm-12 p-2 bg-yellow w-1">
                                    <div className="font-12 fw-400 color-yellow mb-1">PAID</div>
                                    <div className="font-12 fw-500 color-yellow">₦35,000</div>
                                </div>
                                <div className="round-8 bg-red p-2 col-sm-12 col-md-6 w-1">
                                    <div className="font-12 fw-400 color-red mb-1">FEE</div>
                                    <div className="font-12 fw-500 color-red">₦3,500</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="font-12 fw-400 mb-2 color-sec-fade fw-500">PAID LOANS</div>
                            <div className="color-secondary mb-3 fw-600 font-25">₦6,000</div>
                            <div className="row mb-2 justify-content-between">
                                <div className="round-8 col-md-6 col-sm-12 p-2 bg-lpurple w-1">
                                    <div className="font-12 fw-400 color-lpurple mb-1">COUNT</div>
                                    <div className="font-12 fw-500 color-lpurple">25</div>
                                </div>
                                <div className="round-8 bg-green col-sm-12 p-2 col-md-6 w-1">
                                    <div className="font-12 fw-400 color-green mb-1">INTEREST</div>
                                    <div className="font-12 fw-500 color-green">₦35,000</div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="round-8 col-md-6 col-sm-12 p-2 bg-yellow w-1">
                                    <div className="font-12 fw-400 color-yellow mb-1">PAID</div>
                                    <div className="font-12 fw-500 color-yellow">₦35,000</div>
                                </div>
                                <div className="round-8 bg-red p-2 col-sm-12 col-md-6 w-1">
                                    <div className="font-12 fw-400 color-red mb-1">FEE</div>
                                    <div className="font-12 fw-500 color-red">₦3,500</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mt-4">
                            <div className="font-12 fw-400 mb-2 color-sec-fade fw-500">PAST DUE LOANS</div>
                            <div className="color-secondary mb-3 fw-600 font-25">₦36,000</div>
                            <div className="row mb-2 justify-content-between">
                                <div className="round-8 col-md-6 col-sm-12 p-2 bg-lpurple w-1">
                                    <div className="font-12 fw-400 color-lpurple mb-1">COUNT</div>
                                    <div className="font-12 fw-500 color-lpurple">25</div>
                                </div>
                                <div className="round-8 bg-green col-sm-12 p-2 col-md-6 w-1">
                                    <div className="font-12 fw-400 color-green mb-1">DISBURSED</div>
                                    <div className="font-12 fw-500 color-green">₦35,000</div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="round-8 col-md-6 col-sm-12 p-2 bg-yellow w-1">
                                    <div className="font-12 fw-400 color-yellow mb-1">PAID</div>
                                    <div className="font-12 fw-500 color-yellow">₦35,000</div>
                                </div>
                                <div className="round-8 bg-red p-2 col-sm-12 col-md-6 w-1">
                                    <div className="font-12 fw-400 color-red mb-1">PENALTY</div>
                                    <div className="font-12 fw-500 color-red">₦3,500</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                {/* search filter section */}
      {/* <section className="card p-3 mt-40">
                <Formik
                    initialValues={{
                        approvedDate: '',
                        due: '',
                        borrower: '',
                        principal: '',
                        status: ''
                    }}
                    validationSchema={yup.object({
                        approvedDate: yup.date(),
                        due: yup.number(''),
                        principal: yup.number(''),
                        borrower: yup.string(''),
                        status: yup.string(),
                    })}
                    onSubmit={values => {
                        console.log(JSON.stringify(values));
                    }}>

                    {props => (
                        <section className="">
                            <Form>
                                <div className="">
                                    <div className="font-14 fw-600 mb-2 color-sec-fade fw-500">FILTER BY</div>
                                    <div className="adjust-grid justify-content-between align-items-center">
                                        <div className="mb-3">
                                            <label htmlFor="approvedDate" className="form-label font-12 fw-500 color-text">Approved Date</label>
                                            <Field type="date" className="form-control-new form-control" name="approvedDate" placeholder=""></Field>
                                            <div className="form-error mt-1">
                                                <ErrorMessage className="font-12" name="approvedDate"></ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="borrower" className="form-label font-12 fw-500 color-text">Borrower</label>
                                            <Field type="text" className="form-control-new form-control" name="borrower" placeholder="" ></Field>
                                            <div className="form-error mt-1">
                                                <ErrorMessage className="font-12" name="borrower"></ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="due" className="form-label font-12 fw-500 color-text">Total Due</label>
                                            <Field type="text" className="form-control-new form-control" name="due" placeholder="" ></Field>
                                            <div className="form-error mt-1">
                                                <ErrorMessage className="font-12" name="due"></ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="principal" className="form-label font-12 fw-500 color-text">Principal</label>
                                            <Field type="text" className="form-control-new form-control" name="principal" placeholder="" ></Field>
                                            <div className="form-error mt-1">
                                                <ErrorMessage className="font-12" name="principal"></ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="status" className="form-label font-12 fw-500 color-text">Status</label>
                                            <Field type="text" className="form-control-new form-control" name="status" placeholder="" ></Field>
                                            <div className="form-error mt-1">
                                                <ErrorMessage className="font-12" name="status"></ErrorMessage>
                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" disabled={props.isValid === false} className="border-0 m-auto w-100 font-12 round-4 fw-500 color-white p-3 btn-login">Filter</button>
                                        </div>

                                        <div>
                                            <button className="border-0 m-auto bg-white">
                                                <CachedIcon className="color-sec-fade pointer font-40" />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </Form>

                        </section>
                    )}

                </Formik>

            </section> */}
      {/* search and filter section end */}

      {/* <section className="table-section round-6 bg-white p-4 mt-40">
                <Table className="pointer" columns={columns} dataSource={data} pagination={{ pageSize: 20 }} rowKey="id" />
            </section>
        </div>  */}
    </NewDashboardLayout>
  );
};

export default LoansIndex;
