export default function sidebarTabStyle(maxHeight, activeAccordion, active) {
  return {
    overflow: "hidden",
    marginTop: "5px",
    transition: "max-height 800ms ease-in",
    maxHeight: activeAccordion === active ? maxHeight : "0px",
    display: "flex",
    flexDirection: "column",
    gap: "3px",
  };
}
