import "./NibbsTransactionModal.css";
import Logo from "../../../assets/images/logo.png";

export default function NibbsTransactionModal({ transaction }) {
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    year: "numeric",
  };
  const {
    actionType,
    sourceAccountName,
    sourceAccountNumber,
    sourceBankVerificationNumber,
    sourceKycLevel,
    destinationAccountName,
    destinationAccountNumber,
    destinationBankVerificationNumber,
    destinationKycLevel,
    channel,
    sourceInstitutionName,
    sourceInstitutionCode,
    destinationInstitutionName,
    destinationInstitutionCode,
    reasonCode,
    referenceCode,
    narration,
    unitTransacted,
    amountBefore,
    amountAfter,
    lockedAmount,
    transactionId,
    sessionId,
    createdAt,
    status,
  } = transaction;
  const initials = sourceAccountName
    .split(" ")
    .slice(0, 2)
    .map((word) => word[0])
    .join("");

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    new Date(createdAt)
  );
  return (
    <div className="nibbs_modal_container">
      <img src={Logo} alt="logo" />
      <h5>Transaction Reciept</h5>
      <span>
        <h2 className="initials">{initials}</h2>
        <div>
          <p>{sourceAccountName}</p>
          <p>{sourceAccountNumber}</p>
          <p>{formattedDate}</p>
        </div>
      </span>
      <div className="nibbs_group">
        <h6>Action Type</h6>
        <p>{actionType}</p>
      </div>
      <div className="nibbs_group">
        <h6>Source Account Name</h6>
        <p>{sourceAccountName}</p>
      </div>
      <div className="nibbs_group">
        <h6>Source Account Number</h6>
        <p>{sourceAccountNumber}</p>
      </div>
      <div className="nibbs_group">
        <h6>Source Bank Verification Number</h6>
        <p>{sourceBankVerificationNumber}</p>
      </div>
      <div className="nibbs_group">
        <h6>Source Kyc Level</h6>
        <p>{sourceKycLevel}</p>
      </div>
      <div className="nibbs_group">
        <h6>Destination Account Name</h6>
        <p>{destinationAccountName}</p>
      </div>
      <div className="nibbs_group">
        <h6>Destination Account Number</h6>
        <p>{destinationAccountNumber}</p>
      </div>
      <div className="nibbs_group">
        <h6>Destination Bank Verification Number</h6>
        <p>{destinationBankVerificationNumber}</p>
      </div>
      <div className="nibbs_group">
        <h6>Destination Kyc Level</h6>
        <p>{destinationKycLevel}</p>
      </div>
      <div className="nibbs_group">
        <h6>Channel</h6>
        <p>{channel}</p>
      </div>
      <div className="nibbs_group">
        <h6>Source Institution Name</h6>
        <p>{sourceInstitutionName}</p>
      </div>
      <div className="nibbs_group">
        <h6>Source Institution Code</h6>
        <p>{sourceInstitutionCode}</p>
      </div>
      <div className="nibbs_group">
        <h6>Destination Institution Name</h6>
        <p>{destinationInstitutionName}</p>
      </div>
      <div className="nibbs_group">
        <h6>Destination Institution Code</h6>
        <p>{destinationInstitutionCode}</p>
      </div>
      <div className="nibbs_group">
        <h6>Reason Code</h6>
        <p>{reasonCode}</p>
      </div>
      <div className="nibbs_group">
        <h6>Reference Code</h6>
        <p>{referenceCode}</p>
      </div>
      <div className="nibbs_group">
        <h6>Narration</h6>
        <p>{narration}</p>
      </div>
      <div className="nibbs_group">
        <h6>Reason Code</h6>
        <p>{reasonCode}</p>
      </div>
      <div className="nibbs_group">
        <h6>Unit Transacted</h6>
        <p>{unitTransacted}</p>
      </div>
      <div className="nibbs_group">
        <h6>Amount Before</h6>
        <p>{amountBefore}</p>
      </div>
      <div className="nibbs_group">
        <h6>Amount After</h6>
        <p>{amountAfter}</p>
      </div>
      <div className="nibbs_group">
        <h6>Locked Amount</h6>
        <p>{lockedAmount}</p>
      </div>
      <div className="nibbs_group">
        <h6>Transaction ID</h6>
        <p>{transactionId}</p>
      </div>
      <div className="nibbs_group">
        <h6>Session ID</h6>
        <p>{sessionId}</p>
      </div>
      <div className="nibbs_group">
        <h6>Status</h6>
        <p>{status}</p>
      </div>
    </div>
  );
}
