/* eslint-disable react-hooks/exhaustive-deps */
import "./view-user.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import CustomerService from "../../../../services/api/customer/customer";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Tabs, Table, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import Moment from "moment";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SavingsService from "../../../../services/api/savings/savings";
import CustomLoader from "../../../../components/loader/loader";
import cardLogo from "../../../../assets/images/visa-white.svg";
import TransactionService from "../../../../services/api/transaction/transaction";
import { notification } from "antd";
import EnterBvnModal from "../../../../components/modals/EnterBvnModal/EnterBvnModal";
import { useDisclosure } from "@mantine/hooks";
import DocumentModal from "../../../../components/modals/documentModal/DocumentModal";
import ElectricityTokenModal from "../../../../components/transaction/ElectricityTokenModal/ElectricityTokenModal";
import { Button, Menu, Textarea } from "@mantine/core";
import { BsThreeDotsVertical } from "react-icons/bs";
import MessageModal from "../../../../components/modals/MessageModal/MessageModal";
import DeActivateUser from "../../../../components/modals/DeactivateUser/DeActivateUser";
import ActivateUser from "../../../../components/modals/ActivateUser/ActiveUser";
import BlackListUser from "../../../../components/modals/BlackListUser/BlackListUser";
import ViewBvnModal from "../../../../components/modals/ViewBvnModal/ViewBvnModal";
import ReasonForFreezeUnfreeze from "../../../../components/modals/ReasonForFreezeUnfreeze/ReasonForFreezeUnfreeze";
// import FreezeEnterPin from "../../../../components/modals/FreezeEnterPin/FreezeEnterPin";

// const desc = ["terrible", "bad", "normal", "good", "wonderful"];
const { TabPane } = Tabs;

function callback(key) {
  return key;
}

const ViewUser = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const {
    getCustomerById,
    setCustomerStatus,
    sendCustomerMessage,
    messageLoader,
    verifyDocument,
    verificationStatus,
    isBvnDetailsLoading,
    getBvnDetails,
    updateBvnDetails,
    getElectricityToken,
  } = CustomerService();

  const { getSavingsById, getUserCards, freezeUnfreezeAccount } =
    SavingsService();

  const { generateReciept } = TransactionService();
  // const [documentModal, setDocumentModal] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [modalDocsData, setModalDocsData] = useState([]);
  const [modalTransactionData, setModalTransactionData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [messageModal, setMessageModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [blackListModal, setBlackListModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userDataDetails, setUserDataDetails] = useState(null);
  const [userSavingsData, setUserSavingsData] = useState([]);
  const [userDocumentData, setUserDocumentData] = useState([]);
  const [userTransactionData, setUserTransactionData] = useState([]);
  const [useCardDatils, setUserCardDetails] = useState([]);

  const [bvnDetailsModal, setBvnDetailsModal] = useState(false);
  const [userBvnData, setUserBvnData] = useState(null);
  const [userNinData, setUserNinData] = useState(null);
  const [userBvn, setUserBvn] = useState("");
  const [userNin, setUserNin] = useState("");
  const [sortValue, setSortValue] = useState("all");
  const [enterBvnModal, setEnterBvnModal] = useState(false);
  const [enterNinModal, setEnterNinModal] = useState(false);
  const [electricityData, setElectricityData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNinModalOpen, setIsNinModalOpen] = useState(false);
  const [isfreezing, setIsfreezing] = useState(false);
  const [reason, setReason] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = (e) => {
    navigate(-1);
  };

  const viewSavingsDetails = (res) => {
    navigate(`/main/savings/${res}`);
  };

  const maskData = (text) => {
    if (!text) return "-";
    return `${text.slice(0, 4)}****${text.slice(-3)}`;
  };

  const viewTransactionDetails = async (res) => {
    setModalTransactionData(res);
    setTransactionModal(true);
    const data = await generateReciept(res.ID);
    if (data?.data?.receipt) {
      setTransactionData(data?.data?.receipt);
    } else {
      setTransactionData([]);
    }
  };

  const showDocsModal = (record) => {
    setModalDocsData(record);
  };

  const setStatusChange = (status, message) => {
    const payload = {
      customerid: id,
      statusCode: status,
      statusMessage: message,
    };
    setCustomerStatus(payload, id);
  };

  const sendMessage = (description, title) => {
    const payload = {
      emailAddress: userDataDetails.email,
      name: userDataDetails.firstName,
      message: description,
      title: title,
    };
    sendCustomerMessage(payload);
  };

  const formatedDate = (data) => {
    let formattedTime = Moment(data).format("DD/MM/YYYY HH:mm");
    return formattedTime;
  };

  const verifyDocs = async (status, reason) => {
    const data = await verifyDocument(modalDocsData?.id, status, reason);
    if (data?.data?.resp?.code === "cv00") {
      navigate(0);
    }
  };

  const openNotification = (message, messageHeader, color) => {
    notification.open({
      message: <h5 className="h5">{messageHeader}</h5>,
      description: message,
      style: { color },
    });
  };

  const [
    isElectricityModalOpen,
    { open: openElectricityModal, close: closeElectricityModal },
  ] = useDisclosure(false);

  const getElectricity = async (token) => {
    const data = await getElectricityToken(token);
    setElectricityData(data);
    openElectricityModal();
  };

  const handleGetBvnDetails = async () => {
    if (
      Number(userDataDetails?.bvn) === undefined ||
      isNaN(Number(userDataDetails?.bvn)) ||
      !Number(userDataDetails?.bvn)
    ) {
      setEnterBvnModal(true);
      return;
    }

    const data = await getBvnDetails(Number(userDataDetails?.bvn));
    if (data?.status?.toLowerCase() === "success") {
      setUserBvnData(data.data);
      setBvnDetailsModal(true);
    } else {
      setEnterBvnModal(true);
      return;
    }
  };

  // const handleGetNinDetails = async () => {
  //   if (!userDataDetails?.nin) {
  //     setEnterNinModal(true);
  //     return;
  //   }

  //   const data = await getBvnDetails(Number(userDataDetails?.bvn));
  //   if (data?.status?.toLowerCase() === "success") {
  //     setUserBvnData(data.data);
  //     setBvnDetailsModal(true);
  //   } else {
  //     setEnterBvnModal(true);
  //     return;
  //   }
  // };

  const handleUpdateBvnDetails = async () => {
    const data = await updateBvnDetails(userBvn);
    if (data?.status.toLowerCase() === "success") {
      openNotification("Update successful", "Success Message", "green");
      setUserBvn("");
      setBvnDetailsModal(false);
    } else {
      openNotification(data?.message, "Error Notification", "red");
      setUserBvn("");
      return;
    }
  };

  const transactionStatuses = Array.from(
    new Set(
      userTransactionData?.account?.transactions.map((transaction) =>
        transaction.transactionStatus.toLowerCase()
      )
    )
  );

  const accountTypes = Array.from(
    new Set(
      userTransactionData?.account?.transactions.map((transaction) =>
        transaction.accountType.toLowerCase()
      )
    )
  );

  const debitTransactionType = Array.from(
    new Set(
      userTransactionData?.account?.transactions.map((transaction) =>
        transaction.amount.toString().startsWith("-") ? "debit" : "credit"
      )
    )
  );

  const filterTransactions = [
    ...transactionStatuses,
    ...accountTypes,
    ...debitTransactionType,
  ];

  const sortTransactions = () => {
    const transactions = userTransactionData?.account?.transactions;
    if (sortValue?.toLowerCase() === "all") {
      return transactions;
    } else if (sortValue?.toLowerCase() === "debit") {
      return transactions.filter((transaction) =>
        transaction.amount.toString().startsWith("-")
      );
    } else if (sortValue?.toLowerCase() === "credit") {
      return transactions.filter(
        (transaction) => !transaction.amount.toString().startsWith("-")
      );
    } else if (sortValue?.toLowerCase() === "APPROVED".toLowerCase()) {
      return transactions.filter(
        (transaction) =>
          transaction.transactionStatus.toLowerCase() === "approved"
      );
    } else if (sortValue?.toLowerCase() === "DECLINED".toLowerCase()) {
      return transactions?.filter(
        (transaction) =>
          transaction.transactionStatus.toLowerCase() === "declined"
      );
    } else if (sortValue?.toLowerCase() === "deposit") {
      return transactions.filter(
        (transaction) => transaction.accountType.toLowerCase() === "deposit"
      );
    }
  };

  const freezeAccount = async () => {
    const payload = {
      nuban: userDataDetails?.nuban,
      status: "FREEZE",
      reason,
    };

    await freezeUnfreezeAccount(payload);
  };

  const unFreezeAccount = async () => {
    const payload = {
      nuban: userDataDetails?.nuban,
      status: "UNFREEZE",
      reason,
    };

    await freezeUnfreezeAccount(payload);
  };

  const [
    isDocumentModalOpen,
    { open: openDocumentModal, close: closeDocumentModal },
  ] = useDisclosure(false);

  // Added by Williams End

  useEffect(() => {
    (async () => {
      try {
        const data = await getCustomerById(id);
        const userDetails = data?.data?.customerDetails;

        const userSavings = data?.data?.customerDetails?.deposit;
        const userDocuments = data?.data?.customerDetails?.documents;
        let userTransaction = data?.data?.customerDetails?.walletAccount;
        let transactionID = userTransaction;
        if (transactionID) {
          const savingsData = await getSavingsById(transactionID);
          let savingsTransaction = savingsData?.data?.savingsResponseDto;
          setUserTransactionData(savingsTransaction);
        }

        setUserDocumentData(userDocuments);
        setUserSavingsData(userSavings);

        const userData = await getUserCards(id);
        if (userData?.data?.cards) {
          setUserCardDetails(userData?.data?.cards);
        }
        setUserDataDetails(userDetails);
      } catch (err) {
        return err;
      }
      setLoading(false);
    })();
  }, []);

  const docsColumns = [
    {
      title: "",
      render: () => <DocumentScannerIcon className="color-sec-fade pointer" />,
    },
    {
      title: "DATE",
      dataIndex: "date",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (text, record) => (
        <div>
          <span
            className={
              record.status.toLowerCase() === "approve" ||
              record.status.toLowerCase() === "approved"
                ? "saving-status border-0 font-12 py-2 px-3"
                : record.status.toLowerCase() === "reject" ||
                  record.status.toLowerCase() === "rejected"
                ? "failed-status border-0 font-12 py-2 px-3"
                : "pending-status border-0 font-12 py-2 px-3"
            }
          >
            {record.status.toUpperCase()}
          </span>
        </div>
      ),
    },
    {
      title: "",
      render: (props) => (
        <ArrowForwardIosIcon className="color-sec-fade pointer font-16" />
      ),
    },
  ];

  const savingsColumns = [
    {
      title: "PRODUCT ID",
      dataIndex: "productID",
    },
    {
      title: "PRODUCT NAME",
      dataIndex: "productName",
    },
    {
      title: "START DATE",
      dataIndex: "startDate",
      render: (text, record) => (
        <span>{record.startDate ? <div>{record.startDate}</div> : "-"}</span>
      ),
    },
    {
      title: "END DATE",
      dataIndex: "endDate",
      render: (text, record) => (
        <span>
          {record.endDate ? <div>{record.endDate}</div> : <div> - </div>}
        </span>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (text, record) => (
        <div>
          <span
            className={
              record.status === "ACTIVE"
                ? "saving-status color-secondary bg-lblue border-0 font-12 py-2 px-3"
                : record.status === "CLOSED"
                ? "saving-status bg-lred color-red border-0 font-12 py-2 px-3"
                : "saving-status bg-lorange color-yellow border-0 font-12 py-2 px-3"
            }
          >
            {record.status.toUpperCase()}
          </span>
        </div>
      ),
    },
    {
      title: "",
      render: (props) => (
        <ArrowForwardIosIcon className="color-sec-fade pointer font-16" />
      ),
    },
  ];

  const logsColumns = [
    {
      title: "EVENT NAME",
      dataIndex: "eventName",
    },
    {
      title: "TIME",
      dataIndex: "time",
    },
    {
      title: "",
      render: (props) => (
        <ArrowForwardIosIcon className="color-sec-fade pointer font-16" />
      ),
    },
  ];

  const transactionColumns = [
    {
      title: "",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      render: (res, record) => {
        return (
          <FiberManualRecordIcon
            fontSize="small"
            color={res === "APPROVED" ? "success" : "warning"}
          />
        );
      },
    },
    {
      title: "TRANSACTION ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "DATE",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text, record) => <span>{formatedDate(record.createdOn)}</span>,
    },
    {
      title: "AMOUNT (₦)",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <NumberFormat
          className={
            record.amount.toString().startsWith("-")
              ? "color-error fw-700"
              : "color-green fw-700"
          }
          value={record.amount}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: "BALANCE (₦)",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => (
        <NumberFormat
          className="fw-600 color-secondary"
          value={record.balance}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: "NARRATION",
      dataIndex: "notes",
      key: "notes",
      render: (text, record) => (
        <span>
          {record?.transactionType === "DEPOSIT_INTEREST_POSTING"
            ? "Daily Interest Received"
            : record?.transactionType === "DEPOSIT_INTEREST_TAX"
            ? "WHT - Daily Interest"
            : record.notes === null
            ? "Charges"
            : record.notes}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (props) => (
        <ArrowForwardIosIcon className="color-sec-fade pointer font-16" />
      ),
    },
  ];

  const logData = [
    {
      id: "1",
      eventName: "Add pin while onboarding",
      time: "Apr 22, 2022, 01:05 pm",
    },
    {
      id: "2",
      eventName: "Log out",
      time: "Apr 22, 2022, 01:05 pm",
    },
    {
      id: "3",
      eventName: "Add pin while onboarding",
      time: "Apr 22, 2022, 01:05 pm",
    },
    {
      id: "4",
      eventName: "Login",
      time: "Apr 22, 2022, 01:05 pm",
    },
    {
      id: "5",
      eventName: "Add pin while onboarding",
      time: "Apr 22, 2022, 01:05 pm",
    },
  ];

  return (
    <NewDashboardLayout>
      {loading || verificationStatus || messageLoader ? (
        <div className="load">
          <CustomLoader />
        </div>
      ) : (
        <div>
          <button
            onClick={handleClick}
            className="mb-4 border-0 bg-grey pointer"
          >
            <KeyboardBackspaceIcon /> Back
          </button>

          <section className="mb-5">
            <div className="row">
              <div className="col-md-6 col-sm-12 fw-500 font-20 color-secondary">
                {userDataDetails?.firstName} {userDataDetails?.lastName}
              </div>

              <div
                className="col-md-6 col-sm-12"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Menu
                  shadow="md"
                  width={240}
                  transitionProps={{ duration: 150, transition: "pop" }}
                >
                  <Menu.Target>
                    <Button
                      rightSection={<BsThreeDotsVertical />}
                      color="#213f7d"
                    >
                      More Options
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      leftSection={
                        <img
                          src="\message-icon.svg"
                          alt="message-icon"
                          width={14}
                        />
                      }
                      onClick={() => setMessageModal(true)}
                    >
                      Send Message
                    </Menu.Item>

                    {userDataDetails?.status !== "A" && (
                      <Menu.Item
                        leftSection={
                          <img
                            src="\check.svg"
                            alt="deactivate-icon"
                            width={14}
                          />
                        }
                        onClick={() => setActivateModal(true)}
                      >
                        Activate User
                      </Menu.Item>
                    )}

                    <Menu.Divider />

                    {userDataDetails?.status === "A" && (
                      <Menu.Item
                        leftSection={
                          <img
                            src="\blacklist-icon.svg"
                            alt="blacklist-icon"
                            width={14}
                          />
                        }
                        onClick={() => setBlackListModal(true)}
                      >
                        Blacklist User
                      </Menu.Item>
                    )}

                    {userDataDetails?.status === "A" && (
                      <Menu.Item
                        leftSection={
                          <img
                            src="\deactivate-icon.svg"
                            alt="deactivate-icon"
                            width={14}
                          />
                        }
                        onClick={() => setDeactivateModal(true)}
                      >
                        Deactivate User
                      </Menu.Item>
                    )}

                    <Menu.Item
                      leftSection={
                        <img
                          src="\view-bvn-icon.svg"
                          alt="view-bvn-icon"
                          width={14}
                        />
                      }
                      onClick={handleGetBvnDetails}
                    >
                      {isBvnDetailsLoading
                        ? "Fetching data..."
                        : "View BVN Details"}
                    </Menu.Item>

                    <Menu.Divider />

                    {!userDataDetails?.isAccountFrozen && (
                      <Menu.Item
                        leftSection={
                          <img
                            src="\unfreeze-icon.svg"
                            alt="unfreeze-icon"
                            width={14}
                          />
                        }
                        onClick={() => {
                          setIsfreezing(true);
                          showModal();
                        }}
                      >
                        Freeze Account
                      </Menu.Item>
                    )}

                    {userDataDetails?.isAccountFrozen && (
                      <Menu.Item
                        leftSection={
                          <img
                            src="\lien-icon.svg"
                            alt="lien-icon"
                            width={14}
                          />
                        }
                        onClick={() => {
                          setIsfreezing(false);
                          showModal();
                        }}
                      >
                        Unfreeze Account
                      </Menu.Item>
                    )}
                  </Menu.Dropdown>
                </Menu>

                <MessageModal
                  messageModal={messageModal}
                  sendMessage={sendMessage}
                  setMessageModal={setMessageModal}
                />
                <DeActivateUser
                  deactivateModal={deactivateModal}
                  setDeactivateModal={setDeactivateModal}
                  setStatusChange={setStatusChange}
                />
                <ActivateUser
                  activateModal={activateModal}
                  setActivateModal={setActivateModal}
                  setStatusChange={setStatusChange}
                />
                <BlackListUser
                  blackListModal={blackListModal}
                  setBlackListModal={setBlackListModal}
                  setStatusChange={setStatusChange}
                />
                <ViewBvnModal
                  bvnDetailsModal={bvnDetailsModal}
                  handleUpdateBvnDetails={handleUpdateBvnDetails}
                  isBvnUpdateDetailsLoading={isBvnDetailsLoading}
                  setBvnDetailsModal={setBvnDetailsModal}
                  setUserBvn={setUserBvn}
                  userBvn={userBvn}
                  userBvnData={userBvnData}
                />

                <Modal
                  centered
                  footer={null}
                  destroyOnClose={true}
                  visible={enterBvnModal}
                  onOk={() => setEnterBvnModal(false)}
                  onCancel={() => setEnterBvnModal(false)}
                  width={480}
                >
                  <EnterBvnModal
                    setUserBvnData={setUserBvnData}
                    setBvnDetailsModal={setBvnDetailsModal}
                    openNotification={openNotification}
                  />
                </Modal>
              </div>
            </div>
          </section>

          <section className="big-card mb-5">
            <div className="row">
              <div className="col-md-3 text-center">
                {userDataDetails?.photoLocation ? (
                  <img
                    className="profile-pics"
                    src={userDataDetails?.photoLocation}
                    alt="profile-pics"
                  />
                ) : (
                  <Avatar
                    size={{
                      xs: 24,
                      sm: 32,
                      md: 80,
                      lg: 100,
                      xl: 130,
                      xxl: 180,
                    }}
                    icon={<UserOutlined />}
                  />
                )}
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  {userDataDetails?.firstName ? (
                    <div className="color-secondary font-20 fw-500 mb-2">
                      {userDataDetails?.firstName} {userDataDetails?.lastName}
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                  {/* <div className="color-sec-fade fw-400 font-16">LSQ 508268</div> */}
                </div>

                <div>
                  <div className="color-secondary font-16 fw-500 mb-2">
                    User’s Tier
                  </div>
                  <div className="font-34 color-secondary fw-600 text-left">
                    {" "}
                    <span
                      className={
                        userDataDetails?.tier === "Tier 1"
                          ? "tier bg-yellow"
                          : userDataDetails?.tier === "Tier 2"
                          ? "tier bg-green"
                          : "tier"
                      }
                    >
                      {userDataDetails?.tier}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-16 fw-500 mb-2">
                    Balance
                  </div>
                  {/* <div className="color-sec-fade fw-400 font-16">₦{userTransactionData?.account?.account?.balance}</div> */}
                  <div className="color-sec-fade fw-400 font-16">
                    {userTransactionData?.account?.account?.balance ? (
                      <NumberFormat
                        className="color-sec-fade fw-400 font-16"
                        value={userTransactionData?.account?.account?.balance}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                </div>

                <div>
                  <div className="color-secondary font-16 fw-500 mb-2">
                    Wallet ID :{" "}
                    <span className="color-sec-fade fw-400 font-16">
                      {userTransactionData?.id}
                    </span>
                  </div>
                  <div className="color-secondary font-16 fw-500 mb-2">
                    Nuban ID :{" "}
                    <span className="color-sec-fade fw-400 font-16">
                      {userDataDetails?.nuban}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-4">
                  <div className="color-secondary font-16 fw-500 mb-2">
                    Previous Loans
                  </div>
                  <div className="color-sec-fade fw-400 font-16">0</div>
                </div>

                <div>
                  <div className="color-secondary font-16 fw-500 mb-2">
                    Loan Requests
                  </div>
                  <div className="color-sec-fade fw-400 font-16">0</div>
                </div>
              </div>
            </div>
          </section>

          <section className="big-card">
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="General Information" key="1">
                <div className="row mt-2">
                  <div className="mb-4 color-secondary font-18 fw-600">
                    Personal
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Full Name
                      </div>
                      <div className="color-sec-fade fw-500 font-16">
                        {userDataDetails?.firstName ? (
                          <span>
                            {userDataDetails?.firstName}{" "}
                            {userDataDetails?.lastName}
                          </span>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Gender
                      </div>
                      {userDataDetails?.gender ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.gender}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Street
                      </div>
                      {userDataDetails?.address[0]?.address1 ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.address[0]?.address1}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Address Verification
                      </div>
                      <div className="color-sec-fade fw-500 font-16">-</div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Phone Number
                      </div>
                      <div className="color-sec-fade fw-500 font-16">
                        {userDataDetails?.phoneNumber
                          ? userDataDetails?.phoneNumber
                          : "-"}
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Marital Status
                      </div>
                      {userDataDetails?.maritalStatus ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.maritalStatus}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Nearest Landmark
                      </div>
                      <div className="color-sec-fade fw-500 font-16">
                        {userDataDetails?.nearestLandMark
                          ? userDataDetails?.nearestLandMark
                          : "-"}
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Selfie BVN Check
                      </div>
                      <div className="color-sec-fade fw-500 font-16">-</div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Email Address
                      </div>
                      <div className="color-sec-fade fw-500 font-16">
                        {userDataDetails?.email ? userDataDetails?.email : "-"}
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        NIN
                      </div>
                      <div className="nin_wrapper">
                        {userDataDetails?.nin
                          ? maskData(userDataDetails?.nin)
                          : "-"}
                        <div
                          className={`${
                            userDataDetails?.NinFlag?.toLowerCase() ===
                              "verify" ||
                            userDataDetails?.NinFlag?.toLowerCase() ===
                              "verified" ||
                            userDataDetails?.NinFlag?.toLowerCase() ===
                              "approved"
                              ? "approve"
                              : "decline"
                          }`}
                        >
                          <div className="ring" />
                          {userDataDetails?.NinFlag}
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        City
                      </div>
                      {userDataDetails?.address[0]?.city ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.address[0]?.city}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Selfie ID Check
                      </div>
                      <div className="color-sec-fade fw-500 font-16">-</div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        BVN
                      </div>
                      <div className="nin_wrapper">
                        {maskData(userDataDetails?.bvn)
                          ? maskData(userDataDetails?.bvn)
                          : "-"}
                        <div
                          className={`${
                            userDataDetails?.bvn ? "approve" : "decline"
                          }`}
                        >
                          <div className="ring" />
                          {userDataDetails?.bvn ? "Verified" : "Unverified"}
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Type of Residence
                      </div>
                      {userDataDetails?.address[0]?.addressType ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.address[0]?.addressType}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        State
                      </div>
                      {userDataDetails?.address[0]?.state ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.address[0]?.state}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Local Govt. Area
                      </div>
                      <div className="color-sec-fade fw-500 font-16">
                        {userDataDetails?.lga ? userDataDetails?.lga : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row mt-4">
                  <div className="mb-5 color-secondary font-18 fw-500">
                    Education and Employment
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Level of Education
                      </div>
                      {userDataDetails?.levelOfEducation ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.levelOfEducation}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Office Email
                      </div>
                      {userDataDetails?.employments[0]?.officeEmail ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.employments[0]?.officeEmail}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Employment Status
                      </div>
                      {userDataDetails?.employments[0]?.status ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.employments[0]?.status}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Monthly Income
                      </div>
                      {userDataDetails?.employments[0]?.monthlyIncome ? (
                        <NumberFormat
                          className="color-secondary font-14 fw-400 mb-2"
                          value={userDataDetails?.employments[0]?.monthlyIncome}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Sector of Employment
                      </div>
                      {userDataDetails?.employments[0]?.sector ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.employments[0]?.sector}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2"></div>
                      <div className="color-sec-fade fw-500 font-16"></div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Duration of Employment
                      </div>
                      {userDataDetails?.employments[0]?.duration ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.employments[0]?.duration}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2"></div>
                      <div className="color-sec-fade fw-500 font-16"></div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row mt-4">
                  <div className="mb-5 color-secondary font-18 fw-500">
                    Next of Kin
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Full Name
                      </div>
                      {userDataDetails?.nextOfKin?.name ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.nextOfKin?.name}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Relationship
                      </div>
                      {userDataDetails?.nextOfKin?.relationShip ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.nextOfKin?.relationShip}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Phone Number
                      </div>
                      {userDataDetails?.nextOfKin?.phoneNumber ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.nextOfKin?.phoneNumber}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        City
                      </div>
                      {userDataDetails?.nextOfKin?.city ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.nextOfKin?.city}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Gender
                      </div>
                      {userDataDetails?.nextOfKin?.gender ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.nextOfKin?.gender}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Local Govt. Area
                      </div>
                      {userDataDetails?.nextOfKin?.lga ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.nextOfKin?.lga}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Address
                      </div>
                      {userDataDetails?.nextOfKin?.address ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.nextOfKin?.address}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Nearest Landmark
                      </div>
                      {userDataDetails?.nextOfKin?.nearestLandMark ? (
                        <div className="color-sec-fade fw-500 font-16">
                          {userDataDetails?.nextOfKin?.nearestLandMark}
                        </div>
                      ) : (
                        <div className="color-sec-fade fw-500 font-16">-</div>
                      )}
                    </div>
                  </div>
                </div>
              </TabPane>

              {/* Transactions tab */}
              <TabPane tab="Transactions" key="2">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <div className="mt-3 mb-4 fw-500 color-secondary font-16">
                    User's Transaction
                  </div>

                  <div className="d-flex align-items-center gap-2">
                    <span className="text-muted">Filter by</span>
                    <div className="form_group_col">
                      <label></label>
                      <select
                        name="filterTransaction"
                        value={sortValue}
                        onChange={(e) => setSortValue(e.target.value)}
                      >
                        {["all", ...filterTransactions].map((el) => (
                          <option key={el}>{el}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <Table
                  className="pointer"
                  columns={transactionColumns}
                  dataSource={sortTransactions()}
                  pagination={{ pageSize: 20 }}
                  rowKey="id"
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        if (record.token) {
                          getElectricity(record.token);
                        } else if (record.notes !== null) {
                          viewTransactionDetails(record);
                        }
                      },
                    };
                  }}
                />

                <Modal
                  centered
                  footer={null}
                  destroyOnClose={true}
                  visible={transactionModal}
                  onOk={() => setTransactionModal(false)}
                  onCancel={() => setTransactionModal(false)}
                  width={800}
                  height={600}
                >
                  <section className="">
                    <div className="color-secondary fw-500 font-18 text-center py-3">
                      Transaction ID : {modalTransactionData.ID}
                    </div>

                    <section className="big-card">
                      <div className="row mt-2">
                        <div className="mb-5 color-secondary font-18 fw-500">
                          Transaction Information
                        </div>

                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              TRANSACTION ID
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {modalTransactionData?.ID}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              REF ID
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {transactionData?.referenceNumber || "-"}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              DATE
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {formatedDate(transactionData?.transactionDate) ||
                                "-"}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              AMOUNT
                            </div>
                            <NumberFormat
                              className="color-sec-fade fw-500 font-16"
                              value={modalTransactionData?.amount || "-"}
                              prefix={"₦"}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              BALANCE
                            </div>
                            <NumberFormat
                              className="color-sec-fade fw-500 font-16"
                              value={modalTransactionData?.balance || "-"}
                              prefix={"₦"}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              TYPE
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {transactionData?.TransactionType || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              CHANNEL
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {transactionData?.channel || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              DESTINATION BANK
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {transactionData?.BankName || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              DESTINATION ACCT NUMBER
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {transactionData?.BeneficiaryAccountNumber || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              DESTINATION ACCT NAME{" "}
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {transactionData?.BeneficiaryName || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              SOURCE ACCT NUMBER
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {transactionData?.SourceAccountNumber || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              SOURCE ACCT NAME{" "}
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {transactionData?.SourceName || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              STATUS
                            </div>
                            <div
                              className={
                                transactionData?.status === "Success"
                                  ? "color-green bg-green fw-700 round-10 w-1 text-center  font-12 p-2"
                                  : transactionData?.status === "DISBURSED"
                                  ? "color-green fw-700 round-10 w-1 text-center font-12 p-2 bg-green"
                                  : transactionData?.status === "PAID" || "Paid"
                                  ? "color-green w-1 text-center fw-700 round-10  font-12 p-2 bg-green"
                                  : transactionData?.status === null
                                  ? "color-grey fw-700 w-1 text-center round-10  font-12 p-2 bg-grey"
                                  : transactionData?.status === "Pending"
                                  ? "color-yellow bg-yellow round-10 w-1 fw-700 text-center font-12 p-2"
                                  : "text-center font-12 p-2"
                              }
                            >
                              {transactionData?.status || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-5">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              ENTRY
                            </div>
                            <div
                              className={
                                modalTransactionData?.debitCreditType === "CR"
                                  ? "color-green fw-700 round-10  font-12 p-2 bg-green w-1 text-center"
                                  : "color-red bg-red round-10  font-12 p-2 fw-700 w-1 text-center"
                              }
                            >
                              {modalTransactionData?.debitCreditType === "CR"
                                ? "CREDIT"
                                : "DEBIT"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="">
                            <div className="color-secondary font-12 fw-400 mb-2">
                              NARRATION
                            </div>
                            <div className="color-sec-fade fw-500 font-16">
                              {transactionData?.Narration || "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </section>
                </Modal>

                <ElectricityTokenModal
                  opened={isElectricityModalOpen}
                  close={closeElectricityModal}
                  electricityData={electricityData}
                />
              </TabPane>

              <TabPane tab="User Logs" key="3">
                <div className="row mt-4">
                  <div className="mb-5 color-secondary font-18 fw-500">
                    User's System Details
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Mobile OS
                      </div>
                      <div className="color-sec-fade fw-500 font-16">
                        {userDataDetails?.mobileOs ? (
                          <span>{userDataDetails?.mobileOs} </span>
                        ) : (
                          <span> - </span>
                        )}
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Last Login
                      </div>
                      <div className="color-sec-fade fw-500 font-16">-</div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Version ID
                      </div>
                      <div className="color-sec-fade fw-500 font-16">
                        {userDataDetails?.mobileVersionId ? (
                          <span>{userDataDetails?.mobileVersionId} </span>
                        ) : (
                          <span> - </span>
                        )}
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Last Login Location
                      </div>
                      <div className="color-sec-fade fw-500 font-16">-</div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Install ID
                      </div>
                      <div className="color-sec-fade fw-500 font-16">
                        {userDataDetails?.deviceId ? (
                          <span>{userDataDetails?.deviceId} </span>
                        ) : (
                          <span> - </span>
                        )}
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Number of Login
                      </div>
                      <div className="color-sec-fade fw-500 font-16">-</div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Last Login Date
                      </div>
                      <div className="color-sec-fade fw-500 font-16">-</div>
                    </div>

                    <div className="mb-4">
                      <div className="color-secondary font-14 fw-400 mb-2">
                        Device Name
                      </div>
                      <div className="color-sec-fade fw-500 font-16">
                        {userDataDetails?.deviceName ? (
                          <span>{userDataDetails?.deviceName} </span>
                        ) : (
                          <span> - </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="mt-4">
                  <div className="mt-3 mb-4 fw-500 color-secondary font-16">
                    User's Logs
                  </div>
                  <Table
                    columns={logsColumns}
                    dataSource={logData}
                    pagination={{ pageSize: 10 }}
                    rowKey="id"
                  />
                </div>
              </TabPane>

              <TabPane tab="Documents" key="4">
                <div className="mt-3 mb-4 fw-500 color-secondary font-16">
                  User's Document
                </div>
                <Table
                  className="pointer"
                  columns={docsColumns}
                  dataSource={userDocumentData}
                  pagination={{ pageSize: 20 }}
                  rowKey="id"
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        showDocsModal(record);
                        openDocumentModal();
                      },
                    };
                  }}
                />

                <DocumentModal
                  opened={isDocumentModalOpen}
                  close={closeDocumentModal}
                  data={modalDocsData}
                  verifyDocs={verifyDocs}
                />
              </TabPane>

              <TabPane tab="Bank Details" key="5">
                <div className="mt-3 mb-4 fw-500 color-secondary font-16">
                  Bank Details
                </div>
                <section className="row">
                  {useCardDatils.map((data, id) => (
                    <div key={id} className="col-lg-4 col-md-4 col-sm-6">
                      <div className="debit-card mb-4 px-3 py-4">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <div className="form-label font-16 fw-500 color-white text-left d-flex">
                            {data?.bankName}
                          </div>
                          {data?.image ? (
                            <img
                              className="logo-width"
                              src={data?.bankLogo}
                              alt="debit-card"
                            />
                          ) : (
                            <img
                              className="logo-width"
                              src={cardLogo}
                              alt="debit-card"
                            />
                          )}
                        </div>
                        <div className="font-16 fw-500 color-white text-left mb-4">
                          **** **** **** {data?.lastFourDigit}
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <div className="font-16 fw-500 color-white text-left mb-3">
                            {data?.accountName}
                          </div>
                          <div className="font-16 fw-500 color-white text-right mb-3">
                            {data?.expMonth}/{data?.expYear}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </section>
              </TabPane>

              <TabPane tab="Savings" key="6">
                <div className="mt-3 mb-4 fw-500 color-secondary font-16">
                  User's Savings
                </div>
                <Table
                  className="pointer"
                  columns={savingsColumns}
                  dataSource={userSavingsData}
                  pagination={{ pageSize: 20 }}
                  rowKey="id"
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        viewSavingsDetails(record?.ID);
                      },
                    };
                  }}
                />
              </TabPane>

              <TabPane tab="Loans" key="7">
                <div className="mt-3 mb-4 fw-500 color-secondary font-16">
                  User's Loan
                </div>
              </TabPane>
            </Tabs>
          </section>
        </div>
      )}

      <ReasonForFreezeUnfreeze
        freezeAccount={freezeAccount}
        handleCancel={handleCloseModal}
        handleOk={handleCloseModal}
        isFreezing={isfreezing}
        isModalOpen={isModalOpen}
        setValue={setReason}
        unFreezeAccount={unFreezeAccount}
        value={reason}
      />
    </NewDashboardLayout>
  );
};

export default ViewUser;
