/* eslint-disable react-hooks/exhaustive-deps */
import "./user-index.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import { Tag, Table } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import CachedIcon from "@mui/icons-material/Cached";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import customerService from "../../../../services/api/customer/customer";
// import CustomLoader from "../../../../components/loader/loader";
// import { Pagination } from "antd";
// import { PlaylistAddRounded } from "@mui/icons-material";

const UserIndex = () => {
  // const [clickedRowRecord, setClickedRowRecord] = useState({});
  // const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  // const [userList, setUserList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const navigate = useNavigate();

  const { searchCustomer } = customerService();

  // const onShowSizeChange = (page, pageSize) => {
  //   console.log(page, pageSize);
  // };

  // const refresh = async () => {
  //   setTableLoading(true);
  //   const payload = {
  //     pageNo: 0,
  //     pageSize: 20,
  //     sortBy: "id",
  //     sortDir: "ASC",
  //   };
  //   const data = await getAllCustomer(
  //     payload.pageNo,
  //     payload.pageSize,
  //     payload.sortBy,
  //     payload.sortDir
  //   );
  //   const tabledata = data?.data?.content;
  //   setUserList(data.data);
  //   setTableData(tabledata);
  //   setTableLoading(false);
  // };

  // const onShowChange = async (page, pageSize) => {
  //   setTableLoading(true);
  //   const payload = {
  //     pageNo: page - 1,
  //     pageSize: pageSize,
  //     sortBy: "id",
  //     sortDir: "ASC",
  //   };
  //   const data = await getAllCustomer(
  //     payload.pageNo,
  //     payload.pageSize,
  //     payload.sortBy,
  //     payload.sortDir
  //   );
  //   const tabledata = data?.data?.content;
  //   setTableData(tabledata);
  //   setTableLoading(false);
  // };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const payload = {
  //         pageNo: 0,
  //         pageSize: 20,
  //         sortBy: "id",
  //         sortDir: "ASC",
  //       };
  //       const data = await getAllCustomer(
  //         payload.pageNo,
  //         payload.pageSize,
  //         payload.sortBy,
  //         payload.sortDir
  //       );
  //       const tabledata = data?.data?.content;
  //       setUserList(data.data);
  //       setTableData(tabledata);
  //     } catch (err) {
  //       return err;
  //     }
  //     setLoading(false);
  //   })();
  // }, []);

  const viewUser = (res) => {
    navigate(`/main/customer/${res}`);
  };

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="0">
  //       <button onClick={viewUser} className="font-14 border-0 color-sec-fade back-btn  pointer">
  //         <VisibilityOutlinedIcon className="avatar-sec" />
  //         View User
  //       </button>
  //     </Menu.Item>
  //   </Menu>
  // );

  const columns = [
    {
      title: "USERNAME",
      dataIndex: "username",
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.firstName.length - b.firstName.length,
      render: (text, record) => (
        <span>
          {record.firstName ? (
            <div>
              {record.firstName} {record.lastName}
            </div>
          ) : (
            <div> - </div>
          )}
        </span>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.email.length - b.email.length,
      render: (text, record) => (
        <span>{record.email ? <div>{record.email}</div> : <div> - </div>}</span>
      ),
    },
    {
      title: "PHNONE NUMBER",
      dataIndex: "phoneNumber",
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.phoneNumber - b.phoneNumber,
    },
    {
      title: "DATE",
      dataIndex: "dateCreated",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.dateCreated - b.dateCreated,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (tag) => (
        <span>
          <Tag
            color={
              // tag === 'A' ? 'success' : 'error'

              tag === "A"
                ? "#213f7d"
                : tag === "B"
                ? "#7f0020"
                : tag === "I"
                ? "#e3ac07"
                : "#7f002033"
            }
            key={tag}
          >
            {tag.toUpperCase() === "A"
              ? "Active"
              : tag.toUpperCase() === "B"
              ? "Blacklist"
              : tag.toUpperCase() === "I"
              ? "Inactive"
              : "Locked"}
          </Tag>
        </span>
      ),
    },
    {
      title: "",
      render: (props) => (
        <ArrowForwardIosIcon className="color-sec-fade pointer font-16" />
      ),
      // <Dropdown overlay={menu} trigger={['click']}>
      //   <a className="ant-dropdown-link" onClick={(e) => {
      //     e.preventDefault();
      //     setClickedRowRecord(props)
      //     console.log(props)
      //   }}>
      //     <MoreVertIcon className="color-sec-fade pointer" />
      //   </a>
      // </Dropdown>
    },
  ];

  return (
    <NewDashboardLayout>
      <div>
        <div className="color-secondary fw-600 font-25 mb-1">Customers</div>
        <section className="infor-cards row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card mt-4">
              <PersonOutlineIcon className="round-50 color-red bg-red font-30 p-1" />
              <div className="mt-3 color-sec-fade fw-500 font-16">
                TOTAL USERS
              </div>
              <div className="color-secondary fw-600 font-25 mt-3">0</div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card mt-4">
              <AdminPanelSettingsOutlinedIcon className="round-50 color-green bg-green font-30 p-1" />
              <div className="mt-3 color-sec-fade fw-500 font-16">
                ACTIVE USERS
              </div>
              <div className="color-secondary fw-600 font-25 mt-3">0</div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card mt-4">
              <AccountBalanceWalletOutlinedIcon className="round-50 color-yellow bg-yellow font-30 p-1" />
              <div className="mt-3 color-sec-fade fw-500 font-16">LOANS</div>
              <div className="color-secondary fw-600 font-25 mt-3">0</div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card mt-4">
              <SavingsOutlinedIcon className="round-50 color-secondary bg-blue font-30 p-1" />
              <div className="mt-3 color-sec-fade fw-500 font-16">SAVINGS</div>
              <div className="color-secondary fw-600 font-25 mt-3">0</div>
            </div>
          </div>
        </section>

        {/* search filter section */}
        <section className="card p-3 mt-40">
          <Formik
            initialValues={{
              emailAddress: "",
              number: "",
              name: "",
              bvn: "",
              status: "",
              nuban: "",
            }}
            validationSchema={yup.object({
              emailAddress: yup.string().email(),
              number: yup.number(""),
              bvn: yup.number(""),
              name: yup.string(""),
              status: yup.string(),
              nuban: yup.string(),
            })}
            onSubmit={(values, { resetForm }) => {
              const payload = {
                pageNo: 0,
                pageSize: 20,
                sortBy: "id",
                sortDir: "ASC",
                bvn: values.bvn,
                name: values.name,
                phone: values.number,
                email: values.emailAddress,
                nuban: values.nuban,
              };
              const displaySearch = async () => {
                setTableLoading(true);
                const data = await searchCustomer(payload);

                const tabledata = data?.data?.content;
                setTableData(tabledata);
                setTableLoading(false);
              };
              displaySearch();
              resetForm();
            }}
          >
            {(props) => (
              <section className="">
                <Form>
                  <div className="">
                    <div className="font-14 fw-600 mb-2 color-sec-fade fw-500">
                      FILTER BY
                    </div>
                    <div className="adjust-grid justify-content-between align-items-center">
                      <div className="mb-3">
                        <label
                          htmlFor="emailAddress"
                          className="form-label font-12 fw-500 color-text"
                        >
                          Email
                        </label>
                        <Field
                          type="email"
                          className="form-control-new form-control"
                          name="emailAddress"
                          placeholder=""
                        ></Field>
                        <div className="form-error mt-1">
                          <ErrorMessage
                            className="font-12"
                            name="emailAddress"
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="name"
                          className="form-label font-12 fw-500 color-text"
                        >
                          FirstName or LastName
                        </label>
                        <Field
                          type="text"
                          className="form-control-new form-control"
                          name="name"
                          placeholder=""
                        ></Field>
                        <div className="form-error mt-1">
                          <ErrorMessage
                            className="font-12"
                            name="name"
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="number"
                          className="form-label font-12 fw-500 color-text"
                        >
                          Phone Number
                        </label>
                        <Field
                          type="text"
                          className="form-control-new form-control"
                          name="number"
                          placeholder=""
                        ></Field>
                        <div className="form-error mt-1">
                          <ErrorMessage
                            className="font-12"
                            name="number"
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="bvn"
                          className="form-label font-12 fw-500 color-text"
                        >
                          BVN
                        </label>
                        <Field
                          type="text"
                          className="form-control-new form-control"
                          name="bvn"
                          placeholder=""
                        ></Field>
                        <div className="form-error mt-1">
                          <ErrorMessage
                            className="font-12"
                            name="bvn"
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="nuban"
                          className="form-label font-12 fw-500 color-text"
                        >
                          Nuban
                        </label>
                        <Field
                          type="text"
                          className="form-control-new form-control"
                          name="nuban"
                          placeholder=""
                        ></Field>
                        <div className="form-error mt-1">
                          <ErrorMessage
                            className="font-12"
                            name="nuban"
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          disabled={props.isValid === false}
                          className="border-0 m-auto w-100 font-12 round-4 fw-500 color-white p-3 btn-login"
                        >
                          Search
                        </button>
                      </div>

                      <div>
                        <button className="border-0 m-auto bg-white">
                          <CachedIcon className="color-sec-fade pointer font-40" />
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </section>
            )}
          </Formik>
        </section>
        {/* search and filter section end */}

        <section className="table-section round-6 bg-white p-4 mt-40">
          <Table
            className="pointer"
            columns={columns}
            dataSource={tableData}
            pagination={false}
            loading={tableLoading}
            rowKey="id"
            onRow={(record, index) => {
              return {
                onClick: () => {
                  viewUser(record.id);
                },
              };
            }}
          />
        </section>
      </div>
    </NewDashboardLayout>
  );
};

export default UserIndex;
