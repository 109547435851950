import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/auth/login/login";
import ForgotPassword from "./pages/auth/forgot-password/forgot-password";
import ResetPassword from "./pages/auth/rest-password/rest-password";
import UserIndex from "./pages/main/users/user-index/user-index";
import ViewUser from "./pages/main/users/view-user/view-user";
import GuarantorIndex from "./pages/main/guarantors/guarantors-index/guarantor-index";
import Error from "./pages/error/error";
import Dashboard from "./pages/main/dashboard/dashboard";
import SavingsIndex from "./pages/main/savings/savings-index/savings-index";
import ViewSavings from "./pages/main/savings/view-savings/view-savings";
import LoansIndex from "./pages/main/loans/loans-index/loans-index";
import ViewLoans from "./pages/main/loans/view-loans/view-loans";
import ConfirmVerification from "./pages/auth/confirn-verification/confirm-verification";
import ResetConfirm from "./pages/auth/reset-confirmed/reset-confirmed";
import PreferencesIndex from "./pages/main/preferences/preferences-index/preferences-index";
import TransactionIndex from "./pages/main/transactions/transaction-index/transaction-index";
import ViewTransaction from "./pages/main/transactions/view-transactions/view-transaction";
import LoanRequest from "./pages/main/loans/loan-request/loan-request";
import NIBBSTransactionsIndex from "./pages/main/nibss-transactions/nibss-transaction-index/nibss-transaction-index";
import ViewNIBBSTransaction from "./pages/main/nibss-transactions/view-nibss-transaction/view-nibss-transaction";
import { Suspense, lazy } from "react";
import CustomLoader from "./components/loader/loader";
import TransactionHistory from "./pages/main/transaction-history/TransactionHistory";
import SavingsProduct from "./pages/main/savingsProduct/SavingsProduct";
import CreateNewSavingsProduct from "./components/SavingsProduct/CreateSavingsProduct/CreateSavingsProduct";
import SingleSavingsProduct from "./pages/main/savingsProduct/SingleSavingsProduct";
import EditNewSavingsProduct from "./components/SavingsProduct/EditSavingsProduct/EditSavingsProduct";
import SettlementReport from "./pages/main/settlement-report/SettlementReport";
import Unauthorized from "./pages/main/unauthorized/Unauthorized";
import PaystackChargeback from "./pages/main/paystack-chargeback/PaystackChargeback";

const BannerManagement = lazy(() =>
  import("./pages/main/banner-management/bannerManagement")
);
const Kyc = lazy(() => import("./pages/main/kyc/kyc"));
const Audit = lazy(() => import("./pages/main/audit/Audit"));
const BulkUpload = lazy(() => import("./pages/main/bulk-upload/bulkUpload"));
const UserManagement = lazy(() =>
  import("./pages/main/user-management/user-management")
);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="main">
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="settlement-report" element={<SettlementReport />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="customer">
                <Route index element={<UserIndex />} />
                <Route path=":id" element={<ViewUser />} />
              </Route>
              <Route path="savings">
                <Route index element={<SavingsIndex />} />
                <Route path=":id" element={<ViewSavings />} />
              </Route>
              <Route path="savings-product">
                <Route index element={<SavingsProduct />} />
                <Route
                  path="create-savings-product"
                  element={<CreateNewSavingsProduct />}
                />
                <Route
                  path="edit-savings-product/:id"
                  element={<EditNewSavingsProduct />}
                />
                <Route
                  path="saving-product/:id"
                  element={<SingleSavingsProduct />}
                />
              </Route>
              <Route path="transactions">
                <Route index element={<TransactionIndex />} />
                <Route path=":id" element={<ViewTransaction />} />
              </Route>
              <Route path="nibss-transactions">
                <Route index element={<NIBBSTransactionsIndex />} />
                <Route path=":id" element={<ViewNIBBSTransaction />} />
              </Route>
              <Route path="loans">
                <Route index element={<LoansIndex />} />
                <Route path=":id" element={<ViewLoans />} />
              </Route>
              <Route path="loan-requests" element={<LoanRequest />} />
              <Route path="guarantors">
                <Route index element={<GuarantorIndex />} />
              </Route>
              <Route path="user-management">
                <Route
                  index
                  element={
                    <Suspense
                      fallback={<CustomLoader />}
                      children={<UserManagement />}
                    />
                  }
                />
              </Route>
              <Route path="audit">
                <Route
                  index
                  element={
                    <Suspense
                      fallback={<CustomLoader />}
                      children={<Audit />}
                    />
                  }
                />
              </Route>
              <Route path="preferences">
                <Route index element={<PreferencesIndex />} />
              </Route>
              {/* <Route path="userManagement">
                <Route
                  index
                  element={
                    <Suspense
                      fallback={<CustomLoader />}
                      children={<UserManagement />}
                    />
                  }
                />
              </Route> */}
              <Route path="audit">
                <Route
                  index
                  element={
                    <Suspense
                      fallback={<CustomLoader />}
                      children={<Audit />}
                    />
                  }
                />
              </Route>
              <Route path="kyc">
                <Route
                  index
                  element={
                    <Suspense fallback={<CustomLoader />} children={<Kyc />} />
                  }
                />
              </Route>
              <Route path="bulk-upload">
                <Route
                  index
                  element={
                    <Suspense
                      fallback={<CustomLoader />}
                      children={<BulkUpload />}
                    />
                  }
                />
              </Route>
              <Route path="paystack-chargeback">
                <Route
                  index
                  element={
                    <Suspense
                      fallback={<CustomLoader />}
                      children={<PaystackChargeback />}
                    />
                  }
                />
              </Route>
              <Route path="transaction">
                <Route
                  index
                  element={
                    <Suspense
                      fallback={<CustomLoader />}
                      children={<TransactionHistory />}
                    />
                  }
                />
              </Route>
              <Route path="banner-management">
                <Route
                  index
                  element={
                    <Suspense
                      fallback={<CustomLoader />}
                      children={<BannerManagement />}
                    />
                  }
                />
              </Route>
            </Route>
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="resetPassword" element={<ResetPassword />} />
            <Route path="confirm-reset" element={<ConfirmVerification />} />
            <Route path="reset-success" element={<ResetConfirm />} />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
