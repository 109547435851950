import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import "../auth/login/login.scss";


const Error = () => {
  return (
    <Result className='mt-5 font-20 color-text'
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="" className='border-0 font-16 fw-500 color-white btn-login'> <Link className="text-decoration-none color-white" to='/'>Back Home</Link></Button>}
  />
  )
}

export default Error
