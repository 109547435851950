import React from "react";
import TabLabel from "../TabLabel";
import sidebarTabStyle from "../../../utils/SidebarTabStyle";
import SideMenuList from "../SideMenuList";
import { useAppContext } from "../../../context/app";

export default function CustomerExperience() {
  const { activeAccordion, setActiveAccordion } = useAppContext();
  const handleClick = () => {
    if (activeAccordion === "customerExperience") {
      setActiveAccordion("");
    } else {
      setActiveAccordion("customerExperience");
    }
  };
  return (
    <>
      <hr className="horizontal_line" />
      <TabLabel
        title="Customer Experience"
        handleClick={handleClick}
        isActive={activeAccordion === "customerExperience"}
      />
      <div
        style={sidebarTabStyle("20rem", activeAccordion, "customerExperience")}
      >
        <SideMenuList
          title="Customer"
          name="Customer"
          iconLink="/dashboard.svg"
          setActiveAccordion={setActiveAccordion}
          active="customerExperience"
        />

        <SideMenuList
          title="Know Your Customer"
          name="KYC"
          iconLink="/kyc.svg"
          setActiveAccordion={setActiveAccordion}
          active="customerExperience"
        />
      </div>
    </>
  );
}
