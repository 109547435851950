import { Button } from "@mantine/core";
import NewDashboardLayout from "../../../layouts/dashboard-layout/NewDashboardLayout";
import { useNavigate } from "react-router-dom";

export default function Unauthorized() {
  const navigate = useNavigate();
  return (
    <NewDashboardLayout>
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div
          className="gap-4"
          style={{
            borderBottom: "3px solid #DDDDDD",
            position: "relative",
            display: "grid",
            gridTemplateColumns: "55% 45%",
            width: "85%",
          }}
        >
          <div>
            <h2
              style={{ fontSize: "35px", fontWeight: "800", color: "#072C50" }}
            >
              You do not have the necessary permissions to view this page
            </h2>
            <p
              style={{ fontSize: "16px", fontWeight: "400", color: "#52525B" }}
            >
              If you believe this is an error, please contact your administrator
              for assistance.
            </p>
            <Button
              color="#072C50"
              radius={6}
              onClick={() => navigate("/main/customer")}
            >
              Go to Dashboard
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "end",
              position: "relative",
            }}
          >
            <img
              src="/unauthorized-vase.gif"
              alt="unauthorized background pics"
              width={80}
              height={80}
              style={{ position: "absolute", bottom: "0", left: 0 }}
            />
            <img
              src="/unauthorized-gif.gif"
              alt="unauthorized background pics"
              width={300}
              height={340}
              style={{ position: "absolute", left: "13%", zIndex: 3 }}
            />
            <img
              src="/unauthorized-bg.png"
              alt="unauthorized background pics"
              width={200}
              height={340}
              style={{ position: "absolute", left: "24%", zIndex: 1 }}
            />
            <img
              src="/unauthorized-vase.gif"
              alt="unauthorized background pics"
              width={80}
              height={80}
              style={{ position: "absolute", bottom: "0", right: 0 }}
            />
          </div>
        </div>
      </div>
    </NewDashboardLayout>
  );
}
