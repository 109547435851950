import { Table, Menu, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
// import { FaDownload, FaCheck, FaTimes } from "react-icons/fa";

export default function SavingsTable({ data }) {
  const navigate = useNavigate();

  const getASavingsProduct = (id) => {
    const savingsProducts = JSON.parse(localStorage.getItem("savingsProduct"));
    const product = savingsProducts?.find((product) => product.id === id);
    localStorage.setItem("editSavingsProduct", JSON.stringify(product));
    navigate("/main/savings-product/edit-savings-product/" + product.id);
  };

  const rows = data?.map((element) => (
    <Table.Tr key={element.id}>
      <Table.Td className="py-3">{element.id || ""}</Table.Td>
      <Table.Td className="py-3">{element.name || ""}</Table.Td>
      <Table.Td className="py-3" style={{ width: "250px" }}>
        {element.description || ""}
      </Table.Td>
      <Table.Td className="py-3">{element.period || ""}</Table.Td>
      <Table.Td className="py-3">{element.productID || ""}</Table.Td>
      <Table.Td className="py-3">{element.taxRate || ""}</Table.Td>
      <Table.Td className="py-3">
        {element.productPaymentsMethod || ""}
      </Table.Td>
      <Table.Td className="py-3">
        <Menu
          width={220}
          shadow="md"
          transitionProps={{ transition: "scale", duration: 250 }}
          position="left"
        >
          <Menu.Target>
            <Button
              className="saving_target_btn shadow-sm"
              style={{
                zIndex: "30",
              }}
            >
              <BsThreeDotsVertical size={18} />
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              className="btn_list text-black"
              onClick={() =>
                navigate("/main/savings-product/saving-product/" + element.id)
              }
            >
              View Savings
            </Menu.Item>
            <Menu.Item
              className="btn_list text-black"
              onClick={() => getASavingsProduct(element.id)}
            >
              Edit Savings
            </Menu.Item>
            {/* <Menu.Item className="btn_list text-danger">
              Delete Savings
            </Menu.Item> */}
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <div
      className="h-75 rounded-2 shadow-sm mt-4"
      style={{ overflowY: "scroll" }}
    >
      <Table className="bg-white mt-4" highlightOnHover stickyHeader>
        <Table.Thead style={{ zIndex: "50" }}>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <Table.Th>Product Name</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th>Period</Table.Th>
            <Table.Th>Product ID</Table.Th>
            <Table.Th>Tax Rate</Table.Th>
            <Table.Th>Payments Method</Table.Th>
            <Table.Th>Action</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  );
}
