import SideMenuList from "../SideMenuList";
import { useAppContext } from "../../../context/app";
import { BiLogOutCircle } from "react-icons/bi";

export default function Settings({ setLogOutModal }) {
  const { setActiveAccordion } = useAppContext();

  return (
    <>
      <hr className="horizontal_line" />
      <button className="dropdown_btn">
        <span style={{ textTransform: "capitalize", fontWeight: "600" }}>
          Settings
        </span>
      </button>

      <div>
        <SideMenuList
          title="Preferences"
          name="Preferences"
          iconLink="/preferences.svg"
          setActiveAccordion={setActiveAccordion}
        />

        <SideMenuList
          title="User Roles and Permission"
          name="User Management"
          iconLink="/users-roles.svg"
          setActiveAccordion={setActiveAccordion}
        />
        
        <div
          className={`d-flex align-items-center gap-3 single_menu`}
          role="button"
          onClick={() => setLogOutModal(true)}
        >
          <div className="inactive_side">
            <BiLogOutCircle className="icon" />
          </div>
          <p className="m-0 inactive_name">Logout</p>
        </div>
      </div>
    </>
  );
}
