import PendingTable from "../../../components/settlement-report/PendingTable";
import SuccessfulTable from "../../../components/settlement-report/SuccessfulTable";
import UnSuccessfulTable from "../../../components/settlement-report/UnSuccessfulTable";
import SettlementReportTabs from "../../../components/settlement-report/SettlementReportTabs";
import TopFilter from "../../../components/settlement-report/TopFilter";
import { useAppContext } from "../../../context/app";
import NewDashboardLayout from "../../../layouts/dashboard-layout/NewDashboardLayout";
import { useState } from "react";
import UploadSettlementReport from "../../../components/settlement-report/UploadSettlementReport";
import { useQuery } from "@tanstack/react-query";
import SavingsService from "../../../services/api/savings/savings";
import { Spin } from "antd";
import { Pagination } from "@mantine/core";

export default function SettlementReport() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [filterData, setFilterData] = useState(null);
  const { activeSettlementTab, setActiveSettlementTab } = useAppContext();
  const { allSettlementReport, allSettlementReportByDate } = SavingsService();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const payload = {
    status: activeSettlementTab,
    pageNo,
    pageSize: 50,
  };

  const {
    data: allReports,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["allSettlementReport", { pageNo }, { activeSettlementTab }],
    queryFn: () => allSettlementReport(payload),
  });

  const pendingReports = allReports?.settlementReportUploadList?.filter(
    (record) => record.settlementStatus?.toLowerCase() === "pending"
  );
  const successfulReports = allReports?.settlementReportUploadList?.filter(
    (record) => record.settlementStatus?.toLowerCase() === "successful"
  );
  const unSuccessfulReports = allReports?.settlementReportUploadList?.filter(
    (record) => record.settlementStatus?.toLowerCase() === "unsuccessful"
  );

  const handleFilterByDate = async (from, end) => {
    const payload = {
      startDate: from,
      endDate: end,
      pageNo: 0,
      pageSize: 50,
    };
    const data = await allSettlementReportByDate(payload);
    setFilterData(data);
  };

  const status = (status) => {
    if (
      status?.toLowerCase() === "processing" ||
      status?.toLowerCase() === "processed"
    ) {
      return "PROCESSING";
    } else {
      return status;
    }
  };

  return (
    <NewDashboardLayout>
      <div className="w-100" style={{ height: "20%" }}>
        <h3 className="text_primary fw-600 font-25">Settlement Upload</h3>
        <SettlementReportTabs
          activeSettlementTab={activeSettlementTab}
          setActiveSettlementTab={setActiveSettlementTab}
        />
      </div>

      <Spin spinning={isLoading}>
        <div
          className="w-100 bg-white p-2 overflow-auto"
          style={{ height: "80%" }}
        >
          <TopFilter
            showModal={showModal}
            handleFilterByDate={handleFilterByDate}
            activeSettlementTab={activeSettlementTab}
            refetch={refetch}
            setFilterData={setFilterData}
          />

          <div className="mt-3">
            {activeSettlementTab === "pending" && (
              <PendingTable
                pendingReports={pendingReports}
                filterData={filterData}
                status={status}
              />
            )}

            {activeSettlementTab === "successful" && (
              <SuccessfulTable
                successfulReports={successfulReports}
                filterData={filterData}
                status={status}
              />
            )}

            {activeSettlementTab === "unsuccessful" && (
              <UnSuccessfulTable
                unSuccessfulReports={unSuccessfulReports}
                filterData={filterData}
                status={status}
              />
            )}
          </div>
        </div>
      </Spin>

      <div className="w-100 d-flex align-items-center justify-content-center mt-4">
        <Pagination hideWithOnePage total={pageNo} />
      </div>

      <UploadSettlementReport
        isModalOpen={isModalOpen}
        hideModal={hideModal}
        onChange={setPageNo}
        refetch={refetch}
      />
    </NewDashboardLayout>
  );
}
