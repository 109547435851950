import "../login/login.scss";
import "./confirm-verification.scss";
import { Link } from "react-router-dom";
import loginImage from '../../../../src/assets/images/loginVector2.svg';
import logo from '../../../../src/assets/images/logo.png';

const ConfirmVerification = () => {

    return (
        <section className="pt-5 overflow-x">
            <div className="pdl-5">
                <img className="w-200" src={logo} alt="creditville-logo" />
            </div>
            <div className="row mt-5">
                <div className="col-md-6 pdl-5">
                    <img className="w-100" src={loginImage} alt="login" />
                </div>
                <div className="col-md-6 px-10">
                    <div>
                        <div className="font-30 fw-700 color-secondary ">HEYO!!</div>
                        <div className="color-text font-20 fw-400 mt-3 mb-5">A verification link has just been sent to your email to reset your password </div>
                    </div>
                    

                    <div className="d-flex justify-content-center mt-4">
                        <div className="fw-500 font-12 color-secondary pointer link"><Link className="color-secondary text-decoration-none" to="/">BACK TO LOGIN</Link></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ConfirmVerification
