export function tabsStyles1(activeTab, status) {
  return {
    backgroundColor: activeTab === status ? "#072c50" : "#fff",
    color: activeTab === status ? "#fff" : "#000",
    border: activeTab === status ? "0px" : "1px solid #d0d5dd",
    outline: 0,
    fontSize: "15px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
  };
}

export function tabsStyles2(activeTab, status) {
  return {
    backgroundColor: activeTab === status ? "#072c50" : "#fff",
    color: activeTab === status ? "#fff" : "#000",
    border: activeTab === status ? "0px" : "1px solid #d0d5dd",
    outline: 0,
    fontSize: "15px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "0px",
  };
}

export function tabsStyles3(activeTab, status) {
  return {
    backgroundColor: activeTab === status ? "#072c50" : "#fff",
    color: activeTab === status ? "#fff" : "#000",
    border: activeTab === status ? "0px" : "1px solid #d0d5dd",
    outline: 0,
    fontSize: "15px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "0px",
  };
}
