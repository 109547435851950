import "./preferences-index.scss";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import CircleNotificationsOutlinedIcon from "@mui/icons-material/CircleNotificationsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MailLockOutlinedIcon from "@mui/icons-material/MailLockOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

const PreferencesIndex = () => {
  return (
    <NewDashboardLayout>
      <div>
        <div className="color-secondary fw-600 font-25 mb-5">Preferences</div>
        <section className="card">
          <div className="cardTitle fw-500 font-18 color-secondary pb-4">
            {" "}
            General{" "}
          </div>
          <div className="row py-3">
            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent">
                <AccountCircleOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Profile
                </span>
              </div>
              <div className="mb-2 px-3 py-3 pointer cardContent">
                <DocumentScannerOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Tier Management
                </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent pb-4">
                <SupportAgentOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Support Channel
                </span>
              </div>
              <div>
                <div className="mb-2 px-3 py-3 pointer cardContent">
                  <InsertDriveFileOutlinedIcon className="p-0 color-secondary font-40" />
                  <span className="ml-2 color-secondary font-16 fw-400">
                    Two Factor Authentication{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent pb-4">
                <InsertDriveFileOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Organization Documents
                </span>
              </div>
              <div>
                <div className="mb-2 px-3 py-3 pointer cardContent">
                  <GroupsOutlinedIcon className="p-0 color-secondary font-40" />
                  <span className="ml-2 color-secondary font-16 fw-400">
                    Referral Configuration
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="card mt-5">
          <div className="cardTitle fw-500 font-18 color-secondary pb-4">
            {" "}
            System Settings{" "}
          </div>
          <div className="row py-3">
            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent ">
                <SupervisorAccountOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  System
                </span>
              </div>
              <div></div>
            </div>

            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent">
                <CircleNotificationsOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Notification Settings
                </span>
              </div>
              <div></div>
            </div>

            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent">
                <MailLockOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Domain Settings
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="card mt-5">
          <div className="cardTitle fw-500 font-18 color-secondary pb-4">
            {" "}
            Teams and Roles{" "}
          </div>

          <div className="row py-3">
            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent">
                <GroupOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Teams
                </span>
              </div>
              <div></div>
            </div>

            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent">
                <SettingsSuggestOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Roles and Permissions{" "}
                </span>
              </div>
              <div></div>
            </div>
          </div>
        </section>

        <section className="card mt-5">
          <div className="cardTitle fw-500 font-18 color-secondary pb-4">
            {" "}
            Messaging Center{" "}
          </div>

          <div className="row py-3">
            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent">
                <EmailOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Messages
                </span>
              </div>
              <div></div>
            </div>

            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent">
                <AdminPanelSettingsOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  {" "}
                  Message Management{" "}
                </span>
              </div>
              <div></div>
            </div>

            <div className="col-md-4">
              <div className="mb-2 px-3 py-3 pointer cardContent">
                <SettingsApplicationsOutlinedIcon className="p-0 color-secondary font-40" />
                <span className="ml-2 color-secondary font-16 fw-400">
                  Tags Management
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </NewDashboardLayout>
  );
};

export default PreferencesIndex;
