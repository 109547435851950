import { Button, Textarea } from "@mantine/core";
import { Modal } from "antd";
import React, { useState } from "react";

export default function ReasonForFreezeUnfreeze({
  isModalOpen,
  handleOk,
  handleCancel,
  isFreezing,
  value,
  setValue,
  freezeAccount,
  unFreezeAccount,
}) {
  const [error, setError] = useState("");
  const handleClick = async () => {
    if (isFreezing) {
      if (!value) {
        setError("Give a reason for freezing this account");
        return;
      }
      await freezeAccount();
    } else {
      if (!value) {
        setError("Give a reason for unfreezing this account");
        return;
      }
      await unFreezeAccount();
    }
  };

  return (
    <Modal
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={500}
      centered
      footer={null}
    >
      <div className="w-100">
        <Textarea
          minRows={4}
          value={value}
          label={`Give reason for ${isFreezing ? "freezing" : "unfreezing"} this
          account.`}
          onChange={(event) => setValue(event.currentTarget.value)}
          withAsterisk
        />
        {error && (
          <span style={{ fontSize: "11px", color: "red" }}>{error}</span>
        )}
        <Button
          color="#213f7d"
          style={{ fontSize: "14px", marginTop: "1.1rem" }}
          onClick={handleClick}
          fullWidth
        >
          CONTINUE
        </Button>
      </div>
    </Modal>
  );
}
