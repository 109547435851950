import "../login/login.scss";
import { Link } from "react-router-dom";
import loginImage from '../../../../src/assets/images/loginVector2.svg';
import logo from '../../../../src/assets/images/logo.png';

const ResetConfirm = () => {

    return (
        <section className="pt-5 overflow-x">
            <div className="pdl-5">
                <img className="w-200" src={logo} alt="creditville-logo" />
            </div>
            <div className="row mt-5">
                <div className="col-md-6 pdl-5">
                    <img className="w-100" src={loginImage} alt="login" />
                </div>
                <div className="col-md-6 px-10">
                    <div>
                        <div className="font-30 fw-700 color-secondary ">CONGRATULATIONS!!</div>
                        <div className="color-text font-20 fw-400 mt-3 mb-3">You've successfully made your password reset </div>
                        <div className="color-text font-20 fw-400 mb-5">Please, kindly click the link below to login. </div>
                    </div>
                    

                    <div className="d-flex justify-content-center mt-4">
                        <div className="fw-500 font-12 color-secondary pointer link"><Link className="color-secondary text-decoration-none" to="/">BACK TO LOGIN</Link></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResetConfirm
