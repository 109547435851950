import { notification } from "antd";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";

export const openSuccessNotification = (message, status) => {
  notification.open({
    message: `${status}`,
    description: `${message}`,
    duration: 4,
    icon: <SmileOutlined style={{ color: "#71b324" }} />,
  });
};

export const openErrorNotification = (message, status) => {
  notification.open({
    message: `${status}`,
    description: `${message}`,
    duration: 2,
    icon: <FrownOutlined style={{ color: "red" }} />,
  });
};
