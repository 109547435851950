import { Table } from "@mantine/core";

export default function NibbsTable({
  allNibbsTransactions,
  handleShowNibbsTransaction,
}) {
  return (
    <Table
      stickyHeader
      stickyHeaderOffset={0}
      className="pointer overflowing-table"
      highlightOnHover
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Created At</Table.Th>
          <Table.Th>Transaction ID</Table.Th>
          <Table.Th>Action Type</Table.Th>
          <Table.Th>Channel</Table.Th>
          <Table.Th>Unit Transacted</Table.Th>
          <Table.Th>Source Account Name</Table.Th>
          <Table.Th>Destination Account Name</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {allNibbsTransactions.map(
          ({
            id,
            createdAt,
            transactionId,
            actionType,
            channel,
            unitTransacted,
            sourceAccountName,
            destinationAccountName,
          }) => (
            <Table.Tr
              key={id}
              className="cursor_pointer"
              onClick={() => handleShowNibbsTransaction(id)}
            >
              <Table.Td className="py-3">
                {new Date(createdAt).toLocaleDateString()}
              </Table.Td>
              <Table.Td className="py-3">{transactionId}</Table.Td>
              <Table.Td className="py-3">{actionType}</Table.Td>
              <Table.Td className="py-3">{channel}</Table.Td>
              <Table.Td className="py-3">{unitTransacted}</Table.Td>
              <Table.Td className="py-3">{sourceAccountName}</Table.Td>
              <Table.Td className="py-3">{destinationAccountName}</Table.Td>
            </Table.Tr>
          )
        )}
      </Table.Tbody>
    </Table>
  );
}
