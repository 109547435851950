/* eslint-disable react-hooks/exhaustive-deps */
import "./NewDashboardLayout.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { openErrorNotification } from "../../utils/Notifications";
import NewSideBar from "../../components/sidebar/NewSideBar";
import NewNavbar from "../../components/navbar/NewNavbar";

export default function NewDashboardLayout({ children }) {
  const navigate = useNavigate();

  const onIdle = () => {
    localStorage.clear();
    navigate("/");
    openErrorNotification(
      "Session Timeout",
      "This User has been inactive for more than 5 minutes, Please Login to continue"
    );
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 5,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
    ],
    immediateEvents: [],
    debounce: 0,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    syncTimers: 0,
  });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("userDetails");
    if (loggedInUser) {
      //   const newUser = JSON.parse(loggedInUser);
      return;
    } else {
      navigate("/");
    }

    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="app_container">
      <NewSideBar />
      <div className="main_container">
        <NewNavbar />
        <div className="main_wrapper">{children}</div>
      </div>
    </div>
  );
}
