import { useState } from "react";
import SavingsTable from "../../../components/SavingsProduct/SavingsTable/SavingsTable";
import SearchFilter from "../../../components/SavingsProduct/SearchFilter/SearchFilter";
import NewDashboardLayout from "../../../layouts/dashboard-layout/NewDashboardLayout";
import SavingsService from "../../../services/api/savings/savings";
import "./SavingsProduct.css";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";

export default function SavingsProduct() {
  const { getSavingsProducts } = SavingsService();
  const [period, setPeriod] = useState("all");
  const payload = {
    pageNo: "0",
    pageSize: "50",
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getSavingsProducts"],
    queryFn: () => getSavingsProducts(payload),
  });
  data && localStorage.setItem("savingsProduct", JSON.stringify(data));

  const handleChangePeriod = (e) => {
    setPeriod(e.currentTarget.value);
  };

  const renderSavingsProducts = () => {
    if (period === "all") {
      return data;
    } else if (period === "from newest") {
      return data
        ?.filter(
          (product) =>
            product.createdOn !== undefined || product.createdOn !== null
        )
        .sort((a, b) => b.createdOn - a.createdOn);
    } else {
      return data?.filter(
        (product) => product.period.toLowerCase() === period.toLowerCase()
      );
    }
  };

  return (
    <NewDashboardLayout>
      <h3 className="h3 savings_title">Savings Product</h3>
      <SearchFilter
        data={data}
        handleChangePeriod={handleChangePeriod}
        period={period}
        refetch={refetch}
      />
      <Spin spinning={isLoading} size="default">
        <SavingsTable data={renderSavingsProducts()} />
      </Spin>
    </NewDashboardLayout>
  );
}
