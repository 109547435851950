import { Modal } from "@mantine/core";
import { useFormik } from "formik";
import { object, string } from "yup";

export default function GenerateTokenModal({ opened, close }) {
  const validationSchema = object().shape({
    from: string().required("This field is required"),
    to: string().required("This field is required"),
  });

  const { values, handleChange, handleSubmit, errors, touched, isSubmitting } =
    useFormik({
      initialValues: {
        from: "",
        to: "",
      },
      validationSchema,
      onSubmit: async ({ from, to }, { resetForm, setSubmitting }) => {},
    });

  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      centered
      radius={20}
    >
      <form onSubmit={handleSubmit}>
        <div className="w-100 d-flex align-items-center justify-content-between gap-2 mb-2">
          <div className="form_group_col mt-2">
            <label htmlFor="from">From</label>
            <input
              type="date"
              name="from"
              value={values.from}
              onChange={handleChange}
            />
            {errors.from && touched.from && (
              <div style={{ fontSize: "11px", color: "red" }}>
                {errors.from}
              </div>
            )}
          </div>

          <div className="form_group_col mt-2">
            <label htmlFor="to">To</label>
            <input
              type="date"
              name="to"
              value={values.to}
              onChange={handleChange}
            />
            {errors.to && touched.to && (
              <div style={{ fontSize: "11px", color: "red" }}>{errors.to}</div>
            )}
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="primary_btn mt-2 w-100 py-2 text-white fs-6 rounded-2"
        >
          Search
        </button>
      </form>
    </Modal>
  );
}
