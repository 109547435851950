import { useFormik } from "formik";
import { object, string } from "yup";
import "./EnterBvnModal.scss";
import CustomerService from "../../../services/api/customer/customer";

export default function EnterBvnModal({
  openNotification,
  setUserBvnData,
  setBvnDetailsModal,
}) {
  const { getBvnDetails } = CustomerService();

  const validationSchema = object().shape({
    bvn: string().required("BVN is required"),
  });

  const { errors, touched, handleSubmit, isSubmitting, values, handleChange } =
    useFormik({
      initialValues: {
        bvn: "",
      },
      validationSchema,
      onSubmit: async ({ bvn }, { resetForm, setSubmitting }) => {
        const data = await getBvnDetails(Number(bvn));

        if (data.status.toLowerCase() === "success") {
          setUserBvnData(data.data);
          setBvnDetailsModal(true);
          resetForm();
          setSubmitting(false);
        } else {
          openNotification(data.message, "Error Notification", "red");
          return;
        }
      },
    });
  return (
    <div>
      <h5>Enter User BVN</h5>
      <form className="enter_bvn_form" onSubmit={handleSubmit}>
        <div className="d-flex flex-column gap-4">
          <div className="form_group_col">
            <label htmlFor="bvn">Enter BVN</label>
            <input
              type="text"
              name="bvn"
              value={values.bvn}
              onChange={handleChange}
            />
          </div>
          <button type="submit" disabled={isSubmitting} className="updateBvn">
            Submit
          </button>
        </div>
        {errors.bvn && touched.bvn && (
          <div className="color-error font-12">{errors.bvn}</div>
        )}
      </form>
    </div>
  );
}
