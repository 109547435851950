import { axiosSavingInstance } from "../../interceptors/axiosInterceptors";
import { useState } from "react";

const SAVING_URL = process.env.REACT_APP_SAVING_SERVICE_PATH;

const TransactionService = () => {
  const [allNibbsTransactionLoader, setAllNibbsTransactionLoader] =
    useState(false);
  const [recieptLoader, setRecieptLoader] = useState(false);
  const [pageSize, setPageSize] = useState(null);
  const [error, setError] = useState(null);

  const getAllNibbsTransaction = async (pageNo, pageSize) => {
    try {
      const { data } = await axiosSavingInstance.get(
        `${SAVING_URL}/wallet/nibss/transaction/all?offset=${pageNo}&limit=${pageSize}`
      );
      setPageSize(pageSize);
      return data?.data?.nibssTransactionResponseData?.nibssTransaction;
    } catch (error) {
      return error;
    }
  };

  const searchNibbsTransaction = async ({ sessionId, referenceCode }) => {
    const url = `${SAVING_URL}/wallet/nibss/transaction/fields?sessionId=${sessionId}&referenceCode=${referenceCode
      .split("|")
      .join("")}`;
    try {
      const { data } = await axiosSavingInstance.get(url);
      setPageSize(1);
      return data?.data?.nibssTransactionResponseData?.nibssTransaction;
    } catch (error) {
      return error;
    }
  };

  const generateReciept = async (id) => {
    setRecieptLoader(true);
    setRecieptLoader(true);
    try {
      const { data } = await axiosSavingInstance.get(
        `${SAVING_URL}/wallet/transaction/receipt/${id}`
      );
      setRecieptLoader(false);
      return data;
    } catch (error) {
      setRecieptLoader(false);
      return;
    }
  };

  const filterNibbsTransaction = async (startDate, offset, endDate) => {
    const limit = 20;
    const url = `${SAVING_URL}/wallet/nibss/transaction/date?startDate=${startDate}&limit=${limit}&offset=${offset}&endDate=${endDate}`;

    setRecieptLoader(true);
    try {
      const { data } = await axiosSavingInstance.get(url);
      setRecieptLoader(false);
      setPageSize(offset);
      const result = data?.data?.nibssTransactionResponseData.nibssTransaction;
      if (result.length === 0) setError("No transaction within this period");
      return result;
    } catch (error) {
      setError(error.message);
      return;
    }
  };

  return {
    getAllNibbsTransaction,
    searchNibbsTransaction,
    generateReciept,
    recieptLoader,
    allNibbsTransactionLoader,
    setAllNibbsTransactionLoader,
    pageSize,
    filterNibbsTransaction,
    error,
    setError,
  };
};
export default TransactionService;
