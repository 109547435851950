import "../kyc/KycTable/KycTable.css";

export default function SettlementStatus({ status }) {
  return (
    <div
      className={`kyc__status ${
        status.toLowerCase() === "processing"
          ? "kyc_status_pending_color"
          : (status.toLowerCase() === "verified") | "completed"
          ? "kyc_status_verified_color"
          : "kyc_status_waiting_color"
      }`}
    >
      <span
        className={`${
          status.toLowerCase() === "processing"
            ? "pending_ball"
            : (status.toLowerCase() === "verified") | "completed"
            ? "verified_ball"
            : "waiting_ball"
        }`}
      />
      {status}
    </div>
  );
}
