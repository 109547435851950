import { useNavigate, useLocation } from "react-router-dom";

export default function SideMenuList({
  name,
  iconLink,
  title,
  setActiveAccordion,
  active,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split("/")[2];

  return (
    <div
      className={`d-flex align-items-center gap-3 ${
        pathname.toLowerCase().replace("-", " ") === name.toLowerCase()
          ? "single_menu_active"
          : "single_menu"
      }`}
      role="button"
      onClick={() => {
        if (name) {
          navigate(`/main/${name.toLowerCase().replace(" ", "-")}`);
          setActiveAccordion(active);
        } else {
          return;
        }
      }}
    >
      <div
        className={`${
          pathname.toLowerCase().replace("-", " ") === name.toLowerCase()
            ? "active_side"
            : "inactive_side"
        }`}
      >
        <img src={iconLink} alt={name} />
      </div>
      <p
        className={`m-0 ${
          pathname.toLowerCase().replace("-", " ") === name.toLowerCase()
            ? "active_name"
            : "inactive_name"
        }`}
      >
        {title}
      </p>
    </div>
  );
}
