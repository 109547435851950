import { Modal } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";

export default function MessageModal({
  messageModal,
  setMessageModal,
  sendMessage,
}) {
  return (
    <Modal
      centered
      destroyOnClose={true}
      visible={messageModal}
      footer={null}
      onCancel={() => setMessageModal(false)}
      width={500}
    >
      <Formik
        initialValues={{
          title: "",
          description: "",
        }}
        validationSchema={yup.object({
          title: yup.string().required("title field is required"),
          description: yup.string().required("description field is required"),
        })}
        onSubmit={(values) => {
          sendMessage(values.description, values.title);
          setMessageModal(false);
        }}
      >
        {(props) => (
          <section className="pt-3 overflow-x">
            <Form>
              <div className="row">
                <div className="col-md-12 px-3">
                  <div>
                    <div className="font-18 text-center mb-3 fw-500 color-secondary ">
                      Send Message to User
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="title"
                      className="form-label font-14 fw-500 color-text"
                    >
                      Title *
                    </label>
                    <Field
                      type="text"
                      className="form-control font-14"
                      name="title"
                      placeholder="Enter Title"
                    ></Field>
                    <div className="form-error mt-1">
                      <ErrorMessage
                        className="form-error"
                        name="title"
                      ></ErrorMessage>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="description"
                      className="form-label font-14 fw-500 color-text"
                    >
                      Description *
                    </label>
                    <Field
                      type="text"
                      className="form-control font-14"
                      name="description"
                      rows="8"
                      cols="50"
                      component="textarea"
                      placeholder="Enter your Description"
                    ></Field>
                    <div className="form-error mt-1">
                      <ErrorMessage
                        className="form-error"
                        name="description"
                      ></ErrorMessage>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={props.isValid === false}
                      className="border-0 w-100 font-16 mt-3 round-6 fw-500 color-white p-3 btn-login"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </section>
        )}
      </Formik>
    </Modal>
  );
}
