import React from "react";

export default function DebitBox({ status }) {
  return (
    <div
      style={{
        width: "60px",
        height: "25px",
        backgroundColor: status === "debit" ? "#FDE3EA" : "#DFF1C8",
        color: status === "debit" ? "#80011F" : "#73B424",
        fontSize: "13px",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px 20px",
      }}
    >
      {status}
    </div>
  );
}
