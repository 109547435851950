import React from "react";
import NewDashboardLayout from "../../../../layouts/dashboard-layout/NewDashboardLayout";

const ViewLoans = () => {
  return (
    <NewDashboardLayout>
      <div>
        <div className="color-secondary fw-600 font-25 mb-1">Hello</div>
      </div>
    </NewDashboardLayout>
  );
};

export default ViewLoans;
